<mat-card>
  <mat-card-header class="card-header" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <ngx-avatars class="profile-image" size="100" [src]="getPhoto()"></ngx-avatars>
    <p class="title"></p>
  </mat-card-header>

  <mat-card-content *ngIf="associate">
    <mat-divider></mat-divider>
    <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
      <p>{{ 'settings.profile.name' | translate }}:</p>
      <p>{{ associate?.firstName }} {{ associate?.lastName }}</p>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
      <p>{{ 'registration.job-title' | translate }}:</p>
      <p>{{ associate?.jobTitle }}</p>
    </div>

    <!-- <mat-divider *ngIf="associate?.roleName"></mat-divider> -->
    <!-- <div fxLayout="row" class="row" fxLayout.lt-sm="column" *ngIf="associate?.roleName" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
      <p>{{ 'settings.companies.role' | translate }}:</p>
      <p>
        {{ 'settings.companies.' + associate?.roleName?.toLowerCase() | translate }}
      </p>
    </div> -->
    <!-- <mat-divider *ngIf="associate.roleName?.toLowerCase() === 'forwardingagent' || associate.roleName?.toLocaleLowerCase() === 'forwarding agent'"></mat-divider> -->
    <!-- <div
      fxLayout="row"
      class="row"
      fxLayout.lt-sm="column"
      *ngIf="associate.roleName?.toLowerCase() === 'forwardingagent' || associate.roleName?.toLocaleLowerCase() === 'forwarding agent'"
      fxLayoutAlign.lt-sm="center start"
      fxLayoutAlign="start center"
    >
      <p>{{ 'settings.companies.forwarding-company' | translate }}:</p>
      <p>{{ associate?.forwardingCompanyName }}</p>
    </div> -->

    <!-- <mat-divider></mat-divider> -->
    <!-- <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
      <p>{{ 'registration.birthdate' | translate }}:</p>
      <p>{{ associate?.birthDate | ellosDate: false }}</p>
    </div> -->
    <!-- <mat-divider></mat-divider> -->
    <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
      <p>{{ 'settings.profile.e-mail' | translate }}:</p>
      <p>{{ associate?.email }}</p>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
      <p>{{ 'settings.profile.phone-number' | translate }}:</p>
      <p>{{ associate?.phone }}</p>
    </div>
    <!-- <mat-divider></mat-divider> -->
    <!-- <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
      <p>{{ 'settings.profile.address' | translate }}:</p>
      <p>{{ associate?.street }}</p>
    </div> -->
    <!-- <mat-divider></mat-divider> -->
    <!-- <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
      <p>{{ 'settings.profile.city' | translate }}:</p>
      <p>{{ associate?.city }} - {{ associate?.state }}</p>
    </div> -->

    <!-- <mat-divider></mat-divider> -->
    <!-- <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
      <p>{{ 'registration.nationality' | translate }}:</p>
      <p>{{ associate?.country.name }}</p>
    </div> -->
  </mat-card-content>
  <app-ellos-spinner [text]="'marketplace.details.loading' | translate" *ngIf="!associate"></app-ellos-spinner>

  <div fxLayoutAlign="end end">
    <button mat-stroked-button routerLink="/Settings/Companies/Detail/{{ idCompany }}">
      {{ 'settings.companies.button-back' | translate }}
    </button>
  </div>
</mat-card>
