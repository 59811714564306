import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { Injectable, NgModule } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../services/auth.service';
import StoreItem from '../interfaces/storeItem.interface';
import { parseJwt } from 'src/app/shared/utils/parse-jwt';
import { StoreKey } from '../interfaces/storeKey.interface';
import { ErrorHandleDialogComponent } from '@ccab/components-lib';
import { StorageService } from '../services/storage.service';
import { ModalManagerService } from '../services/modal-manager.service';
import { ERRORS_LIST_TO_NOT_SHOW_MODAL_ERROR } from './errors-not-show-modal-error';
import { URLS_LIST_TO_NOT_SHOW_MODAL_ERROR } from './urls-list-to-not-show-modal-error';
import { URLS_LIST_NOT_TO_SHOW_LOADING_SPINNER } from './urls-list-not-to-show-loading-spinner';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { URLS_REFRESH } from './refresh';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;
  private requests: HttpRequest<any>[] = [];

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly storageService: StorageService,
    private readonly _modalManagerService: ModalManagerService,
    private cookieService: CookieService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      if (!this.isUrlOnTheList(req.url, URLS_LIST_NOT_TO_SHOW_LOADING_SPINNER)) {
        if (this.requests.length == 0) {
          this._modalManagerService.openLoadingModal();
        }
        this.requests.push(req);
      }
      const token: StoreItem = this.storageService.get(StoreKey.apiToken);
      let Refresh: StoreItem;
      if (token.value != null) {
        Refresh = this.storageService.get(StoreKey.Refresh);
      }
      Refresh = this.storageService.get(StoreKey.Refresh);
      if (!token.value) {
        this.requests = this.requests.filter((request) => request.url !== req.url);
        this.closeLoadingSpinnerModal();
        return next.handle(req);
      }
      const _parseJwt = parseJwt(token.value);
      let authReq: HttpRequest<any>;
      if (_parseJwt.expTime < new Date() && !this.isUrlOnTheList(req.url, URLS_REFRESH)) {
        const TokenRefresh = this.cookieService.get('RefreshToken');
        this.authService.RefreshToken(TokenRefresh);
        const token: StoreItem = this.storageService.get(StoreKey.apiToken);
        authReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token.value}`
          }
        });
      } else {
        authReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token.value}`
          }
        });
      }

      return next.handle(authReq).pipe(
        finalize(() => {
          this.requests = this.requests.filter((req) => req.url !== authReq.url);
          this.closeLoadingSpinnerModal();
        }),
        tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              const body = event.body;
              this.convertToDate(body);
            }
          },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              this.requests = this.requests.filter((req) => req.url !== authReq.url);
              this.closeLoadingSpinnerModal();
              this.handleError(err);
            }
          }
        )
      );
    } catch (error) {
      console.error(error);
      this.closeLoadingSpinnerModal();
    }
  }

  async RefreshToken(refresh: string): Promise<void> {
    await this.authService.RefreshToken(refresh);
  }

  forceExpiration() {
    this.dialog.closeAll();
    this.authService.logout();
  }

  handleError(error: HttpErrorResponse) {
    if (error.status === 401) {
      this.forceExpiration();
    } else if (error.status >= 400 && error.error.errors?.length > 0) {
      if (this.isUrlOnTheList(error.url, URLS_LIST_TO_NOT_SHOW_MODAL_ERROR)) {
        return;
      } else {
        if (this.isErrorTheList(error.error.errors, ERRORS_LIST_TO_NOT_SHOW_MODAL_ERROR)) {
        } else {
          this.dialog.open(ErrorHandleDialogComponent, {
            data: error.error.errors
          });
        }
      }
    }
    return throwError(error);
  }

  isUrlOnTheList(urlRequest: string, list: string[]): boolean {
    let isUrlOnTheList = false;
    for (const url of list) {
      if (!isUrlOnTheList) {
        isUrlOnTheList = urlRequest.includes(url);
      }
    }
    return isUrlOnTheList;
  }

  isErrorTheList(errors: string[], list: string[]): boolean {
    let isErrorOnTheList = false;
    for (const url of list) {
      if (!isErrorOnTheList) {
        isErrorOnTheList = errors.includes(url);
      }
    }
    return isErrorOnTheList;
  }

  convertToDate(body) {
    if (body === null || body === undefined) {
      return body;
    }

    if (typeof body !== 'object') {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (this.isIso8601(value)) {
        body[key] = new Date(value);
      } else if (typeof value === 'object') {
        this.convertToDate(value);
      }
    }
  }

  isIso8601(value) {
    if (value === null || value === undefined) {
      return false;
    }

    return this.iso8601.test(value);
  }

  closeSessionAndRedirectToLogin() {
    this.dialog.closeAll();
    this.authService.logout();
    this.router.navigateByUrl('/Login');
  }

  closeLoadingSpinnerModal(): void {
    if (this.requests.length === 0) {
      this._modalManagerService.closeLoadingModal();
    }
  }
}

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    }
  ]
})
export class HttpInterceptorModule {}
