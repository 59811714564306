<div *ngIf="modules.includes(modulePermission.AceitarRejeitarConvites)" class="table">
  <table mat-table [dataSource]="dataSource" class="example-table">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element" class="padding-right">CO-{{ element.idCompany }}</td>
    </ng-container>

    <ng-container matColumnDef="companyName">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'settings.companies.company-name' | translate }}
      </th>
      <td mat-cell matTooltip="{{ element.companyName }}" *matCellDef="let element" class="padding-right">
        {{ element.companyName | truncate : 30 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'settings.my-invites.date' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" class="padding-right">
        {{ element.invitationDate | ellosDate : false }}
      </td>
    </ng-container>

    <ng-container matColumnDef="myRole">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'settings.companies.my-role' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" class="padding-right">
        <p class="forward">{{ 'settings.companies.' + getTranslatedRole(element.role) | translate }}</p>
        <p class="forward" *ngIf="element.role.toLowerCase() === 'forwarding agent'">
          <mat-icon matPreFix> <fa-icon matPreFix [icon]="faBuilding" class="build-icon"></fa-icon> </mat-icon>{{ element.forwardingAgentCompanyName }}
        </p>
      </td></ng-container
    >

    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'settings.companies.country' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" class="padding-right">{{ element.countryName }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'settings.companies.actions' | translate }}
      </th>

      <td mat-cell *matCellDef="let element">
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
          <button
            *ngIf="modules.includes(modulePermission.VisualizarDetallhesConvite) || modules.includes(modulePermission.EnviarConvites)"
            mat-button
            class="detail-button"
            [routerLink]="'/Settings/MyInvitations/Detail/' + element.id + '/' + element.companyName + '/' + element.role"
            [matTooltip]="'settings.companies.details' | translate"
          >
            <mat-icon matPrefix> visibility </mat-icon>
            {{ 'settings.my-invites.see-details' | translate }}
          </button>
          <button mat-button [matTooltip]="'settings.companies.accept-invitation' | translate" (click)="openAction('accept', element)">
            <mat-icon color="accent" matPrefix>check_circle</mat-icon>
            {{ 'settings.my-invites.accept' | translate }}
          </button>
          <button mat-button [matTooltip]="'settings.companies.decline-invitation' | translate" (click)="openAction('decline', element)">
            <mat-icon color="warn">cancel</mat-icon>
            {{ 'settings.my-invites.decline' | translate }}
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator #paginator [length]="response?.invitesCount" [pageSize]="pageSize" (page)="pageChange($event)"> </mat-paginator>
</div>
