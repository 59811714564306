import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import ForgotPassword from 'src/app/core/interfaces/forgotPassword.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import ResetPassword from 'src/app/core/interfaces/resetPassword.interface';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPwdForm: FormGroup;
  resetPwdForm: FormGroup;
  resetingPassword: boolean;
  email: string;
  isHomolog = environment.homolog;
  constructor(
    private readonly fb: FormBuilder,
    private readonly authService: AuthService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly translateService: TranslateService
  ) {
    this.forgotPwdForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      recaptcha: [null, this.isHomolog ? null : Validators.required]
    });
    this.resetPwdForm = this.fb.group({
      newPassword: ['', [Validators.required, Validators.pattern('^(?=.{6,32}$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*[●!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~].*')]],
      code: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {}

  async onSubmitCode(stepper: MatStepper) {
    const sendCode: ForgotPassword = {
      email: this.forgotPwdForm.controls['email'].value
    };

    try {
      await this.authService.forgotPassword(sendCode);
      this.email = sendCode.email;
      stepper.next();
    } catch (exception) {
      this.snackBar.open(exception, 'OK', {
        duration: 4000
      });
    }
  }

  async onResetPassword() {
    this.resetingPassword = true;
    const reset: ResetPassword = {
      email: this.email,
      code: this.resetPwdForm.controls.code.value,
      newPassword: this.resetPwdForm.controls.newPassword.value
    };

    try {
      await this.authService.resetPassword(reset);
      const successMsg: string = this.translateService.instant('auth.forgot-password.reset-password-successfully');

      this.snackBar.open(successMsg, 'OK', {
        duration: 2000
      });

      this.router.navigateByUrl('/Login');
    } catch (exception) {
      this.snackBar.open(exception, 'OK', {
        duration: 4000
      });
    }
    this.resetingPassword = false;
  }

  handleValueRecaptcha(value: string) {
    if (value !== null) {
      this.forgotPwdForm.controls['recaptcha'].setValue(value);
    }
  }
}
