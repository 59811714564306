import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TokenPayload } from '../interfaces/token-payload.interface';
import { AuthService } from '../services/auth.service';
import { URLS_REFRESH } from '../interceptors/refresh';

@Injectable({
  providedIn: 'root'
})
export class TokenExpirationGuard implements CanActivate {
  constructor(private router: Router, private cookieService: CookieService, private readonly authService: AuthService) {}

  canActivate(): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      const token = this.cookieService.get('token');

      if (!token) {
        this.router.navigate(['/Login']);
        resolve(false);
      }

      try {
        const tokenData: TokenPayload = JSON.parse(atob(token.split('.')[1]));
        // Adicione um tratamento de erro caso a análise do token falhe
        if (!tokenData || !tokenData.exp) {
          console.error('Falha ao analisar o token ou token expirado.');
          this.router.navigate(['/Login']);
          resolve(false);
        }
        resolve(true);
      } catch (error) {
        console.error('Erro ao analisar o token:', error);
        this.router.navigate(['/Login']);
        resolve(false);
      }
    });
  }
}
