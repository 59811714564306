export const environment = {
  production: false,
  prePrd: true,
  develop: false,
  homolog: false,
  associateApi: 'https://apis.pre-prd.ellos.org.br/ellos/associate/api',
  showcaseApi: 'https://apis.pre-prd.ellos.org.br/ellos/showcase/api',
  easyTradeApi: 'https://api.equalizacao.ellos.org.br/ellos/easytrade/api',
  meetingApi: 'https://apis.pre-prd.ellos.org.br/ellos/matchmaking/api',
  ncmApi: 'https://apis.pre-prd.ellos.org.br/ellos/ncm/api',
  companyApi: 'https://apis.pre-prd.ellos.org.br/ellos/company/api',
  exportationApi: 'https://api.equalizacao.ellos.org.br/ellos/easytrade-exportation/api',
  easyTradeCertifierApi: 'https://apis.pre-prd.ellos.org.br/ellos/easytrade-certifiers/api',
  originCertificate: 'ADICIONAR-API QUANDO EXISTIR',
  easytradeCustomsApi: 'https://apis.pre-prd.ellos.org.br/ellos/easytrade-customs/api',
  profileApi: 'https://apis.pre-prd.ellos.org.br/ellos/profile/api',
  reCaptchaSiteKey: '6LdDTKsfAAAAAMrOYAJkrP14nkdia0737kTtuvEw'
};
