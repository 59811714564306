<div fxLayout="column" class="dialog" fxFlex="grow">
  <div mat-dialog-title>
    <div fxLayout="row" fxLayoutAlign="space-between center ">
      <span></span>
      <p>{{ 'shared.request-aproval-dialog.title' | translate }}</p>

      <button mat-icon-button matDialogClose>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <mat-divider class="divider"></mat-divider>
  </div>
  <div mat-dialog-content fxLayout="column" fxLayoutAlign="center center" class="main-content">
    <p class="mat-h3" color="primary" [innerHTML]="'shared.request-aproval-dialog.label' | translate" style="text-align: center"></p>
  </div>

  <mat-divider class="divider"></mat-divider>
  <div mat-dialog-actions class="dialog-content" fxLayout="row" fxLayoutAlign="center center">
    <button mat-button [matDialogClose]="true" class="btn-color" styel="text-aling: center;" (click)="RedirectToMonitoring()">
      {{ ('shared.request-aproval-dialog.yes-button' | translate).toUpperCase() }}
    </button>
  </div>
</div>
