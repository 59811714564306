import { environment } from 'src/environments/environment';
export const getEnviroment = (): string => {
  switch (environment.develop || environment.homolog || environment.prePrd || environment.production) {
    case environment.develop:
      return 'develop';
    case environment.homolog:
      return 'homolog';
    case environment.prePrd:
      return 'pre-prd';
    case environment.production:
      return 'production';
    default:
      return 'develop';
  }
};
