import { Ncm } from 'src/app/shared/models/ncm.interface';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductExportationRequest } from '../../Models/product-exportation-request.interface';
import { ProductExportation } from '../../Models/product-exportation.interface';
import { debug } from 'console';

@Component({
  selector: 'app-exportation-products',
  templateUrl: './exportation-products.component.html',
  styleUrls: ['./exportation-products.component.scss']
})
export class ExportationProductsComponent implements OnInit {
  productsRequest: ProductExportationRequest[] = [];
  @Input() productsGrid: ProductExportation[] = [];
  @Input() canEdit: boolean = true;
  @Input() isDue: boolean = false;
  @Input() useProductValue: boolean = false;
  @Input() defaultNcm: Ncm;
  @Input() defaultquantity: any;
  @Input() defaultUnitMeasure: any;
  @Input() defaultUnitMeasureId: any;
  @Input() defaultNetWeight: any;
  @Input() defaultGrossWeight: any;
  @Input() defaultDescriptionProduct: any;
  @Input() isOriginCertificate = false;
  productToEdit: ProductExportation;

  alreadyParseProducts: boolean = false;

  @Output() productList: EventEmitter<ProductExportationRequest[]> = new EventEmitter<ProductExportationRequest[]>();
  @Output('isEditProduct') isEditProduct = new EventEmitter<boolean>();
  productEdition: boolean = false;
  constructor() {}
  indexListProduct = 0;
  ngOnInit(): void {}

  ngDoCheck(): void {
    if (this.productsGrid.length > 0 && !this.alreadyParseProducts && this.productsRequest.length == 0) {
      this.productsGrid.forEach((p) => {
        this.productsRequest.push({
          ncmCode: p.ncm?.ncmCode,
          idUnitMeasure: p.unitMeasure?.id,
          quantity: p.quantity,
          idUnitMeasureWeight: p.unitMeasureWeight?.id,
          grossWeight: p.grossWeight,
          netWeight: p.netWeight,
          manufactoringDate: p.manufactoringDate,
          expirationDate: p.expirationDate,
          description: p.description,
          marksAndNumbers: p.marksAndNumbers,
          sif: p.sif,
          productValue: p.productValue,
          currency: p.currency,
          id: p.id,
          order: p?.order
        });

        this.productsRequest = [...this.productsRequest];
      });
      this.productsGrid.sort((a, b) => a.id - b.id);
      this.productsRequest.sort((a, b) => a.id - b.id);
      this.alreadyParseProducts = true;
    }
  }

  rollbackProduct(product: ProductExportation) {
    this.addProduct(product, true);
  }

  addProduct(product: ProductExportation, isRollBack?: boolean) {
    if (isRollBack) {
      this.productsGrid.push(product);
      this.productsGrid.sort((a) => a.order);
      this.productsGrid = [...this.productsGrid];
      this.productsRequest.push({
        quantity: +product.quantity,
        description: product.description,
        expirationDate: product.expirationDate,
        grossWeight: product.grossWeight,
        idUnitMeasure: product.unitMeasure.id,
        idUnitMeasureWeight: product.unitMeasureWeight.id,
        manufactoringDate: product.manufactoringDate,
        marksAndNumbers: product.marksAndNumbers,
        ncmCode: product.ncm.ncmCode,
        netWeight: product.netWeight,
        sif: product.sif,
        productValue: product.productValue,
        currency: product.currency,
        id: product.id,
        order: product?.order
      });
      this.productsRequest.sort((a) => a.order);
      this.productsRequest = [...this.productsRequest];
      this.productEdition = false;
      this.emitProducts();
      return;
    }
    this.productsGrid.splice(this.indexListProduct, 0, product);
    this.productsGrid.sort((a) => a.order);
    this.productsGrid = [...this.productsGrid];
    this.productsRequest.splice(this.indexListProduct, 0, {
      quantity: +product.quantity,
      description: product.description,
      expirationDate: product.expirationDate,
      grossWeight: product.grossWeight,
      idUnitMeasure: product.unitMeasure.id,
      idUnitMeasureWeight: product.unitMeasureWeight.id,
      manufactoringDate: product.manufactoringDate,
      marksAndNumbers: product.marksAndNumbers,
      ncmCode: product.ncm.ncmCode,
      netWeight: product.netWeight,
      sif: product.sif,
      productValue: product.productValue,
      currency: product.currency,
      id: product.id,
      order: product?.order
    });
    this.productsRequest.sort((a) => a.order);
    this.productsRequest = [...this.productsRequest];
    this.productEdition = false;
    this.indexListProduct = 0;
    this.emitProducts();
  }

  deleteProduct(index: number) {
    this.productsGrid.sort((a) => a.order);
    this.productsGrid.splice(index, 1);
    this.productsGrid = [...this.productsGrid];
    this.productsRequest.sort((a) => a.order);
    this.productsRequest.splice(index, 1);
    this.productsRequest = [...this.productsRequest];
    this.emitProducts();
  }

  editProduct(index: number) {
    this.productToEdit = this.productsGrid[index];
    this.indexListProduct = index;
    this.deleteProduct(index);
    this.emitIsEditProduct();
  }

  emitProducts() {
    this.productList.emit(this.productsRequest);
  }

  emitIsEditProduct() {
    this.isEditProduct.emit(true);
    this.productEdition = true;
  }
}
