<br />
<br />
<br />
<div fxLayout="row" fxLayoutAlign="center center">
  <p class="mat-h1">Login</p>
</div>

<br />

<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" (capslockOn)="capsLock($event)">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div fxLayout="column" fxFlex="60">
      <!-- email -->
      <mat-form-field appearance="outline">
        <mat-label>{{ 'registration.email' | translate }}</mat-label>
        <input formControlName="email" type="email" autocomplete="off" matInput placeholder="example@example.com" />
        <mat-error *ngIf="loginForm.controls.email.hasError('required')">
          {{ 'error.email-required' | translate }}
        </mat-error>
        <mat-error *ngIf="loginForm.controls.email.hasError('email')">
          {{ 'error.email-invalid' | translate }}
        </mat-error>
      </mat-form-field>

      <!-- password -->

      <mat-form-field class="input" color="primary" appearance="outline">
        <mat-label>{{ 'auth.login.password' | translate }}</mat-label>
        <input formControlName="password" matInput type="password" placeholder="{{ 'auth.login.password' | translate }}" />
        <mat-error *ngIf="loginForm.controls.password.hasError('required')">
          {{ 'error.password-required' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-error *ngIf="capslockOn">{{ 'error.caps-lock-on' | translate }}</mat-error>

      <app-recaptcha (valueGrecaptcha)="handleValueRecaptcha($event)" formControlName="recaptcha"></app-recaptcha>

      <br />
      <!-- login button -->
      <button class="input" mat-raised-button color="accent" [disabled]="!isFormValid() || !loginForm.get('recaptcha').value">
        {{ 'auth.login.login-button' | translate }}
      </button>
      <br />

      <!-- forgot password -->
      <a mat-button routerLink="/Forgot-password">
        {{ 'auth.login.forgot-button' | translate }}
      </a>

      <!-- know ellos -->
      <a mat-button href="https://conheca.ellos.org.br/" color="accent">
        {{ 'auth.login.know-ellos' | translate }}
      </a>

      <!-- register -->
      <a mat-button routerLink="/Register" color="accent">
        {{ 'auth.login.register-button' | translate }}
      </a>
    </div>
  </div>
</form>
