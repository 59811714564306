import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TourService } from 'ngx-ui-tour-md-menu';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { ChangeTheme } from '../../core/actions/theme.action';
import { SessionUser } from 'src/app/shared/models/session-user';
import { faGlobeAfrica } from '@fortawesome/free-solid-svg-icons';

import { AuthService } from 'src/app/core/services/auth.service';
import { ThemeService } from 'src/app/core/services/theme.service';
import { PermissionsService } from 'src/app/core/services/permissions.service';

import Theme from 'src/app/core/interfaces/theme.interface';
import { Company } from 'src/app/core/interfaces/company.interface';
import { CompanyPreview } from 'src/app/core/interfaces/company-preview.interface';

import { PermissionsEnum } from 'src/app/core/enums/permissions.enum';
import { EasyTradeService } from 'src/app/modules/easy-trade/services/easy-trade.service';
import { CompanyExportationNotification } from 'src/app/modules/certificates/interfaces/exportation-notification.interface';
import { CompanySelectorComponent } from 'src/app/shared/components/company-selector/company-selector.component';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  user: SessionUser;
  companies: Company[];
  company: CompanyPreview;
  public subscription: any;
  selectedCompany: Company;
  currentThemeName: string;
  faGlobeAfrica = faGlobeAfrica;
  destroy$: Subject<any> = new Subject<any>();

  theme$: Observable<Theme>;
  loggedIn$: Observable<boolean>;
  company$: Observable<CompanyPreview>;
  sessionUser$: Observable<SessionUser>;

  public modules = [];
  public modulePermission = PermissionsEnum;
  hidden = false;
  showFiller = false;
  public notifications: CompanyExportationNotification[] = [];
  public notificationsCount = 0;
  public scrollDistance = 1;
  public loadingMore = false;
  canLoadMore: boolean = true;
  public notificationValidation = false;
  @Input() showTimeZone = true;
  @Input() showCompanySelector = true;
  @Input() ShowTranslationDropdown = false;
  @Input() opened = false;
  @Input() showNotifications: CompanyExportationNotification[] = [];

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly themeService: ThemeService,
    private readonly companyState: Store<{ company: CompanyPreview }>,
    private readonly themeStore: Store<{ theme: Theme }>,
    public tourService: TourService,
    private readonly sessionUserStore: Store<{ sessionUser: SessionUser }>,
    public permissionsService: PermissionsService,
    public readonly easyTradeService: EasyTradeService,
    private readonly dialog: MatDialog,
    private cookieService: CookieService
  ) {
    this.company$ = this.companyState.pipe(select('company'));
    this.sessionUser$ = this.sessionUserStore.pipe(select('sessionUser'));
    this.theme$ = themeStore.pipe(select('theme'));
  }

  async ngOnInit(): Promise<any> {
    this.resetTheme();
    this.theme$.pipe(takeUntil(this.destroy$)).subscribe((newTheme: Theme) => {
      if (newTheme) {
        this.currentThemeName = newTheme.displayName;
      }
    });

    this.sessionUser$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });

    this.company$.pipe(takeUntil(this.destroy$)).subscribe((company) => {
      if (company) {
        this.company = company;
      }
    });

    this.subscription = this.permissionsService.getPermissionschangeEmitter().subscribe((item) => {
      this.modules = item;
    });
    await this.listNotification();
    this.notificationsCount = this.showNotifications.length;
    this.notificationValidation = this.notificationsCount > 0;
    this.opened = false;
  }

  async ngOnChanges() {
    await this.listNotification();
  }

  private resetTheme(): void {
    try {
      const defaultTheme = this.themeService.resetTheme();
      this.currentThemeName = defaultTheme.displayName;
      this.themeStore.dispatch(ChangeTheme(defaultTheme));
    } catch (error) {
      console.error(error);
    }
  }

  changeTheme(): void {
    const currentTheme: Theme = this.themeService.changeTheme();
    this.themeStore.dispatch(ChangeTheme(currentTheme));
    this.currentThemeName = currentTheme.displayName;
  }

  closeSessionAndRedirectToLogin() {
    this.dialog.closeAll();
    this.authService.cleanAllLocalStorage();
    this.authService.cleanAllCookies();
    this.authService.logout();
    this.router.navigateByUrl('/Login');
  }

  logout(): void {
    this.resetTheme();
    this.closeSessionAndRedirectToLogin();
  }

  async refresh() {
    const TokenRefresh = this.cookieService.get('RefreshToken');
    await this.authService.RefreshToken(TokenRefresh);
  }

  payment(): void {
    this.router.navigateByUrl('/Payment');
  }

  validateTimeZone(): boolean {
    if (this.company?.timezone) {
      return true;
    } else {
      return false;
    }
  }

  async onScrollDown() {
    this.loadingMore = true;
    if (this.showNotifications.length == 0) {
      this.loadingMore = false;
      return;
    }
    this.showNotifications = [...this.showNotifications, ...this.notifications];
    this.showNotifications = this.showNotifications.slice(0, this.showNotifications.length);
    this.loadingMore = false;
  }

  validateHiddenTimeZone(): boolean {
    return !this.modules.includes(this.modulePermission.AcessarPerfilHome);
  }

  navigateSettings() {
    this.router.navigateByUrl('/Settings/MyProfile');
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.subscription.unsubscribe();
  }
  toggleBadgeVisibility() {
    this.hidden = !this.hidden;
  }

  async listNotification() {
    try {
      this.notifications = await this.easyTradeService.listCompanyExportationNotifications(this.company.id);
      this.showNotifications = [...this.notifications];
      this.showNotifications = this.showNotifications.slice(0, 5);
    } catch (error) {
      error;
    }
  }
  onCompanyChange(event: CompanyExportationNotification[]) {
    this.notifications = event;
    this.showNotifications = event;
    this.notificationsCount = this.showNotifications.length;
    this.notificationValidation = this.notificationsCount > 0;
    this.opened = false;
  }
}
