<mat-toolbar class="mkt-navbar colorful-mat-toolbar">
  <span class="example-spacer"></span>
  <div class="mkt-navbar-options" fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="end center">
    <button
      class="nav-button"
      *ngIf="modules.includes(modulePermission.AcessarPerfilHome)"
      tourAnchor="settings.my-profile"
      mat-button
      routerLink="/Settings/MyProfile"
      routerLinkActive="active-route"
    >
      <mat-icon>person</mat-icon>
      {{ 'settings.navbar.my-profile' | translate }}
    </button>

    <button class="nav-button" tourAnchor="settings.my-companies" mat-button routerLink="/Settings/Companies" routerLinkActive="active-route">
      <mat-icon>business</mat-icon>
      {{ 'settings.navbar.companies' | translate }}
    </button>

    <button
      *blockCompanyProfile="['EMB', 'CTS']"
      class="nav-button"
      tourAnchor="settings.company-management"
      mat-button
      routerLink="/Settings/CompanyManagement"
      routerLinkActive="active-route"
    >
      <mat-icon>business</mat-icon>
      {{ 'settings.navbar.company-management' | translate }}
    </button>

    <button
      *ngIf="modules.includes(modulePermission.VisualizarConvites)"
      class="nav-button"
      tourAnchor="settings.invitations"
      mat-button
      routerLink="/Settings/MyInvitations"
      routerLinkActive="active-route"
    >
      <mat-icon>create_new_folder</mat-icon>
      {{ 'settings.navbar.my-invitations' | translate }}
    </button>
    <button *ngIf="modules.includes(modulePermission.EntrarContatoCamara)" class="nav-button" mat-button routerLink="/Settings/ContactAndHelp" routerLinkActive="active-route">
      <mat-icon>help</mat-icon>
      {{ 'settings.navbar.contact-and-help' | translate }}
    </button>
  </div>
</mat-toolbar>
