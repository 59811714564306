<section class="table">
  <table mat-table [dataSource]="dataSource?.results" fxFill>
    <ng-container matColumnDef="checkbox">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox [(ngModel)]="checked" (change)="selectUnselectAll()"></mat-checkbox>
      </th>
      <!-- <td mat-cell *matCellDef="let element"><mat-checkbox [checked]="element.checkbox" (change)="selectCertificate($event, element)"></mat-checkbox></td> -->
      <td mat-cell *matCellDef="let element">
        <mat-checkbox *ngIf="element.status !== 0" [checked]="element.checkbox" (change)="selectCertificate($event, element)"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="certificate">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'easy-trade.origin-certificate.table.certificate' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element?.certificate }}</td>
    </ng-container>
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'easy-trade.origin-certificate.table.emission-date' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.emissionDate | ellosDate : false }}
      </td>
    </ng-container>

    <ng-container matColumnDef="protocol">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'easy-trade.origin-certificate.table.protocol' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.protocol }}</td>
    </ng-container>

    <ng-container matColumnDef="process">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'easy-trade.origin-certificate.table.process' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.process }}</td>
    </ng-container>

    <ng-container matColumnDef="invoice">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'easy-trade.origin-certificate.table.invoice' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.invoice }}</td>
    </ng-container>

    <ng-container matColumnDef="invoiceDate">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'easy-trade.origin-certificate.table.invoice-date' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.invoiceDate | ellosDate : false }}
      </td>
    </ng-container>

    <ng-container matColumnDef="destinationCountry">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'easy-trade.origin-certificate.table.country' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.destinationCountry }}
      </td>
    </ng-container>

    <ng-container matColumnDef="importer">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'easy-trade.origin-certificate.table.importer' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.importer | truncate : 30 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="status-certifier">
        {{ 'easy-trade.origin-certificate.table.status' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <app-origin-certificate-status-label [status]="element.status"></app-origin-certificate-status-label>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'easy-trade.origin-certificate.table.actions' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item *ngIf="element.status == 0 && element.status == ocStatus.NotValidate" (click)="validateCObyBl(element?.id, true)">
            <a mat-button class="button">
              <mat-icon matPrefix> done </mat-icon>
              <span class="pl-1">{{ 'easy-trade.origin-certificate.table.buttons.request-approval' | translate }}</span>
            </a>
          </button>
          <button mat-menu-item *ngIf="element.status == 0" (click)="validateCObyBl(element?.id, false)">
            <a mat-button class="button">
              <mat-icon matPrefix> compare_arrows </mat-icon>
              <span class="pl-1">Easy Trade</span>
            </a>
          </button>
          <button mat-menu-item>
            <a mat-button class="button" [routerLink]="'/Certificates/Certificateorigin/DetailOriginCertificate/' + element.id">
              <mat-icon matPrefix>visibility</mat-icon>
              <span class="pl-1">{{ 'easy-trade.origin-certificate.table.buttons.details' | translate }}</span>
            </a>
          </button>
          <button mat-menu-item [class.hide-btn]="element.status">
            <a *ngIf="element.status == 0" mat-button class="button" [routerLink]="'/Certificates/Certificateorigin/EditOriginCertificate/' + element.id">
              <mat-icon matPrefix>edit</mat-icon>
              <span class="pl-1">{{ 'easy-trade.origin-certificate.table.buttons.edit' | translate }}</span>
            </a>
          </button>
          <button mat-menu-item [class.hide-btn]="element.status">
            <a *ngIf="element.status == 0" mat-button class="button" (click)="deleteOC(element?.id)">
              <mat-icon matPrefix>delete</mat-icon>
              <span class="pl-1">{{ 'easy-trade.origin-certificate.table.buttons.delete' | translate }}</span>
            </a>
          </button>
          <button mat-menu-item>
            <a mat-button class="button" (click)="duplicateOC(element?.id)">
              <mat-icon matPrefix>file_copy</mat-icon>
              <span class="pl-1">{{ 'easy-trade.origin-certificate.table.buttons.duplicate' | translate }}</span>
            </a>
          </button>
          <button mat-menu-item [class.hide-btn]="!element?.certificate">
            <a mat-button class="button" *ngIf="element?.certificate" (click)="downloadOC(element?.id, element?.status, element?.certificate, element?.invoice)">
              <mat-icon matPrefix>cloud_download</mat-icon>
              <span class="pl-1">{{ 'easy-trade.origin-certificate.table.buttons.download' | translate }}</span>
            </a>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [length]="dataSource?.total" [pageSizeOptions]="[5, 10, 25, 100]" [pageIndex]="pageIndex" [pageSize]="10" (page)="filterPage($event)"> </mat-paginator>
</section>
