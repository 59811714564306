import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { CompanyPreview } from 'src/app/core/interfaces/company-preview.interface';
import { AuthService } from 'src/app/core/services/auth.service';

@Directive({
  selector: '[blockCompanyProfile]'
})
export class BlockByCompanyProfileDirective {
  company$: Observable<CompanyPreview>;
  destroy$ = new Subject<any>();
  @Input() blockCompanyProfile: string[] = [];

  constructor(
    private readonly companyState: Store<{ company: CompanyPreview }>,
    private readonly authService: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
    this.company$ = this.companyState.pipe(select('company'));
  }
  ngOnInit() {
    this.company$
      .pipe(
        takeUntil(this.destroy$),
        map((company) => {
          // Doesn't matter if the company has other profiles, new trade will always be blocked
          // when profile is BRA - Brazilian Authority
          if (company?.profiles?.find((profile) => profile === 'BRA')) return false;

          return company?.profiles?.some((role) => this.blockCompanyProfile.indexOf(role) < 0);
        })
      )
      .subscribe((allowed) => {
        if (allowed && this.viewContainer.length == 0) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else if (!allowed) {
          this.viewContainer.clear();
        }
      });
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
