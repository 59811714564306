export const URLS_LIST_TO_NOT_SHOW_MODAL_ERROR = [
  'importFileByLines',
  '/Crm/company/cnpj/',
  '/ForwardingAgent/getAllByExportation',
  '/Exportation/getDueInfo',
  'Profile/SearchProfile',
  'UserConsent/consentCookie',
  '/Crm/',
  'getByIdReference?IdReference'
];
