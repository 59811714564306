import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ProductExportation } from '../../../Models/product-exportation.interface';

@Component({
  selector: 'app-product-exportation-grid',
  templateUrl: './product-exportation-grid.component.html',
  styleUrls: ['./product-exportation-grid.component.scss']
})
export class ProductExportationGridComponent implements OnInit {
  @Input() dataSource: ProductExportation[] = [];
  @Input() readonlyGrid: boolean = false;
  @Input() canEdit: boolean = true;
  @Input() isEditProduct: boolean = false;

  @Input() useProductValue: boolean = false;
  public totalWeight: number = 0;
  public totalNetWeight: number = 0;
  public Currency = ['BRL', 'USD', 'EUR'];
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() deletedProduct = new EventEmitter<ProductExportation>();
  @Output() editProductEvent = new EventEmitter<number>();
  @Output() upProductEvent = new EventEmitter<number>();
  @Output() downProductEvent = new EventEmitter<number>();
  @Output() iseditProductEvent = new EventEmitter<boolean>();

  public displayedColumns: string[] = [];
  datasource = new MatTableDataSource<ProductExportation>([]);
  constructor() {}

  ngOnInit(): void {
    this.displayedColumns = ['ncm', 'amount', 'unit', 'grossWeight', 'netWeight', 'marks', 'sif', 'productionDate', 'expirationDate', 'description'];

    if (!this.readonlyGrid) {
      this.displayedColumns.push('actions');
    }
    // add the product value column right before description column
    if (this.useProductValue) {
      this.displayedColumns.splice(9, 0, 'productValue');
    }
  }

  checkEdit(): boolean {
    if (this.canEdit) {
      return true;
    } else {
      return this.canEdit && !this.readonlyGrid;
    }
  }

  ngDoCheck() {
    this.calculateTotalWeight();
  }

  ngOnChanges(e) {
    if (e.dataSource) {
      if (e.dataSource.currentValue?.length > 0) {
        e.dataSource.currentValue.sort(this.compareteById);
      }
      this.datasource = new MatTableDataSource<ProductExportation>(e.dataSource.currentValue);
      this.datasource.paginator = this.paginator;
      this.calculateTotalWeight();
      if (this.table) {
        this.table.renderRows();
      }
    }
  }

  compareteById(a, b) {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
  }
  ngAfterViewInit() {
    this.datasource.paginator = this.paginator;
  }

  public deleteProduct(index) {
    this.deletedProduct.emit(index);
  }

  editProduct(index) {
    this.editProductEvent.emit(index);
  }

  upProduct(index) {
    this.upProductEvent.emit(index);
  }

  downProduct(index) {
    this.downProductEvent.emit(index);
  }

  public calculateTotalWeight() {
    this.totalWeight = 0;
    this.totalNetWeight = 0;

    if (this.dataSource?.length > 0) {
      this.dataSource.forEach((element) => {
        let restTotalWeight = this.totalWeight + element?.grossWeight || 0;
        this.totalWeight = parseFloat(restTotalWeight.toFixed(2));

        let restTotalNetWeight = this.totalNetWeight + element?.netWeight || 0;
        this.totalNetWeight = parseFloat(restTotalNetWeight.toFixed(2));
      });
    }
  }
}
