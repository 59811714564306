import { FormBuilder } from '@angular/forms';
import { TransshipmentMethodEnum } from '../../enums/new-trade.enum';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Transshipment } from 'src/app/shared/models/transshipment.interface';
import { faPlaneDeparture, faPlaneArrival, faAnchor, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faShip } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-transshipment-flow',
  templateUrl: './transshipment-flow.component.html',
  styleUrls: ['./transshipment-flow.component.scss']
})
export class TransshipmentFlowComponent implements OnInit, OnChanges {
  faPlaneDeparture = faPlaneDeparture;
  faShip = faShip;
  faAnchor = faAnchor;
  faPlaneArrival = faPlaneArrival;
  faMapMarkerAlt = faMapMarkerAlt;
  @Input() isSummary: boolean;
  @Input() shipmentMethod: TransshipmentMethodEnum = TransshipmentMethodEnum.plane;
  @Input() isNewTrade: boolean;
  transhipmentType = TransshipmentMethodEnum;
  transshipments: Transshipment[] = [{}, {}];
  @Input() defaultTranshipments: Transshipment[];
  @Input() readonlyField: boolean = false;
  @Input() reset: boolean = false;
  urlExportationService: string = environment.exportationApi;
  @Output() resetEvent$ = new EventEmitter<any>(undefined);
  @Output() transshipmentFlow: EventEmitter<Transshipment[]> = new EventEmitter<Transshipment[]>();
  constructor(private readonly fb: FormBuilder) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.shipmentMethod && !changes.shipmentMethod.firstChange) {
      this.resetFlow();
      this.emitTransship();
    }

    if (changes.defaultTranshipments?.currentValue) {
      const objetosComIdValido = changes.defaultTranshipments?.currentValue.filter((objeto) => objeto.id !== undefined && objeto.id !== null);
      const temPeloMenosDoisIdsValidos = objetosComIdValido.length >= 2;
      if (temPeloMenosDoisIdsValidos) {
        this.transshipments = this.defaultTranshipments;
      }
    }
  }

  getLabel(index: number) {
    let isDestiny = this.transshipments.length === index + 1;

    if (!isDestiny && this.shipmentMethod == this.transhipmentType.ship) {
      return 'easy-trade.new-trade.stepper.shipment-information.portDeparture';
    } else if (isDestiny && this.shipmentMethod == this.transhipmentType.ship) {
      return 'easy-trade.new-trade.stepper.shipment-information.portDestination';
    } else if (!isDestiny && this.shipmentMethod == this.transhipmentType.plane) {
      return 'easy-trade.new-trade.stepper.shipment-information.airport-departure';
    } else if (isDestiny && this.shipmentMethod == this.transhipmentType.plane) {
      return 'easy-trade.new-trade.stepper.shipment-information.airport-destination';
    }
  }
  newTranship(): Transshipment {
    return {};
  }
  addShipment() {
    this.transshipments.push(this.newTranship());
    this.emitTransship();
  }
  removeTranshipment(i: number) {
    if (this.transshipments.length > 2) this.transshipments.splice(i, 1);

    this.emitTransship();
  }
  getIds() {
    return this.transshipments.map((t) => t.id);
  }
  resetFlow() {
    this.transshipments = [{}, {}];
    this.defaultTranshipments = [{}, {}];
  }
  setTransshipment(event, index) {
    this.transshipments[index].code = event?.code;
    this.transshipments[index].id = event?.id;
    this.transshipments[index].name = event?.name;
    this.emitTransship();
  }

  validateTransshipment(IdtransshipmentsSelected) {
    let indexDoItemEncontradoNasLocalidades;
    for (let index = 0; index < this.transshipments.length; index++) {
      if (this.transshipments[index]?.id == IdtransshipmentsSelected) {
        indexDoItemEncontradoNasLocalidades = index;
      }
    }
    if (indexDoItemEncontradoNasLocalidades !== undefined) {
      this.transshipments[indexDoItemEncontradoNasLocalidades].id = -1;
    }
  }

  emitTransship() {
    this.transshipmentFlow.emit(this.transshipments);
  }

  emitReset() {
    this.resetEvent$.emit();
  }
}
