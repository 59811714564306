import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { MonitoringItem } from '../interfaces/monitoring-item.interface';
import { MonitoringFilter } from '../interfaces/monitoring-filter.interface';

import EllosRequest from 'src/app/core/interfaces/ellosRequest.interface';
import { EllosApi } from 'src/app/core/interfaces/ellosApi.interface';
import { EllosApiService } from 'src/app/core/http/ellosApi.service';
import { Trader } from '../interfaces/trader.interface';
import { TraderType } from '../config/trader-type.enum';
import { Template } from '../Models/Template/Template.interface';
import { Certificate } from '../interfaces/Certificates/Certificate.interface';
import { PaginatedResult } from '../Models/Template/PaginetedResult';
import { Conveyrs } from '../interfaces/Conveyrs.interface';
import { stringify } from 'query-string';
import { CompanyExportationNotification } from '../../certificates/interfaces/exportation-notification.interface';

@Injectable({
  providedIn: 'root'
})
export class EasyTradeService {
  getTraderComboList(exporter: TraderType) {
    throw new Error('Method not implemented.');
  }
  monitoringList$: BehaviorSubject<MonitoringItem[]> = new BehaviorSubject<MonitoringItem[]>(null);

  constructor(private readonly ellosApi: EllosApiService) {}

  getMonitoringList(filter: MonitoringFilter, pageIndex: number, pageSize: number) {}

  async getTraderData(traderId: number) {
    let request: EllosRequest = {
      ellosApi: EllosApi.EasyTrade,
      resource: `Trader/${traderId}`,
      body: null
    };

    let response = await this.ellosApi.get<Trader>(request);

    return response.data;
  }

  async postTraderData(traderData: Trader) {
    let request: EllosRequest = {
      ellosApi: EllosApi.EasyTrade,
      resource: `Trader`,
      body: traderData
    };

    let response = await this.ellosApi.post<boolean>(request);

    return response;
  }

  async getExporterComboList() {
    let request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `Associate/exporter`,
      body: null
    };
    let response = await this.ellosApi.get<Trader>(request);

    return response.data;
  }

  async getImportersComboList() {
    let request: EllosRequest = {
      ellosApi: EllosApi.Company,
      resource: `Company/importers`,
      body: null
    };
    let response = await this.ellosApi.get<Trader>(request);

    return response.data;
  }

  async postTemplateData(TemplateData: Template) {
    let request: EllosRequest = {
      ellosApi: EllosApi.EasyTrade,
      resource: `Templates`,
      body: TemplateData
    };

    let response = await this.ellosApi.post<boolean>(request);

    return response;
  }

  async postCertificateData(CertificateData: any) {
    let request: EllosRequest = {
      ellosApi: EllosApi.EasyTrade,
      resource: `Exportation/Certificates`,
      body: CertificateData
    };

    let response = await this.ellosApi.post<boolean>(request);

    return response;
  }

  async postExportationData(TemplateData: any) {
    let request: EllosRequest = {
      ellosApi: EllosApi.EasyTrade,
      resource: `Exportation`,
      body: TemplateData
    };

    let response = await this.ellosApi.post<boolean>(request);

    return response;
  }

  async postCertificatesData(certificateData: any, exportationId: string) {
    let request: EllosRequest = {
      ellosApi: EllosApi.EasyTrade,
      resource: `Exportation/Certificates/${exportationId}`,
      body: certificateData
    };

    let response = await this.ellosApi.post<boolean>(request);

    return response;
  }

  async postOriginCertificateSaveTrader(certificateId: number, traderData: any) {
    let request: EllosRequest = {
      ellosApi: EllosApi.EasyTrade,
      resource: `Trader/Save/Certificate/${certificateId}`,
      body: traderData
    };

    let response = await this.ellosApi.post<boolean>(request);

    return response;
  }

  async getTemplatesByCompany(companyId: string, token: string = null) {
    let url = `Templates/${companyId}`;
    let body = {};

    if (token) {
      body = { token: token };
    }

    let request: EllosRequest = {
      ellosApi: EllosApi.EasyTrade,
      resource: url,
      body: body
    };

    let response = await this.ellosApi.post<PaginatedResult<Template>>(request);

    return response;
  }

  async getTemplatesById(id: string) {
    let request: EllosRequest = {
      ellosApi: EllosApi.EasyTrade,
      resource: `Templates/${id}`,
      body: null
    };

    let response = await this.ellosApi.get<Template>(request);

    return response;
  }

  async deleteTemplate(id: string) {
    let request: EllosRequest = {
      ellosApi: EllosApi.EasyTrade,
      resource: `Templates/${id}`,
      body: null
    };

    let response = await this.ellosApi.delete<boolean>(request);

    return response;
  }

  async getCertificatesByCountry(ncm: string, countryIso: string) {
    let request: EllosRequest = {
      ellosApi: EllosApi.EasyTrade,
      resource: `Ncm/Certificates?countryIso=${countryIso}&ncm=${ncm}`,
      body: null
    };
    let response = await this.ellosApi.get<Certificate>(request);

    return response.data;
  }

  async getPorts() {
    let request: EllosRequest = {
      ellosApi: EllosApi.EasyTrade,
      resource: `Exportation/ports`,
      body: null
    };
    let response = await this.ellosApi.get<Conveyrs>(request);

    return response.data;
  }

  async getAirPorts() {
    let request: EllosRequest = {
      ellosApi: EllosApi.EasyTrade,
      resource: `Exportation/airports`,
      body: null
    };
    let response = await this.ellosApi.get<Conveyrs>(request);

    return response.data;
  }

  async getCertificateUrl(urlRequest: { idCertificate: number; idExportation: string }): Promise<string> {
    let queryString = stringify(urlRequest);
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: `Certificate/download?${queryString}`,
      body: null
    };
    let response = await this.ellosApi.get<string>(request);

    return response.data;
  }

  async listCompanyExportationNotifications(idCompany: number) {
    let request: EllosRequest = {
      ellosApi: EllosApi.Exportation,
      resource: `Notification/getnotificationbyiduser?idUser=${idCompany}`,
      body: null
    };
    let response = await this.ellosApi.get<CompanyExportationNotification[]>(request);

    return response.data;
  }
}
