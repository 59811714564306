import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MatChipInputEvent } from '@angular/material/chips';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { EventsGridComponent } from '../../components/events-grid/events-grid.component';
import { EditEventDialogComponent } from '../../components/edit-event-dialog/edit-event-dialog.component';

import { Contact } from '../../models/contact';
import { Sector } from 'src/app/shared/models/sector';
import { SocialMedia } from '../../models/social-media';
import { CompanyDetail } from '../../models/company-detail';

import Language from 'src/app/core/interfaces/language.interface';
import { CompanyPreview } from 'src/app/core/interfaces/company-preview.interface';

import { CompanyService } from 'src/app/core/services/company.service';
import { CrmService } from 'src/app/shared/services/crm-service.service';
import { CRMDropDownService } from '../../services/crm-drop-down.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

import { select, Store } from '@ngrx/store';
import { ChangeCompany } from 'src/app/core/actions/company.action';
import { PrimaryChapter } from 'src/app/shared/models/primary-chapter';
import { SessionCompanies } from 'src/app/core/actions/session-companies.action';

import { take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { faFacebook, faInstagram, faLinkedin, faTwitter, faGgCircle } from '@fortawesome/free-brands-svg-icons';
import { Timezone } from 'src/app/shared/models/timezone';
import { Country } from 'src/app/shared/models/country';

@Component({
  selector: 'app-company-edit',
  templateUrl: './company-edit.component.html',
  styleUrls: ['./company-edit.component.scss']
})
export class CompanyEditComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  cnpjValidator: boolean = true;
  updatingTags = false;
  valuesInputContact: Contact[] = [];

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  company = this.fb.group({
    associationDate: [''],
    controls: [''],
    idCompany: [''],
    IdContact: [''],
    website: [''],
    countryNumericCode: [null as Country, Validators.required],
    city: ['', Validators.required],
    state: ['', Validators.required],
    district: ['', Validators.required],
    phoneNumber: ['', Validators.required],
    street: ['', Validators.required],
    number: ['', Validators.required],
    contactEmail: ['', Validators.required],
    fantasyName: ['', Validators.required],
    stateRegistry: ['', Validators.required],
    zipCode: ['', Validators.required],
    poBox: [''],
    complement: [''],
    fax: [''],
    cnpj: ['', Validators.required],
    timezone: [null as Timezone, [Validators.required]],
    tradelensId: ['']
  });
  primaryChapterId? = new FormControl(null as number, Validators.required);
  id?: [];
  name?: [];
  website: [];
  associationDate: [];
  country?: Country;
  city: [];
  state: [];
  district: [];
  phoneNumber: any;
  street: [];
  number: [];
  contactEmail: [];
  fantasyName: [];
  stateRegistry: [];
  zipCode: [];
  poBox: [];
  complement: [];
  fax: [];
  cnpj: [];
  status: [];
  profiles?: [][];
  timezone: Timezone;
  roleName: [];
  idTimezone?: number;
  tradelensId?: [];
  sectorId = new FormControl(null as number, Validators.required);
  importer = new FormControl(null as any);
  exporter = new FormControl(null as any);
  forwarding = new FormControl(null as any);
  @ViewChild('phoneNumberContactForm') phoneNumberContactForm: FormControl;

  products = [];

  socialMedia = this.fb.group({
    icon: ['', [Validators.required]],
    link: ['', [Validators.required]]
  });

  contactForm = this.fb.group({
    name: ['', [Validators.required]],
    role: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    phonenumber: ['', [Validators.required]]
  });

  medias: SocialMedia[] = [];
  destroy$ = new Subject();
  language$: Observable<Language>;
  saveEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  primaryChapter: PrimaryChapter[] = [];
  segments: Sector[] = [];
  companyId: any;
  loading: boolean;
  loadingSocialMedia: boolean;
  companyDetail: CompanyDetail;
  companyLogo: string;
  companyCover: string;
  contacts: Contact[] = [];
  originalContacts: Contact[] = [];
  tempContact: Contact;
  resetNumber: boolean;
  isAddMedia: boolean;
  companySession: CompanyPreview;
  companies: CompanyPreview[];
  sessionCompanies$: Observable<CompanyPreview[]>;
  private sessionCompany$: Observable<CompanyPreview>;
  language: string;
  faFacebook = faFacebook;
  faInstagram = faInstagram;
  faLinkedin = faLinkedin;
  faTwitter = faTwitter;
  faGgCircle = faGgCircle;
  @ViewChild('eventGrid') eventGrid: EventsGridComponent;
  constructor(
    private readonly fb: FormBuilder,
    private readonly langStore: Store<{ language: Language }>,
    private readonly crmDropDownService: CRMDropDownService,
    private readonly route: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly companySessionState: Store<{ company: CompanyPreview }>,
    private readonly crmService: CrmService,
    private readonly sessionCompaniesState: Store<{
      sessionCompanies: CompanyPreview[];
    }>,
    private readonly router: Router,
    private readonly companyService: CompanyService,
    private readonly notifyService: NotificationService
  ) {
    this.companyId = +this.route.snapshot.paramMap.get('id');
    this.sessionCompany$ = this.companySessionState.pipe(select('company'));
    this.sessionCompanies$ = this.sessionCompaniesState.pipe(select('sessionCompanies'));

    if (isNaN(this.companyId) || this.companyId < 1) {
      this.redirectToCompanies();
    }
    this.language$ = langStore.pipe(select('language'));
  }

  async ngOnInit() {
    this.company.markAllAsTouched();

    this.sessionCompanies$.pipe(takeUntil(this.destroy$)).subscribe(async (companies) => {
      this.companies = companies ? [...companies] : undefined;
    });
    this.sessionCompany$.pipe(takeUntil(this.destroy$)).subscribe((company) => {
      this.companySession = { ...company };
    });

    try {
      this.loading = true;
      this.companyDetail = await this.companyService.companyDetail(this.companyId);
      if (this.companyDetail && this.companyDetail.cnpj != null) {
        this.company.controls.cnpj.setValue(this.companyDetail.cnpj);
        this.company.controls.cnpj.disable();
      }

      if (!this.companyDetail || this.companyDetail.roleName.toUpperCase() !== 'ADMIN' || this.companyDetail?.status.toUpperCase() == 'INACTIVE') this.redirectToCompanies();

      this.companyLogo = await this.companyService.companyLogo(this.companyId);
      this.companyCover = await this.companyService.companyCover(this.companyId);
      this.medias = await this.companyService.getSocialMedia(this.companyId);
      this.contacts = await this.companyService.getCompanyContacts(this.companyId);
      this.originalContacts = await this.companyService.getCompanyContacts(this.companyId);

      this.contacts.forEach((element) => {
        element.disabledContactForEdit = true;
      });
      this.valuesInputContact = this.contacts;

      await this.getProductsTags();
      this.setDefaultValues();
      this.language$.pipe(takeUntil(this.destroy$)).subscribe(async (lang: Language) => {
        if (lang) {
          this.language = lang.code;
          this.getSectors();
          this.getPrimaryChapters();
        }
      });
      this.renderLogo();
      this.renderCover();

      this.loading = false;
    } catch (e) {
      this.redirectToCompanies();
    }
  }

  async getProductsTags() {
    this.products = await this.companyService.getProductsTag(this.companyId);
  }

  renderLogo() {
    if (this.companyLogo === '' || !this.companyLogo || this.companyLogo == null) {
      this.companyLogo = '../../../../assets/img/empty-company-logo.png';
    } else {
      this.companyLogo = 'data:image/png;base64,' + this.companyLogo;
    }
  }

  getIcon(media) {
    switch (media) {
      case 'facebook':
        return this.faFacebook;
      case 'instagram':
        return this.faInstagram;
      case 'linkedin':
        return this.faLinkedin;
      case 'twitter':
        return this.faTwitter;
      default:
        return this.faGgCircle;
    }
  }

  async getSectors() {
    if (this.language) {
      let lang;
      switch (this.language) {
        case 'en':
          lang = 'English';
          break;
        case 'pt':
          lang = 'Portuguese';
          break;
        default:
          break;
      }
      try {
        this.segments = await this.crmService.getSectors(lang);
      } catch {
        this.segments = [];
      }
    }
  }

  subscribeDialog() {
    let dialogRef = this.dialog.open(EditEventDialogComponent, {
      data: {
        idCompany: this.companyDetail.id,
        edit: false
      },
      width: '685px'
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((response) => {
        if (response) {
          this.eventGrid.getEvents();
        }
      });
  }

  async getPrimaryChapters() {
    if (this.language) {
      let lang;
      switch (this.language) {
        case 'en':
          lang = 'English';
          break;
        case 'pt':
          lang = 'Portuguese';
          break;
        default:
          break;
      }

      try {
        let idSector: any = this.sectorId.value;
        if (idSector && !isNaN(idSector)) {
          this.primaryChapter = await this.crmService.getPrimaryChapters(idSector, lang);
        }
      } catch {
        this.primaryChapter = [];
      }
    }
  }

  async selectedSector() {
    this.primaryChapterId.setValue(undefined);
    await this.getPrimaryChapters();
  }

  renderCover() {
    if (this.companyCover === '' || !this.companyCover || this.companyCover == null) {
      this.companyCover = '../../../../assets/img/store-cover.jpg';
    } else {
      this.companyCover = 'data:image/png;base64,' + this.companyCover;
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    let index = this.products.findIndex((p) => p === value.trim());
    if ((value || '').trim() && index < 0) {
      this.products.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }

  async updateProductsTag() {
    this.updatingTags = true;
    await this.companyService.updateProductsTag(this.companyId, this.products);
    this.updatingTags = false;
  }

  newSocialMedia(media: SocialMedia = { id: undefined, socialMedia: '', mediaUrl: '' }) {
    return this.fb.group({
      icon: [media?.socialMedia || '', [Validators.required]],
      link: [media?.mediaUrl || '', [Validators.required]]
    });
  }

  async addSocialMedia() {
    if (this.socialMedia.valid) {
      let socialMedia = {
        socialMedia: this.socialMedia.controls.icon.value,
        mediaUrl: this.socialMedia.controls.link.value,
        idCompany: +this.companyId
      };
      this.isAddMedia = true;
      let response = await this.companyService.addSocialMedia(socialMedia);

      if (response > 0) {
        this.medias.push({ id: response, ...socialMedia });
        this.socialMedia = this.newSocialMedia();
      }
      this.isAddMedia = false;
    }
  }

  newContact() {
    return this.fb.group({
      name: ['', [Validators.required]],
      role: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phonenumber: ['', [Validators.required]]
    });
  }

  async addContact(contactForm) {
    if (this.contactForm.valid) {
      let contact = {
        email: this.contactForm.controls.email.value,
        phone: this.contactForm.controls.phonenumber.value,
        name: this.contactForm.controls.name.value,
        role: this.contactForm.controls.role.value,
        idCompany: +this.companyId
      };
      this.resetNumber = true;
      let response = await this.companyService.addCompanyContact(contact);

      if (response > 0) {
        this.contacts.push({ id: response, ...contact });
        this.resetNumber = !this.resetNumber;
        this.contactForm = this.newContact();
        contactForm.resetForm();
        this.contactForm.reset();
        this.contactForm.updateValueAndValidity();
      }
      this.resetNumber = false;
    }
  }
  resetAddContact() {
    this.contactForm.reset();
    this.contactForm.controls.phonenumber.setValue('');
    this.phoneNumberContactForm.reset();
  }

  notAdded(socialMediaName: string) {
    let index = this.medias.findIndex((m) => m.socialMedia === socialMediaName);
    return index < 0;
  }

  async removeSocialMedia(mediaId: number, index: number) {
    let response = await this.companyService.removeSocialMedia(mediaId);
    if (response) {
      this.medias.splice(index, 1);
    }
  }

  async remove(i) {
    this.products.splice(i, 1);
  }

  redirectToCompanies() {
    this.router.navigateByUrl('/Settings/Companies');
  }

  onCountrySelected(event) {
    if (event.name !== 'Brazil') {
      this.company.controls.stateRegistry.setErrors(null);
      this.company.controls.cnpj.setValue('');
    }
    this.company.controls.countryNumericCode.setValue(event);
  }

  async removeContact(contactId: number, index: number) {
    let response = await this.companyService.removeContact(contactId);
    if (response) {
      this.contacts.splice(index, 1);
    }
  }

  async editContact(contactId: number, index: number) {
    let auxContact;
    this.contacts.forEach((element) => {
      if (element.id === contactId) {
        auxContact = element;
        element.disabledContactForEdit = false;
      } else {
        element.disabledContactForEdit = true;
      }
    });
    this.tempContact = auxContact;
  }

  logContact(index: number) {
    console.log(this.originalContacts[index]);
  }

  async cancel(index: number) {
    this.contacts[index] = cloneDeep(this.originalContacts[index]);
  }

  async saveContactEdited(contactId: number, index: number) {
    let response = await this.companyService.editContact({
      idCompany: this.companyId,
      IdContact: this.tempContact.id,
      email: this.valuesInputContact[index].email,
      phone: this.valuesInputContact[index].phone,
      name: this.valuesInputContact[index].name,
      role: this.valuesInputContact[index].role
    });
    this.contacts[index].disabledContactForEdit = true;
  }

  disableSaveCompanyInformations(): boolean {
    return this.loading || !this.company.valid;
  }

  setDefaultValues() {
    this.company.controls.idCompany.setValue(this.companyId);
    this.company.controls.website.setValue(this.companyDetail.website || '');
    this.company.controls.countryNumericCode.setValue(this.companyDetail.country || null);
    this.company.controls.city.setValue(this.companyDetail.city || '');
    this.company.controls.state.setValue(this.companyDetail.state || '');
    this.company.controls.phoneNumber.setValue(this.companyDetail.phoneNumber || '');
    this.company.controls.street.setValue(this.companyDetail.street || '');
    this.company.controls.number.setValue(this.companyDetail.number || '');
    this.company.controls.contactEmail.setValue(this.companyDetail.contactEmail || '');
    this.company.controls.fantasyName.setValue(this.companyDetail.fantasyName || '');
    this.sectorId.setValue(this.companyDetail.sectorId || null);
    this.primaryChapterId.setValue(this.companyDetail.primaryChapterId || undefined);
    this.company.controls.zipCode.setValue(this.companyDetail.zipCode || '');
    this.company.controls.district.setValue(this.companyDetail.district || '');
    this.company.controls.poBox.setValue(this.companyDetail.poBox || '');
    this.company.controls.complement.setValue(this.companyDetail.complement || '');
    this.company.controls.fax.setValue(this.companyDetail.fax || '');
    this.company.controls.cnpj.setValue(this.companyDetail.cnpj || '');
    this.company.controls.timezone.setValue(this.companyDetail.timezone || undefined);
    this.company.get('countryNumericCode').disable();

    if (this.companyDetail.country.alpha2Code !== 'BR') {
      this.company.controls.stateRegistry.setErrors(null);
      this.company.controls.cnpj.setValue('');
    } else {
      this.company.controls.stateRegistry.setValue(this.companyDetail.stateRegistry);
    }

    this.company.controls.tradelensId.setValue(this.companyDetail.tradelensId || '');

    if (this.companyDetail?.profiles) {
      let importer = this.companyDetail?.profiles.findIndex((p) => p.toUpperCase() == 'IMP');
      let exporter = this.companyDetail?.profiles.findIndex((p) => p.toUpperCase() == 'EXP');
      let forwarding = this.companyDetail?.profiles.findIndex((p) => p.toUpperCase() == 'FWA');

      this.exporter.setValue(exporter >= 0);
      this.importer.setValue(importer >= 0);
      this.forwarding.setValue(forwarding >= 0);
    } else {
      this.exporter.setValue(undefined);
      this.importer.setValue(undefined);
    }
  }

  async saveCompanyInformations() {
    this.loading = true;

    this.saveEvent.next(true);
    try {
      await this.updateProfile(this.exporter.value, 'Exporter');
      await this.updateProfile(this.importer.value, 'Importer');
      await this.updateProfile(this.forwarding.value, 'ForwardingAgent');
      await this.updateProductsTag();

      let companyUpdate: object = {
        idCompany: +this.company.value.idCompany,
        associationDate: this.company.value.associationDate,
        fantasyName: this.company.value.fantasyName,
        cnpj: this.company.value.cnpj || this.companyDetail.cnpj,
        contactEmail: this.company.value.contactEmail,
        stateRegistry: this.company.value.stateRegistry,
        zipCode: this.company.value.zipCode,
        street: this.company.value.street,
        website: this.company.value.website,
        number: this.company.value.number,
        city: this.company.value.city,
        state: this.company.value.state,
        district: this.company.value.district,
        complement: this.company.value.complement,
        poBox: this.company.value.poBox,
        phoneNumber: this.company.value.phoneNumber,
        fax: this.company.value.fax,
        tradelensId: this.company.value.tradelensId,
        sectorId: this.sectorId?.value || null,
        primaryChapterId: this.primaryChapterId?.value || null,
        idTimezone: this.company.controls.timezone?.value?.id || null
      };

      let response = await this.companyService.updateCompany(companyUpdate as CompanyDetail);
      if (response.success) {
        this.updateTimezoneCompanySession();
        this.router.navigateByUrl('/Settings/Companies');
      }
    } catch (e) {
      this.notifyError(e.error?.errors[0]);
    } finally {
      this.loading = false;
    }
  }

  notifyError(msg) {
    const message = `error.code.${msg}`;
    this.notifyService.errorSnackBar({
      buttonText: 'X',
      duration: 4000,
      text: message,
      horizontalPosition: 'end',
      verticalPosition: 'top'
    });
  }

  updateTimezoneCompanySession() {
    if (this.companySession.id === this.companyDetail.id) {
      this.companySession.timezone = this.company.controls.timezone?.value || undefined;

      this.companySessionState.dispatch(ChangeCompany(this.companySession));
    }
    let index = this.companies.findIndex((c) => c.id === this.companyDetail.id);

    if (index >= 0) {
      let newCompany: CompanyPreview = {
        ...this.companies[index],
        timezone: this.company.controls.timezone?.value || undefined
      };
      this.companies[index] = newCompany;
      this.sessionCompaniesState.dispatch(SessionCompanies(this.companies));
    }
  }

  async updateProfile(event, profile: string) {
    let request = {
      idCompany: this.companyId,
      profileCode: profile
    };
    try {
      event ? await this.companyService.setCompanyProfile(request) : await this.companyService.deleteCompanyProfile(request);
    } catch (e) {
    } finally {
    }
  }

  associationDateAdjusts() {
    if (this.company.controls.associationDate.value) {
      let dateGMT = new Date(this.company.controls.associationDate.value);
      return dateGMT;
    }
    return undefined;
  }

  numericOnly(event): boolean {
    let patt = /^[0-9_.-]*$/;
    let result = patt.test(event.key);
    return result;
  }

  numericAndLetters(event): boolean {
    let patt = /^[a-zA-Z0-9_.-]*$/;
    let result = patt.test(event.key);
    return result;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
