import { EventEmitter, Injectable } from '@angular/core';
import { PermissionsEnum } from '../enums/permissions.enum';
import { EllosApiService } from '../http/ellosApi.service';
import { EllosApi } from '../interfaces/ellosApi.interface';
import EllosRequest from '../interfaces/ellosRequest.interface';
import { IPermissions } from '../interfaces/permissions.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  public modulesName = 'modules';

  public listModules = [];

  permissionschange: EventEmitter<any> = new EventEmitter();

  constructor(private readonly ellosApi: EllosApiService) {}

  public emitPermissionschangeEvent() {
    this.permissionschange.emit(this.listModules);
  }

  public getPermissionschangeEmitter() {
    return this.permissionschange;
  }

  storageLoaded$ = new BehaviorSubject<boolean>(false);

  public async setPermissionStorage(value: any) {
    const valueFilter = JSON.parse(localStorage.getItem(this.modulesName));

    if (valueFilter != null && valueFilter?.length) {
      localStorage.removeItem(this.modulesName);
    }
    value = JSON.stringify(value);
    localStorage.setItem(this.modulesName, value);
    this.storageLoaded$.next(true);
    this.getPermissionStorage();
  }

  // buscas as informações de permissão no localstorage
  public getPermissionStorage() {
    const value = JSON.parse(localStorage.getItem(this.modulesName));

    if (value != null) {
      this.listModules = [];
      value.forEach((element) => {
        this.listModules.push(element.moduleId);
      });
    } else {
      this.listModules = [
        PermissionsEnum.AcessarPerfilHome,
        PermissionsEnum.AlterarIdiomaHome,
        PermissionsEnum.AcessarSettingsHome,
        PermissionsEnum.Settings,
        PermissionsEnum.VisualizarConvites,
        PermissionsEnum.SolicitarNovaEmpresa,
        PermissionsEnum.EditarInformacoes,
        PermissionsEnum.DeletarConta,
        PermissionsEnum.AceitarRejeitarConvites,
        PermissionsEnum.VisualizarDetallhesConvite,
        PermissionsEnum.VisualizarEmpresas
      ];
    }

    this.emitPermissionschangeEvent();
    return this.listModules;
  }

  // busca as informações no banco de dados
  public async getPermissions(dataPermissions: IPermissions) {
    const request: EllosRequest = {
      ellosApi: EllosApi.Profile,
      body: dataPermissions,
      resource: `Profile/LoadCompanyModules`
    };

    try {
      const response = await this.ellosApi.post(request);
      return response.data;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }

  public validatePermission(permission) {
    this.getPermissionStorage();
    return this.listModules.includes(permission) ? true : false;
  }

  public async setProfileDefault(companyId: number) {
    const request: EllosRequest = {
      ellosApi: EllosApi.Profile,
      body: companyId,
      resource: `Profile/SearchProfile`
    };

    try {
      const response = await this.ellosApi.post(request);
      return response.data;
    } catch (exception) {
      throw this.ellosApi.getErrorMessage(exception.error);
    }
  }
}
