<h1>{{ 'settings.companies.companies' | translate }}</h1>
<div fxLayout="row wrap" class="search-area" fxLayoutAlign="space-between start">
  <app-search-companies fxFlex="80"></app-search-companies>
  <div *blockCompanyProfile="['EMB']">
    <button *ngIf="modules.includes(modulePermission.SolicitarNovaEmpresa)" mat-flat-button color="accent" class="button-new-company" routerLink="/Settings/Companies/Request">
      {{ ('settings.companies.request-new-company' | translate).toUpperCase() }}
    </button>
  </div>
</div>
<div *ngIf="modules.includes(modulePermission.VisualizarEmpresas)" class="table">
  <table mat-table [dataSource]="dataSource" class="example-table">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Id</th>
      <td mat-cell *matCellDef="let element" class="padding-right">CO-{{ element.id }}</td>
    </ng-container>

    <ng-container matColumnDef="companyName">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'settings.companies.company-name' | translate }}
      </th>
      <td mat-cell matTooltip="{{ element.companyName }}" *matCellDef="let element" class="padding-right">
        {{ element.companyName | truncate : 30 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="myRole">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'settings.companies.my-role' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" class="padding-right">
        {{ 'settings.companies.' + element.role.replace(' ', '-').toLowerCase() | translate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'settings.companies.country' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" class="padding-right">
        {{ element.countryName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'settings.companies.status' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" class="padding-right">
        <div class="status mat-elevation-z4 {{ element.status.toUpperCase() }}" fxLayout="row" fxLayoutAlign="center center">
          {{ 'settings.companies.' + element.status.toLowerCase() | translate }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'settings.companies.actions' | translate }}
      </th>

      <td mat-cell *matCellDef="let element">
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px" *ngIf="element.status.toUpperCase() !== 'INVITATION'; else invitation">
          <button mat-icon-button [routerLink]="'/Settings/Companies/Detail/' + element.id" [matTooltip]="'settings.companies.details' | translate">
            <mat-icon> visibility </mat-icon>
          </button>
          <button
            mat-icon-button
            [matTooltip]="'settings.companies.add-member' | translate"
            *ngIf="element.status.toUpperCase() !== 'INACTIVE' && element.role.toUpperCase() === 'ADMIN' && modules.includes(modulePermission.AdicionarNovoUsuarioEmpresa)"
            (click)="inviteUser(element.id)"
          >
            <mat-icon> person_add_alt_1 </mat-icon>
          </button>
          <button
            mat-icon-button
            [matTooltip]="'settings.companies.edit-company' | translate"
            *ngIf="element.status.toUpperCase() !== 'INACTIVE' && element.role.toUpperCase() === 'ADMIN' && modules.includes(modulePermission.EditarEmpresa)"
            [routerLink]="'/Settings/Companies/Edit/' + element.id"
          >
            <mat-icon> create </mat-icon>
          </button>

          <button
            *ngIf="modules.includes(modulePermission.DeixarEmpresa)"
            mat-icon-button
            [matTooltip]="'settings.companies.leave-company' | translate"
            (click)="leaveCompanyDialog(element)"
          >
            <fa-icon class="icone" [icon]="faSignOutAlt"></fa-icon>
          </button>
        </div>

        <ng-template #invitation>
          <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
            <button
              mat-icon-button
              [routerLink]="'/Settings/Companies/Invitation/' + element.id + '/' + element.companyName"
              [matTooltip]="'settings.companies.details' | translate"
            >
              <mat-icon> visibility </mat-icon>
            </button>
            <button mat-icon-button color="accent" [matTooltip]="'settings.companies.accept-invitation' | translate">
              <mat-icon>check_circle</mat-icon>
            </button>
            <button mat-icon-button color="warn" [matTooltip]="'settings.companies.decline-invitation' | translate">
              <mat-icon>cancel</mat-icon>
            </button>
          </div>
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator #paginator [length]="count" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" (page)="pageChange($event)"> </mat-paginator>
</div>
