import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import { QueryCompanyMembersAction } from '../../actions/query-company-member.action';
import { QueryCompanyMember } from '../../models/query-company-member';

@Component({
  selector: 'app-search-company-member',
  templateUrl: './search-company-member.component.html',
  styleUrls: ['./search-company-member.component.scss']
})
export class SearchCompanyMemberComponent implements OnInit {
  @ViewChild('searchInput') searchInput: ElementRef;
  @Input() idCompany: number;
  profile: string;
  destroy$ = new Subject<any>();
  pageIndex: number = 0;
  pageSize: number = 10;
  userType = new FormControl();

  rangeDate = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  constructor(private readonly queryCompanyMembersState: Store<QueryCompanyMember>) {}

  ngOnInit(): void {
    this.sendNewCompanyMembersQuery();
  }

  ngAfterViewInit() {
    this.inputFilter();
  }

  profileFilter(profile) {
    this.profile = profile.value;
    this.sendNewCompanyMembersQuery();
  }
  inputFilter() {
    fromEvent(this.searchInput?.nativeElement, 'keyup')
      .pipe(
        debounceTime(800),
        switchMap(async () => this.sendNewCompanyMembersQuery()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
  async sendNewCompanyMembersQuery() {
    let query: QueryCompanyMember = {
      idCompany: this.idCompany,
      role: this.userType.value || null,
      endJoinDate:
        this.rangeDate.controls.end.value == null
          ? undefined
          : this.rangeDate.controls.end.value.toLocaleDateString('en') + ' ' + this.rangeDate.controls.end.value.toLocaleTimeString('pt-BR'),
      initJoinDate: this.rangeDate.controls.start.value == null ? undefined : this.rangeDate.controls.start.value.toLocaleDateString('en'),
      nameOrEmail: this.searchInput?.nativeElement.value || null,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize
    };

    this.queryCompanyMembersState.dispatch(QueryCompanyMembersAction(query));
  }

  filterDate() {
    if ((this.rangeDate.controls.start.value && this.rangeDate.controls.end.value) || (this.rangeDate.controls.start.value == null && this.rangeDate.controls.end.value == null)) {
      if (this.rangeDate.controls.end.value !== null) {
        let date = this.rangeDate.controls.end.value;
        date.setHours(this.rangeDate.controls.end.value.getHours() + 23, 59);
        this.rangeDate.controls.end.setValue(date);
      }
      this.sendNewCompanyMembersQuery();
    }
  }

  resetCalendar() {
    this.rangeDate.controls.start.setValue(undefined);
    this.rangeDate.controls.end.setValue(undefined);
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.queryCompanyMembersState.dispatch(QueryCompanyMembersAction(undefined));
  }
}
