import { CurrencyPipe } from './../../../../shared/pipes/ellos-currency.pipe';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Language from 'src/app/core/interfaces/language.interface';
import { UnitMeasureService } from 'src/app/modules/marketplace/services/unit-measure.service';
import { Country } from 'src/app/shared/models/country';
import { Transshipment } from 'src/app/shared/models/transshipment.interface';
import { CountryService } from 'src/app/shared/services/country.service';
import { NcmService } from 'src/app/shared/services/ncm.service';
import { CurrencyEnum, IncotermNumberEnum, TransshipmentMethodEnum } from '../../enums/new-trade.enum';
import { CertificateData } from '../../Models/certificate-data.interface';
import { ProductExportation } from '../../Models/product-exportation.interface';
import { OriginCertificateService } from '../../services/origin-certificate.service';
import { PortService } from '../../services/port.service';
import { allCountries } from 'src/app/core/models/all-countries';

@Component({
  selector: 'app-origin-certificate-detail',
  templateUrl: './origin-certificate-detail.component.html',
  styleUrls: ['./origin-certificate-detail.component.scss']
})
export class OriginCertificateDetailComponent implements OnInit {
  certificateData: CertificateData;
  certificateStopPoints: Transshipment[] = [];
  countries: Country[] = [];
  originCountry: Country;
  destinationCountry: Country;
  idCertificate: number;
  productsForGrid: ProductExportation[] = [];

  destroy$ = new Subject();
  language$: Observable<Language>;
  currentLanguage: string;
  tabIndex: number = 0;
  displayedColumns = ['ncmCode', 'quantity', 'meassure', 'grossWeight', 'marksAndNumber', 'sif', 'manufactoringDate', 'expirationDate', 'description'];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly route: Router,
    private readonly oringinCertificateService: OriginCertificateService,
    private readonly countryService: CountryService,
    private readonly portService: PortService,
    private readonly ncmService: NcmService,
    private readonly unitMeasureService: UnitMeasureService,
    private readonly langStore: Store<{ language: Language }>,
    private currencyPipe: CurrencyPipe
  ) {
    this.language$ = langStore.pipe(select('language'));

    let param = this.activatedRoute.snapshot.paramMap.get('id');
    if (param != null && param.trim()?.length > 0) {
      this.idCertificate = parseInt(param);
    }
  }

  async ngOnInit() {
    try {
      this.language$.pipe(takeUntil(this.destroy$)).subscribe(async (lang: Language) => {
        if (lang) {
          this.currentLanguage = lang.code;
        }
      });
      this.countries = allCountries;
      await this.getCertificateDetail(this.idCertificate);
    } catch (e) {
      this.backOCList();
    }
  }

  async getCertificateDetail(idCertificate) {
    if (!idCertificate) return false;

    try {
      let data = await this.oringinCertificateService.getOriginCertificate(this.idCertificate);

      if (data == null) {
        this.backOCList();
      } else {
        this.certificateData = data;

        if (this.certificateData.originCountry != null) {
          let c = this.countries.find((s) => s.alpha3Code?.trim().toLocaleLowerCase() == this.certificateData.originCountry?.trim().toLocaleLowerCase());
          if (c != null) this.originCountry = { ...c };
        }

        if (this.certificateData.destinationCountry != null) {
          let c = this.countries.find((s) => s.alpha3Code?.trim().toLocaleLowerCase() == this.certificateData.destinationCountry?.trim().toLocaleLowerCase());
          if (c != null) this.destinationCountry = { ...c };
        }

        let portType: TransshipmentMethodEnum = this.getShipmentMethod();

        let flow: Transshipment[] = [];
        let deparPort = await this.portService.getGenericPortById(data.shipmentInformation?.portOfDeparture, portType);
        flow.push(deparPort);

        if (data.shipmentInformation?.transshipments != null && data.shipmentInformation?.transshipments.length > 0) {
          data.shipmentInformation.transshipments
            .sort((s) => s.ordenation)
            .forEach(async (element) => {
              let port = await this.portService.getGenericPortById(element.portId, portType);
              flow.push(port);
            });
        }

        let destPort = await this.portService.getGenericPortById(data.shipmentInformation?.portOfDestination, portType);
        flow.push(destPort);

        this.certificateStopPoints = flow;

        if (data.products != null && data.products.length > 0) {
          let units = await this.unitMeasureService.getUnitsMeasure(this.currentLanguage);

          await data.products.forEach(async (e) => {
            let ncm = await this.ncmService.getNCMByCode(e.ncm);

            let foo: ProductExportation = {
              id: e.id,
              ncm: {
                id: ncm[0]?.id,
                ncmCode: ncm[0]?.ncm,
                description: ncm[0]?.description
              },
              expirationDate: e.expirationDate,
              description: e.description,
              grossWeight: e.grossWeight,
              manufactoringDate: e.productionDate,
              netWeight: e.netWeight,
              quantity: e.quantity,
              marksAndNumbers: e.marks?.toString(),
              sif: e.sif,
              unitMeasure: units.find((s) => s.id == e.idUnitMeasure),
              unitMeasureWeight: units.find((s) => s.id == e.idUnitMeasureWeight),
              productValue: e.productValue,
              currency: e.currency
            };

            this.productsForGrid.push(foo);
            this.productsForGrid = [...this.productsForGrid];
          });
        }
      }
    } catch (error) {
      this.backOCList();
    }
  }

  getIncotermEnum(p) {
    return IncotermNumberEnum[p];
  }

  getCurrencyEnum(p) {
    return this.currencyPipe.transform(CurrencyEnum[p]);
  }

  backOCList() {
    this.route.navigateByUrl('/EasyTrade/Certificates/OriginCertificate');
  }

  getShipmentMethod() {
    if (this.certificateData != null) {
      return this.certificateData?.shipmentInformation?.shipmentType != 1 ? TransshipmentMethodEnum.ship : TransshipmentMethodEnum.plane;
    }
    return null;
  }
}
