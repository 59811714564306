import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CompanyPreview } from 'src/app/core/interfaces/company-preview.interface';
import { CompanyService } from 'src/app/core/services/company.service';
import { Member } from '../../models/member.interface';

@Component({
  selector: 'app-edit-member-role-dialog',
  templateUrl: './edit-member-role-dialog.component.html',
  styleUrls: ['./edit-member-role-dialog.component.scss']
})
export class EditMemberRoleDialogComponent implements OnInit {
  memberEdit = new FormControl('', [Validators.required]);
  forwardingCompany = new FormControl('');
  waitingResponse: boolean = false;
  companies: CompanyPreview[] = [];

  constructor(
    private readonly dialogRef: MatDialogRef<EditMemberRoleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { idCompany: number; member: Member },
    private readonly companyService: CompanyService,
    private readonly translateService: TranslateService
  ) {
    let role = data.member?.roleName.toLowerCase() === 'forwarding agent' ? 'ForwardingAgent' : data.member?.roleName.toLowerCase();
    this.memberEdit.setValue(role);

    if (this.memberEdit.value == 'ForwardingAgent') {
      this.searchForward();
    }
    this.forwardingCompany.setValue(data.member?.forwardingCompanyName);
  }

  ngOnInit(): void {}

  async updateMemberRole() {
    let member = {
      idCompany: this.data.idCompany,
      newRole: this.memberEdit.value,
      idAssociate: this.data.member.idAssociate,
      idForwardingCompany: +this.forwardingCompany.value
    };
    try {
      /**
       * Esta atribuição de 'this.waitingResponse = true;' parece estar desnecessária.
       * Mantendo apenas uma ocorrência para evitar redundância no código. Portanto, removê-la resolveu o problema do loader duplo.
       */
      // this.waitingResponse = true;
      let response = await this.companyService.updateMemberRole(member);
      if (response.success) {
        this.data.member.roleName = this.memberEdit.value;

        let index = this.companies.findIndex((c) => c.id == +this.forwardingCompany.value);

        if (index >= 0) {
          this.data.member.forwardingCompanyName = this.companies[index].name;
        }
        this.dialogRef.close(this.data.member);
      } else {
        let msg: string = this.translateService.instant('settings.companies.update-role-error-message');
      }
    } catch (e) {
    } finally {
      this.waitingResponse = false;
    }
  }

  async searchForward() {
    if (this.memberEdit.value !== 'ForwardingAgent') {
      this.forwardingCompany = new FormControl('');
    } else {
      try {
        // this.waitingResponse = true;
        this.companies = undefined;
        this.companies = await this.companyService.searchForwarding(this.data.member.email);
      } catch (e) {
        this.companies = [];
      } finally {
        this.waitingResponse = false;
      }
    }
  }
}
