<div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="30px" fxLayoutAlign.lt-md="start start" fxLayout.lt-md="column">
  <div class="company-register" fxFlex.lt-md="100">
    <form [formGroup]="informationsBrCountry" *ngIf="!loadingCompanies">
      <!-- PRIMEIRA ETAPA -->
      <div *ngIf="firstStep">
        <div>
          <p>{{ 'registration.search-company-country' | translate }}</p>
          <br />
        </div>
        <div>
          <div>
            <mat-select-country
              class="w100"
              fxFlex="100"
              [countries]="Country"
              appearance="outline"
              [placeHolder]="'registration.company-country' | translate"
              [label]="'registration.company-country' | translate"
              [formControl]="companyCountry"
              #companyCountrySelector;
              (onCountrySelected)="countryCompanySelected($event)"
              required="true"
            >
            </mat-select-country>
          </div>
          <div>
            <mat-form-field fxFlexFill appearance="outline" *ngIf="companyCountry?.value?.alpha2Code == 'BR'">
              <mat-label>CNPJ</mat-label>
              <input matInput autocomplete="off" (keyup)="onKeyCNPJ($event)" [formControl]="cnpj" [dropSpecialCharacters]="false" required mask="00.000.000/0000-00" />
            </mat-form-field>
            <span class="responseCase" *ngIf="responseErrorCase == 1">
              <p>{{ 'registration.revenue-error-invalid' | translate }}</p>
            </span>
            <span class="responseCase" *ngIf="responseErrorCase == 2">
              <p>{{ 'registration.revenue-error-not-found' | translate }}</p>
            </span>
            <span class="responseCase" *ngIf="responseErrorCase == 3">
              <p>{{ 'registration.revenue-error-invalid-status' | translate }}</p>
            </span>
            <span class="responseCase" *ngIf="responseErrorCase == 4">
              <p>{{ 'error.code.ELL-0038' | translate }}</p>
            </span>
          </div>

          <div *ngIf="!waitingCountry">
            <div *ngIf="!isNewForwardingAgent" fxLayout="row">
              <label>{{ 'settings.companies.event.request-services-provide' | translate }}</label>
            </div>
            <br />
            <div fxLayout="row" fxLayoutAlign="center center">
              <button
                *ngIf="!isNewForwardingAgent"
                id="exporter"
                [ngClass]="!_exporterSelect ? 'mat-stroked-button botao_check' : 'mat-raised-button botao_uncheck'"
                (click)="exporterSelect()"
              >
                {{ ('settings.companies.exporter' | translate).toUpperCase() }}
              </button>
              <button id="forwarding" [ngClass]="!_forwardingSelect ? 'mat-stroked-button botao_check' : 'mat-raised-button botao_uncheck'" (click)="forwardingSelect()">
                {{ ('settings.companies.forwarding-agent' | translate).toUpperCase() }}
              </button>
            </div>
          </div>
        </div>

        <br />
        <button
          fxFlexFill
          mat-raised-button
          color="accent"
          fxLayout="row"
          fxLayoutAlign="center center"
          [disabled]="waitingCountry || !cnpjRevenueValid || (!_importerSelect && !_exporterSelect && !_forwardingSelect)"
          (click)="nextOfFirstStep()"
        >
          <span>{{ 'registration.continue' | translate }}</span>
        </button>
      </div>

      <!-- ETAPA DE SELECIONAR A EMPRESA -->
      <div *ngIf="firstStep == false && showStepSelectCompany">
        <p>
          {{ 'registration.your-email' | translate }} <b>{{ _email }}</b>
          {{ 'registration.releated-companies' | translate }}
        </p>

        <div class="email-company-check">
          <mat-slide-toggle [formControl]="exporter" #exp>
            {{ 'settings.companies.exporter' | translate }}
          </mat-slide-toggle>
          <mat-slide-toggle [formControl]="importer" #imp>{{ 'settings.companies.importer' | translate }} </mat-slide-toggle>
          <mat-slide-toggle [formControl]="forwardingAgent" #fwa>{{ 'settings.companies.forwarding-agent' | translate }} </mat-slide-toggle>
        </div>
        <mat-form-field fxFlexFill appearance="outline">
          <mat-label>{{ 'registration.select-a-company' | translate }}</mat-label>
          <mat-select [formControl]="companyList" required>
            <mat-option [value]="company.id" *ngFor="let company of companyAlreadyFound" class="option-company">
              <mat-list-item class="list-item">
                <div mat-line class="company-name">
                  {{ company.companyName }}
                </div>
                <div mat-line class="company-details">{{ company.countryName }} - {{ company?.cnpj }}</div>
              </mat-list-item>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <p>
          {{ 'registration.can-not-find-company' | translate }}
          <a mat-button color="accent" (click)="setNoCompanyFound()">{{ 'registration.click-to-register' | translate }}</a>
        </p>
      </div>

      <!-- ETAPA DE ENDEREÇO INICIAL  -->
      <div fxLayout="column" *ngIf="showFirstStepAdress && !showStepSelectCompany">
        <form [formGroup]="informationsBrCountry">
          <p>
            {{ 'registration.complete-and-check-information' | translate }}
          </p>
          <mat-error *ngIf="errorCountryValue()">
            {{ 'registration.country-error' | translate }}
          </mat-error>
          <mat-error *ngIf="errorEmail">
            {{ 'registration.email-error' | translate }}
          </mat-error>

          <mat-form-field fxFlexFill appearance="outline">
            <mat-label>{{ 'registration.corporate-name' | translate }}</mat-label>
            <input matInput autocomplete="off" formControlName="corporateName" required />
          </mat-form-field>

          <div *ngIf="isBRCountry">
            <mat-form-field color="accent" appearance="outline">
              <mat-label>{{ 'settings.companies.fantasy-name' | translate }}</mat-label>
              <input matInput type="text" required formControlName="fantasyName" />
            </mat-form-field>
          </div>

          <div fxflex="50" fxFill fxLayoutGap="15px" fxLayoutAlign="start start">
            <mat-form-field fxFlexFill appearance="outline" fxFlex="50">
              <mat-label>{{ 'registration.corporate-email' | translate }}</mat-label>
              <input matInput autocomplete="off" formControlName="emailForm" placeholder="example@example.com" required />
            </mat-form-field>
            <mat-form-field fxFlexFill appearance="outline" fxFlex="50">
              <mat-label>{{ 'registration.corporate-phone' | translate }}</mat-label>
              <ngx-mat-intl-tel-input class="input-phone" [onlyCountries]="['br']" [enableSearch]="false" formControlName="phoneForm" required> </ngx-mat-intl-tel-input>
            </mat-form-field>
          </div>

          <div *ngIf="verificationCountry">
            <span class="country-danger">{{ 'registration.span-Company' | translate }}</span>
          </div>

          <div fxLayout="row" fxFill fxLayoutGap="15px" fxLayoutAlign="start start">
            <mat-form-field fxFlexFill appearance="outline" fxFlex="50">
              <mat-label>{{ 'registration.business-sector' | translate }}</mat-label>
              <mat-select [formControl]="businessSector" required>
                <mat-option></mat-option>
                <mat-option [value]="sector.id" *ngFor="let sector of sectors">{{ sector.name }}</mat-option>
              </mat-select>
            </mat-form-field>

            <app-timezone
              [required]="true"
              (timezone)="informationsBrCountry.controls.timezone.setValue($event)"
              fxFlex="50"
              [companyTimezone]="informationsBrCountry?.controls?.timezone?.value"
            ></app-timezone>
          </div>
          <button fxFlexFill mat-raised-button color="accent" fxLayout="row" fxLayoutAlign="center center" [disabled]="disableStepOne()" (click)="continueToSecondStepAdress()">
            <span>{{ 'registration.continue' | translate }}</span>
          </button>
        </form>
      </div>
    </form>
    <div>
      <!-- SEGUNDA ETAPA DE ENDEÇO -->
      <div
        id="informationsBrCountry"
        fxFlex="100"
        *ngIf="isBRCountry && (_exporterSelect || _importerSelect || _forwardingSelect) && showSecondStepAdress == true && showFirstStepAdress == false"
      >
        <form [formGroup]="informationsBrCountry" fxLayout="column" fxLayoutAlign="start start" autocomplete="off">
          <p>
            {{ 'registration.confirm-information' | translate }}
          </p>
          <mat-form-field color="accent" appearance="outline" fxFlex="50">
            <mat-label>{{ 'easy-trade.new-trade.dialog.address' | translate }}</mat-label>
            <input matInput type="text" required formControlName="street" />
            <mat-error *ngIf="informationsBrCountry.controls.street.hasError('whitespace')">
              {{ 'shared.white-space-validator' | translate }}
            </mat-error>
          </mat-form-field>

          <div fxLayout="row" fxFill fxLayoutGap="15px" fxLayoutAlign="start start">
            <mat-form-field fxFlexFill appearance="outline" *ngIf="companyCountry?.value?.alpha2Code == 'BR'">
              <mat-label>{{ 'registration.state' | translate }}</mat-label>
              <mat-select [formControl]="state" required>
                <mat-option value="AC">Acre</mat-option>
                <mat-option value="AL">Alagoas</mat-option>
                <mat-option value="AP">Amapá</mat-option>
                <mat-option value="AM">Amazonas</mat-option>
                <mat-option value="BA">Bahia</mat-option>
                <mat-option value="CE">Ceará</mat-option>
                <mat-option value="DF">Distrito Federal</mat-option>
                <mat-option value="ES">Espírito Santo</mat-option>
                <mat-option value="GO">Goiás</mat-option>
                <mat-option value="MA">Maranhão</mat-option>
                <mat-option value="MT">Mato Grosso</mat-option>
                <mat-option value="MS">Mato Grosso do Sul</mat-option>
                <mat-option value="MG">Minas Gerais</mat-option>
                <mat-option value="PA">Pará</mat-option>
                <mat-option value="PB">Paraíba</mat-option>
                <mat-option value="PR">Paraná</mat-option>
                <mat-option value="PE">Pernambuco</mat-option>
                <mat-option value="PI">Piauí</mat-option>
                <mat-option value="RJ">Rio de Janeiro</mat-option>
                <mat-option value="RN">Rio Grande do Norte</mat-option>
                <mat-option value="RS">Rio Grande do Sul</mat-option>
                <mat-option value="RO">Rondônia</mat-option>
                <mat-option value="RR">Roraima</mat-option>
                <mat-option value="SC">Santa Catarina</mat-option>
                <mat-option value="SP">São Paulo</mat-option>
                <mat-option value="SE">Sergipe</mat-option>
                <mat-option value="TO">Tocantins</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxFill fxLayoutGap="15px" fxLayoutAlign="start start">
            <mat-form-field color="accent" appearance="outline" fxFlex="50">
              <mat-label>{{ 'easy-trade.new-trade.dialog.city' | translate }}</mat-label>
              <input matInput type="text" required formControlName="city" />
              <mat-error *ngIf="informationsBrCountry.controls.city.hasError('whitespace')">
                {{ 'shared.white-space-validator' | translate }}
              </mat-error>
            </mat-form-field>

            <mat-form-field color="accent" appearance="outline" fxFlex="50">
              <mat-label>
                <mat-label>{{ 'easy-trade.new-trade.dialog.district' | translate }}</mat-label>
              </mat-label>
              <input matInput type="text" required formControlName="district" />
              <mat-error *ngIf="informationsBrCountry.controls.district.hasError('whitespace')">
                {{ 'shared.white-space-validator' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxFill fxLayoutGap="15px" fxLayoutAlign="start start" class="custom">
            <mat-form-field color="accent" appearance="outline">
              <mat-label>
                <mat-label>{{ 'settings.companies.number' | translate }}</mat-label>
              </mat-label>
              <input matInput type="text" required formControlName="number" OnlyNumber="true" />
              <mat-error *ngIf="informationsBrCountry.controls.number.hasError('whitespace')">
                {{ 'shared.white-space-validator' | translate }}
              </mat-error>
            </mat-form-field>

            <mat-form-field color="accent" appearance="outline">
              <mat-label>
                {{ 'settings.companies.zip-code' | translate }}
              </mat-label>
              <input matInput type="text" required formControlName="zipcode" OnlyNumber="true" mask="00000-000" />
              <mat-error *ngIf="informationsBrCountry.controls.zipcode.hasError('whitespace')">
                {{ 'shared.white-space-validator' | translate }}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="isBRCountry" color="accent" appearance="outline">
              <mat-label>
                {{ 'settings.companies.state-registration' | translate }}
              </mat-label>
              <input matInput type="text" required formControlName="stateRegistration" />
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>

    <!-- FINALIZAR -->
    <div *ngIf="!firstStep && isFinish" fxLayout="row">
      <button [disabled]="disableFinishSelectCompanyCountry()" fxFlexFill mat-raised-button color="accent" (click)="registerCompany()" fxLayout="row" fxLayoutAlign="center center">
        <span>{{ 'registration.finish-button' | translate }}</span>
      </button>
    </div>
  </div>
</div>
