import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProfileEdit } from '../../models/profile-edit';
import { SessionUser } from 'src/app/shared/models/session-user';
import { PermissionsEnum } from 'src/app/core/enums/permissions.enum';
import { AssociateService } from 'src/app/shared/services/associate.service';
import { SessionUserAction } from 'src/app/shared/actions/session-user-action';
import { PermissionsService } from 'src/app/core/services/permissions.service';
import { DeleteAccountDialogComponent } from '../../components/delete-account-dialog/delete-account-dialog.component';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  public modules = [];
  public modulePermission = PermissionsEnum;

  edit: boolean = false;

  destroy$ = new Subject();
  valid: boolean;
  user: ProfileEdit;
  saving = false;
  saveEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly dialog: MatDialog,

    private readonly permissions: PermissionsService,
    private associateService: AssociateService,
    private readonly sessionUserStore: Store<{ sessionUser: SessionUser }>
  ) {}

  ngOnInit(): void {
    this.modules = this.permissions.getPermissionStorage();
  }

  deleteAccount(): void {
    const dialogRef = this.dialog.open(DeleteAccountDialogComponent, {
      disableClose: true,
      data: this.user
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result) {
        }
      });
  }

  setUser(event) {
    this.user = event;
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }

  async save() {
    let dateGMT = this.hourAdjusts();

    const editeUser: ProfileEdit = {
      ...this.user
    };
    this.saving = true;
    this.saveEvent.next(true);

    try {
      let success = await this.associateService.updateProfile(editeUser);

      if (success) {
        this.sessionUserStore.dispatch(
          SessionUserAction({
            email: this.user.email,
            name: this.user.firstName
          })
        );
      }
    } catch (e) {
    } finally {
      this.saving = false;
      this.edit = false;
    }
  }
  cancel() {
    this.edit = !this.edit;
    this.saveEvent.next(false);
  }
  hourAdjusts() {
    return undefined;
  }
}
