import { Component, ElementRef, EventEmitter, NgZone, OnInit, OnDestroy, Output, ViewChild, HostListener } from '@angular/core';
import { Store, select } from '@ngrx/store';
import Language from '@ccab/components-lib/interfaces/language.interface';
import { Observable, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.scss']
})
export class RecaptchaComponent implements OnInit, OnDestroy {
  @ViewChild('divRecaptcha') divRecaptcha!: ElementRef<HTMLDivElement>;

  widgetId: number | null = null;
  language$: Observable<Language>;
  destroy$ = new Subject<void>();
  @Output() valueGrecaptcha = new EventEmitter<string>();

  private inactivityTimeout = 20 * 60 * 1000; // 20 minutes in milliseconds
  private inactivityTimer: any;

  constructor(private ngZone: NgZone, private langStore: Store<{ language: Language }>) {
    this.language$ = langStore.pipe(select('language'));
  }

  ngOnInit(): void {
    this.startInactivityTimer();

    this.language$.pipe(takeUntil(this.destroy$)).subscribe((lang: Language) => {
      this.renderizarReCaptcha(lang);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.clearInactivityTimer();
  }

  @HostListener('window:click', ['$event'])
  @HostListener('window:keypress', ['$event'])
  resetInactivityTimer(): void {
    this.startInactivityTimer();
  }

  renderizarReCaptcha(lang?: Language) {
    this.destruirReCaptcha();

    this.ngZone.runOutsideAngular(() => {
      if (!window['grecaptcha'] || !this.divRecaptcha) {
        setTimeout(() => {
          this.renderizarReCaptcha(lang);
        }, 500);
        return;
      }

      const idElemento = this.divRecaptcha.nativeElement.getAttribute('id');

      this.widgetId = window['grecaptcha'].render(idElemento, {
        sitekey: environment.reCaptchaSiteKey,
        theme: 'dark',
        hl: lang?.code ?? 'pt',
        size: 'normal',
        callback: (response: string) => {
          this.valueGrecaptcha.emit(response);
          this.ngZone.run(() => {});
        }
      });
    });
  }

  destruirReCaptcha() {
    if (this.widgetId != null) {
      window['grecaptcha'].reset(this.widgetId);
      const element = this.divRecaptcha;

      if (element && element.nativeElement) {
        const parent = element.nativeElement.parentElement;
        parent.removeChild(element.nativeElement);

        const newDiv = document.createElement('div');
        newDiv.setAttribute('id', 'recaptcha');
        parent.appendChild(newDiv);

        this.divRecaptcha = new ElementRef<HTMLDivElement>(newDiv);
      }

      this.widgetId = null;
    }
  }

  private startInactivityTimer() {
    this.clearInactivityTimer();

    this.inactivityTimer = timer(this.inactivityTimeout).subscribe(() => {
      this.language$.pipe(takeUntil(this.destroy$)).subscribe((lang: Language) => {
        this.renderizarReCaptcha(lang);
      });
    });
  }

  private clearInactivityTimer() {
    if (this.inactivityTimer) {
      this.inactivityTimer.unsubscribe();
      this.inactivityTimer = null;
    }
  }
}
