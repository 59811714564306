import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentsLibModule } from '@ccab/components-lib';
import { SharedModule } from 'src/app/shared/shared.module';
import { SettingsModule } from '../settings/settings.module';
import { AuthRoutingModule } from './auth-routing.module';
import { FormDeactivateGuard } from './form-deactivate.guard';
import { ConfirmAccountComponent } from './pages/confirm-account/confirm-account.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoginComponent } from './pages/login/login.component';
import { CreateAccountCompanyComponent } from './pages/create-account-company/create-account-company.component';
import { RecaptchaComponent } from '../authenticity/components/recaptcha/recaptcha.component';
@NgModule({
  declarations: [LoginComponent, CreateAccountCompanyComponent, ForgotPasswordComponent, ConfirmAccountComponent, RecaptchaComponent],
  imports: [CommonModule, AuthRoutingModule, SharedModule, ComponentsLibModule, ReactiveFormsModule, SettingsModule]
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [FormDeactivateGuard]
    };
  }
}
