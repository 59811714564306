// @ts-nocheck

import { Timezone } from 'src/app/shared/models/timezone';
import { noWhitespaceValidator } from 'src/app/shared/validators/not-white-space.validator';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatInput } from '@angular/material/input';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Language from 'src/app/core/interfaces/language.interface';
import { AuthService } from 'src/app/core/services/auth.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { PermissionsService } from 'src/app/core/services/permissions.service';
import { CompanyJoin } from 'src/app/modules/auth/models/company-join';
import { RegisterCompanyContato } from '../../models/register-company';
import { Sector } from '../../models/sector';
import { SessionUser } from '../../models/session-user';
import { CrmService } from '../../services/crm-service.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CnpjRevenueErrorEnum } from 'src/app/core/enums/cnpj-revenue-error.enum';
import { Country } from '../../models/country';
import { allCountries } from 'src/app/core/models/all-countries';

@Component({
  selector: 'app-register-company',
  templateUrl: './register-company.component.html',
  styleUrls: ['./register-company.component.scss']
})
export class RegisterCompanyComponent implements OnInit {
  informationsBrCountry: FormGroup = this.fb.group({
    timezone: [null as Timezone, [Validators.required]],
    street: ['', [Validators.required]],
    city: ['', [Validators.required]],
    state: [''],
    number: ['', [Validators.required]],
    zipcode: ['', [Validators.required]],
    stateRegistration: ['', [Validators.required]],
    district: ['', [Validators.required]],
    corporateName: ['', Validators.required],
    fantasyName: ['', Validators.required],
    emailForm: [('', [Validators.required, Validators.email])],
    complement: [''],
    phoneForm: ['', [Validators.required]]
  });

  email: string;
  @Input() checkboxEmail: boolean = false;
  @Output() selectedCompany = new EventEmitter<CompanyJoin>();
  @Output() companyFound = new EventEmitter<boolean>();
  @Output() finished = new EventEmitter<boolean>();
  @Output() finishedInvite = new EventEmitter<boolean>();
  @Output() isAdmin = new EventEmitter<boolean>();
  @Output() isInviteCompany = new EventEmitter<boolean>();
  @Output() isRequested: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isRequestCompany = new EventEmitter<any>();
  @Input() cnpjDefault: string;
  @Input() emailDefault: string;
  @Input() isNewForwardingAgent = false;
  @Input() checkStep: boolean = false;
  isImporter: boolean = false;
  isExporter: boolean = false;
  isFowarding: boolean = false;
  isBRCountry: boolean = false;
  showAddress: boolean = false;
  showAddressBr: boolean = false;
  _exporterSelect: boolean = false;
  _importerSelect: boolean = false;
  _forwardingSelect: boolean = false;
  verificationCountry: boolean = false;
  showFirstStepAdress: boolean = false;
  isFinish: boolean = false;
  showSecondStepAdress: boolean = false;
  showStepSelectCompany: boolean = false;

  companyList = new FormControl('');
  companyCountry = new FormControl('', Validators.required);
  errorEmail = false;
  businessSector = new FormControl('', Validators.required);
  forwardingAgent = new FormControl(false);
  importer = new FormControl(false);
  exporter = new FormControl(false);
  cnpj = new FormControl('', noWhitespaceValidator);
  state = new FormControl('', noWhitespaceValidator);
  cnpjInvalid: boolean = false;
  cnpjRevenueValid: boolean = true;
  companyAlreadyFound: CompanyJoin[] = [];
  companyByCnpj: any;
  responseErrorCase: number = -1;
  _email: string;
  existingCnpj: string;
  timeZoneCountry: any;
  companyJoin: CompanyJoin;
  waitingLogin: boolean = false;
  waitingCountry: boolean = true;
  firstStep: boolean = true;
  searchCompany: boolean = false;
  userRegistered: boolean = false;
  loadingCompanies: boolean = false;
  integrated: boolean = false;
  existingCompany: boolean = false;
  companyByCnpjReceita: any = null;
  Country: Country[] = allCountries.filter((country) => country.alpha2Code == 'BR');
  @Output() company = new EventEmitter<any>();
  @Output() createUser = new EventEmitter<any>();
  @Output() requestInvite = new EventEmitter<boolean>();
  @Output() cnpjInvite = new EventEmitter<any>();
  @Output() emailInvite = new EventEmitter<any>();
  @Output() existingCompanyRequest = new EventEmitter<boolean>();
  @Output() searchedCompany = new EventEmitter<boolean>();
  _companyFound: boolean = false;
  _finished: boolean = false;
  _isAdmin: boolean = false;
  _requestCompany: boolean = false;
  countryError = false;
  companyCountryValue: Country = null as Country;
  @ViewChild('checkEmail', { static: true }) checkEmail: MatCheckbox;
  @ViewChild('inputEmailCompany', { static: true }) inputEmailCompany: MatInput;

  destroy$ = new Subject<any>();
  language$: Observable<Language>;
  sessionUser$: Observable<SessionUser>;
  sectors: Sector[] = [];
  language: string;
  enableButton = false;
  firstLoad: boolean = true;
  stateRegistration: any;

  constructor(
    private readonly fb: FormBuilder,
    private readonly companyService: CompanyService,
    private readonly permissionService: PermissionsService,
    private readonly sessionUserStore: Store<{ sessionUser: SessionUser }>,
    private readonly langStore: Store<{ language: Language }>,
    private readonly crmService: CrmService,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly dialog: MatDialog
  ) {
    this.sessionUser$ = this.sessionUserStore.pipe(select('sessionUser'));
    this.language$ = langStore.pipe(select('language'));
    this.companyCountry.markAllAsTouched();
  }

  ngOnInit(): void {
    if (this.validator) {
      this.informationsBrCountry.controls.emailForm.reset();
      this.informationsBrCountry.controls.phoneForm.reset();
      this.informationsBrCountry.controls.corporateName.setValue(null);
      this.informationsBrCountry.controls.corporateName.clearValidators();
      this.informationsBrCountry.controls.corporateName.updateValueAndValidity();

      this.informationsBrCountry.controls.fantasyName.setValue(null);
      this.informationsBrCountry.controls.fantasyName.clearValidators();
      this.informationsBrCountry.controls.fantasyName.updateValueAndValidity();

      this.informationsBrCountry.controls.phoneForm.clearValidators();
      this.informationsBrCountry.controls.emailForm.clearValidators();

      this.businessSector.setValue(null);
      this.businessSector.clearValidators();
      this.businessSector.updateValueAndValidity();
    }
    this.informationsBrCountry.controls.emailForm.setValue(this.email);
    this.companyFound.emit(false);
    this._companyFound = false;
    this.isAdmin.emit(false);
    this.finished.emit(false);
    this.finishedInvite.emit(false);
    this.existingCompanyRequest.emit(false);
    this._finished = false;

    this.sessionUser$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user) {
        this.informationsBrCountry.controls.emailForm.setValue(user?.email);
        this._email = user?.email;
        this.getCompaniesByToken();
      } else {
        this.authService.setSessionUser();
      }
    });

    this.language$.pipe(takeUntil(this.destroy$)).subscribe(async (lang: Language) => {
      if (lang) {
        this.language = lang.code;
        this.getSectors();
      }
    });
  }

  exporterSelect() {
    this._exporterSelect = !this._exporterSelect;
  }

  importerSelect() {
    this._importerSelect = !this._importerSelect;
  }

  forwardingSelect() {
    this._forwardingSelect = !this._forwardingSelect;
  }

  onFocusOutEvent(event, name) {
    if (name == 'businessSector') {
      this.businessSector.setValidators([Validators.required]);
      this.businessSector.updateValueAndValidity();
    } else {
      this.informationsBrCountry.controls.corporateName.setValidators([Validators.required]);
      this.informationsBrCountry.controls.corporateName.updateValueAndValidity();
    }

    this.habilitarBotao();
  }

  focusIn(event) {
    this.habilitarBotao();
  }

  isCountryEvent(event) {
    if (event) {
      this.informationsBrCountry = this.fb.group({
        timezone: [null as Timezone, [Validators.required]],
        street: ['', [Validators.required]],
        city: ['', [Validators.required]],
        state: [''],
        number: ['', [Validators.required]],
        zipcode: ['', [Validators.required]],
        stateRegistration: ['', [Validators.required]],
        district: ['', [Validators.required]],
        complement: [''],
        corporateName: ['', Validators.required],
        fantasyName: ['', Validators.required],
        emailForm: [('', [Validators.required, Validators.email])],
        phoneForm: ['', [Validators.required]]
      });
      this.informationsBrCountry.markAllAsTouched();
    }
  }

  habilitarBotao() {
    if (this.businessSector.value != null && this.informationsBrCountry.controls.corporateName.value != null && this.companyCountry.valid) {
      return true;
    }
  }

  async getSectors() {
    if (this.language) {
      let lang;
      switch (this.language) {
        case 'en':
          lang = 'English';
          break;
        case 'pt':
          lang = 'Portuguese';
          break;
        default:
          break;
      }
      try {
        this.sectors = await this.crmService.getSectors(lang);
      } catch {
        this.sectors = [];
      }
    }
  }

  setFalse(checkEmail) {
    this.searchCompany = false;
    this.searchedCompany.emit(false);
    this.companyFound.emit(false);
    this._companyFound = false;
  }

  companyChange(event) {
    this.habilitarBotao();
  }
  async countryCompanySelected(event) {
    this.waitingCountry = true;
    if (event.alpha2Code !== 'BR') {
      this.cnpj = new FormControl('', Validators.required);
      this.state = new FormControl('', Validators.required);
      this.waitingCountry = false;
      this.cnpjRevenueValid = true;
      this.responseErrorCase = -1;
      this.isBRCountry = false;
      this.isCountryEvent(this.isBRCountry);
      this.state.markAllAsTouched();
      this.cnpj.markAllAsTouched();
      if (!this.selectCompanyCountry) {
        this.informationsBrCountry.controls.corporateName = new FormControl('', Validators.required);
      }
    } else {
      this.isBRCountry = true;
      this.isCountryEvent(this.isBRCountry);
    }
    this.verificationCountry = false;

    this.companyCountry.setValue(event);

    this.habilitarBotao();
    if (event.target?.value == '') {
      this.verificationCountry = true;
    }
    if (this.isNewForwardingAgent) {
      this.cnpj.setValue(this.cnpjDefault);
      this._forwardingSelect = true;
      await this.onKeyCNPJ(this.cnpjDefault);
    }
  }
  setNoCompanyFound() {
    this.searchedCompany.emit(false);
    this.companyFound.emit(false);
    this._companyFound = false;
    this.firstStep = false;
    this.showStepSelectCompany = false;
    this.showFirstStepAdress = true;
  }

  async getCompaniesByToken() {
    if (this.informationsBrCountry.controls.emailForm.valid) {
      this.loadingCompanies = true;
      try {
        if (!this.validator) {
          this.companyAlreadyFound = this.companyService.getCrmCompaniesByToken();
        }
        this.searchCompany = true;
        this.resetFields();
        this.searchedCompany.emit(true);
        this.companyAlreadyFound.length > 0 ? this.companyFound.emit(true) : this.companyFound.emit(false);
        this.companyAlreadyFound.length > 0 ? (this._companyFound = true) : (this._companyFound = false);

        this.loadingCompanies = false;
      } catch (e) {
        this.loadingCompanies = false;
        this.companyFound.emit(false);
        this.searchCompany = true;
        this._companyFound = false;
        this.searchedCompany.emit(true);
      }
    } else {
      this.informationsBrCountry.controls.emailForm.markAllAsTouched();
    }
  }

  resetFields() {
    this.companyList = new FormControl('', Validators.required);
    this.companyCountry = new FormControl('', Validators.required);
    this.informationsBrCountry.controls.corporateName = new FormControl('', Validators.required);
    this.informationsBrCountry.controls.phoneForm = new FormControl('', Validators.required);
    this.informationsBrCountry.controls.emailForm = new FormControl('', [Validators.required, Validators.email]);
    this.companyCountry = new FormControl('', Validators.required);
    this.businessSector = new FormControl('', Validators.required);
    this.cnpj = new FormControl('');
    this.state = new FormControl('', Validators.required);
    this.informationsBrCountry.controls.emailForm.markAllAsTouched();
    this.companyList.markAllAsTouched();
    this.companyCountry.markAllAsTouched();
    this.informationsBrCountry.controls.corporateName.markAllAsTouched();
    this.businessSector.markAllAsTouched();
    this.informationsBrCountry.controls.phoneForm.markAllAsTouched();
    this.state.markAllAsTouched();
    this.companyCountry.markAllAsTouched();
  }

  errorCountryValue() {
    let companyCountryValue = this.companyCountry.value as Country;
    let countryCode = companyCountryValue?.numericCode;
    if (countryCode == null && this.companyCountry.value) {
      this.companyCountry.markAsPending();
      return true;
    } else {
      this.companyCountry.setValue(this.companyCountry.value);
      return false;
    }
  }
  async registerCompany() {
    try {
      var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if (!reg.test(this.informationsBrCountry.controls.emailForm.value)) {
        this.errorEmail = true;
        return;
      }
      let newCompany: RegisterCompanyContato = {
        companyName: this.informationsBrCountry.controls.corporateName.value,
        contactEmail: this.informationsBrCountry.controls.emailForm.value,
        companyPhone: this.informationsBrCountry.controls.phoneForm.value,
        countryNumericCode: this.companyCountry.value.numericCode,
        idSector: this.businessSector.value,
        cnpj: this.cnpj.value,
        state: this.state.value,
        isForwardingAgent: this._forwardingSelect,
        isExporter: this._exporterSelect,
        isImporter: this._importerSelect,
        addressDetails: this.informationsBrCountry.valid ? this.informationsBrCountry.valid.value : null
      };
      if (this.informationsBrCountry.valid) {
        const adressDetails = {
          city: this.informationsBrCountry.controls.city.value,
          street: this.informationsBrCountry.controls.street.value,
          zipCode: this.informationsBrCountry.controls.zipcode.value,
          stateRegistration: this.informationsBrCountry.controls.stateRegistration.value,
          district: this.informationsBrCountry.controls.district.value,
          number: this.informationsBrCountry.controls.number.value,
          state: this.informationsBrCountry.controls.state.value,
          complement: this.informationsBrCountry.controls.complement.value
        };

        const otherDetails = {
          fantasyName: this.informationsBrCountry.controls.fantasyName.value,
          idTimezone: this.informationsBrCountry.controls.timezone.value.id,
          stateRegistration: this.informationsBrCountry.controls.stateRegistration.value
        };
        newCompany.addressDetails = adressDetails;
        newCompany.fantasyName = otherDetails.fantasyName;
        newCompany.timezoneId = otherDetails.idTimezone;
      }
      if (this.informationsBrCountry.controls.phoneForm.valid) {
        let response = await this.companyService.registerCompany(newCompany);
        if (response) {
          this.permissionService.setProfileDefault(response);
          this.companyJoin = {
            companyName: newCompany.companyName,
            cnpj: newCompany.cnpj
          };
          this.company.emit(newCompany);
          this.selectedCompany.emit(this.companyJoin);
          this.isAdmin.emit(true);
          this._isAdmin = true;
          this.integrated = false;
          this.finished.emit(true);
          this._finished = true;
        }
      }
    } catch (e) {
      console.error(e);
      if (e?.error?.errors?.length > 0 && e?.error?.errors[0] == 'ELL-0038') {
        await setTimeout(async () => {
          await this.authService.logout();
          await this.router.navigateByUrl('/Login');
        }, 4000);
      }
    }
  }

  async onKeyCNPJ(event) {
    try {
      this.existingCompany = false;
      this.cnpjInvalid = false;
      this.cnpjRevenueValid = false;
      this.responseErrorCase = -1;

      let companyByCnpj: Company[] = [];
      if (event?.target?.value?.length === 18 || this.isNewForwardingAgent) {
        const parameter = this.isNewForwardingAgent ? this.cnpjDefault : event.target.value;
        try {
          companyByCnpj = await this.companyService.getEllosCompanyByCnpj(parameter);
          if (companyByCnpj?.id != null) {
            this.responseErrorCase = CnpjRevenueErrorEnum.existingCnpj;
            return;
          }
        } catch (e) {
          if (e?.error?.errors?.length >= 0 && e?.error?.errors[0] == 'ELL-0006') {
            this.existingCompany = false;
          }
        }

        this.companyByCnpjReceita = await this.companyService.getCompanyByCnpjSearch(parameter);
        this.waitingCountry = false;
        if (this.companyByCnpjReceita?.message) {
          this.cnpjRevenueValid = false;
          if (this.companyByCnpjReceita?.message.includes('inválido')) this.responseErrorCase = CnpjRevenueErrorEnum.cnpjInvalid;
          else if (this.companyByCnpjReceita?.message.includes('Not Found')) this.responseErrorCase = CnpjRevenueErrorEnum.cnpjNotFound;
        } else if (this.companyByCnpjReceita?.status.toLocaleLowerCase() != 'ativa') {
          this.cnpjRevenueValid = false;
          this.responseErrorCase = CnpjRevenueErrorEnum.cnpjNotActive;
        } else {
          if (this.responseErrorCase !== CnpjRevenueErrorEnum.existingCnpj) {
            this.cnpjRevenueValid = true;
            this.responseErrorCase = -1;
          }
        }
      }

      if (this.informationsBrCountry?.controls?.timezone?.value != null) {
        this.timeZoneCountry = this.informationsBrCountry.controls.timezone.value;
      }
      if (this.companyByCnpjReceita?.cnpj) {
        this.informationsBrCountry = this.fb.group({
          timezone: [null as Timezone, [Validators.required]],
          fantasyName: [''],
          corporateName: ['', Validators.required],
          street: ['', [Validators.required]],
          city: ['', [Validators.required]],
          state: [''],
          number: ['', [Validators.required]],
          zipcode: ['', [Validators.required]],
          district: ['', [Validators.required]],
          stateRegistration: [Validators.required],
          emailForm: ['', [Validators.required, Validators.email]],
          complement: [],
          phoneForm: ['', [Validators.required]]
        });
        this.informationsBrCountry.markAllAsTouched();
        this.informationsBrCountry.controls.corporateName.setValue(this.companyByCnpjReceita.name);
        this.informationsBrCountry.controls.corporateName.disable();
        this.informationsBrCountry.controls.fantasyName.setValue(this.companyByCnpjReceita.fantasyName);
        this.informationsBrCountry.controls.street.setValue(this.companyByCnpjReceita.street);
        this.informationsBrCountry.controls.city.setValue(this.companyByCnpjReceita.city);
        this.informationsBrCountry.controls.state.setValue(this.companyByCnpjReceita.state);
        this.informationsBrCountry.controls.number.setValue(this.companyByCnpjReceita.number);
        this.informationsBrCountry.controls.zipcode.setValue(this.companyByCnpjReceita.zipCode);
        this.informationsBrCountry.controls.district.setValue(this.companyByCnpjReceita.district);
        this.informationsBrCountry.controls.complement.setValue(this.companyByCnpjReceita.complement);
        this.informationsBrCountry.controls.stateRegistration.setValue(this.companyByCnpjReceita.stateRegistration);

        this.state.setValue(this.companyByCnpjReceita.state);
      } else if (this.companyByCnpjReceita && !this.companyByCnpjReceita?.cnpj && !this.companyByCnpjReceita.message) {
        this.informationsBrCountry.markAllAsTouched();
        this.existingCompanyRequest.emit(true);
        this.requestInvite.emit(false);
        const cnpj = this.isNewForwardingAgent ? this.cnpjDefault : event?.target?.value;
        this.cnpjInvite.emit(cnpj);
        this.emailInvite.emit(this._email);
        this.isRequestCompany.emit(true);
        this.isRequested.emit(true);
      } else if (!this.companyByCnpjReceita && !this.companyByCnpjReceita?.cnpj && !this.companyByCnpjReceita.message) {
        this.informationsBrCountry.markAllAsTouched();
        this.cnpjInvalid = true;
        this.informationsBrCountry.controls.fantasyName.setValue(null);
        this.informationsBrCountry.controls.street.setValue(null);
        this.informationsBrCountry.controls.city.setValue(null);
        this.informationsBrCountry.controls.state.setValue(null);
        this.informationsBrCountry.controls.number.setValue(null);
        this.informationsBrCountry.controls.zipcode.setValue(null);
        this.informationsBrCountry.controls.district.setValue(null);
        this.informationsBrCountry.controls.complement.setValue(null);
        this.informationsBrCountry.controls.stateRegistration.setValue(null);
        this.informationsBrCountry.controls.timezone.setValue(null);
        this.informationsBrCountry.updateValueAndValidity();
        this.informationsBrCountry.controls.corporateName.setValue(null);
        this.state.setValue(null);
      } else {
        this.informationsBrCountry.reset();
      }
    } catch (e) {
      if (e?.error?.errors?.length >= 0 && e?.error?.errors[0] == 'ELL-0006') {
        this.existingCompany = false;
      }
    }
  }

  disableFinishNotselectCompanyCountry(): boolean {
    return (
      !this.informationsBrCountry.valid ||
      !this.searchCompany ||
      !this.companyList.valid ||
      !this.companyCountry.valid ||
      !this.businessSector.valid ||
      !(this.cnpj.valid && this.state.valid) ||
      this.errorCountryValue() ||
      !(this._importerSelect || this._exporterSelect || this._forwardingSelect)
    );
  }

  disableFinishSelectCompanyCountry(): boolean {
    return !this.informationsBrCountry.valid || !this.searchCompany || !(this._importerSelect || this._exporterSelect || this._forwardingSelect);
  }

  disableFinishSearchCompany(): boolean {
    if (this.isNewForwardingAgent) {
      let companyCountryValue = this.companyCountry.value as Country;
      return (
        (companyCountryValue.alpha2Code !== 'BR' && !this.addressDetails.valid) ||
        (companyCountryValue.alpha2Code === 'BR' && !this.informationsBrCountry.valid) ||
        !(this.informationsBrCountry.controls.phoneForm.valid && this.informationsBrCountry.controls.emailForm.valid) ||
        !this.searchCompany ||
        !this.companyCountry.valid ||
        !this.businessSector.valid ||
        !(this._importerSelect || this._exporterSelect || this._forwardingSelect)
      );
    } else {
      let companyCountryValue = this.companyCountry.value as Country;
      return (
        (companyCountryValue.alpha2Code !== 'BR' && !this.addressDetails.valid) ||
        (companyCountryValue.alpha2Code === 'BR' && !this.informationsBrCountry.valid) ||
        !(this.informationsBrCountry.controls.phoneForm.valid && this.informationsBrCountry.controls.emailForm.valid) ||
        !this.searchCompany ||
        !this.companyCountry.valid ||
        !this.businessSector.valid ||
        !(companyCountryValue.alpha2Code == 'BR' ? this.cnpj.valid && this.state.valid : true) ||
        this.errorCountryValue() ||
        !(this._importerSelect || this._exporterSelect || this._forwardingSelect)
      );
    }
  }

  async finish(e) {
    this.getCompaniesByToken();
    this.registerCompany();
    if (this.validator) {
      await this.router.navigateByUrl('/Home');
    } else {
      await this.router.navigateByUrl('/Settings/MyInvitations/Recieved');
    }
  }

  async linkUserToCompany() {
    let companyId = this.companyList.value;
    let indexCompany = this.companyAlreadyFound.findIndex((c) => c.id === companyId);
    if (indexCompany >= 0) {
      let membership = {
        idCompanyCrm: companyId,
        isForwardingAgent: this.forwardingAgent.value,
        cnpj: this.companyAlreadyFound[indexCompany].cnpj
      };
      try {
        let response;
        if (this._companyFoundCnpjInCRM) {
          this._companyFoundCnpjInCRM = false;
          response = await this.companyService.linkUserToCompanyCnpj(membership);
        } else {
          response = await this.companyService.linkUserToCompany(membership);
        }
        if (response) {
          this.company.emit(this.companyAlreadyFound[indexCompany]);
          this.companyJoin = this.companyAlreadyFound[indexCompany];
          this.selectedCompany.emit(this.companyJoin);
          this.isAdmin.emit(!this.companyJoin.hasAdmin);
          this._isAdmin = !this.companyJoin.hasAdmin;
          this.integrated = this.companyJoin.ellosIntegrated;
          this.finished.emit(true);
          this._finished = true;
        } else {
          this.finished.emit(false);
        }
      } catch (e) {
        this.finished.emit(false);
      }
    }
  }

  disableStepOne() {
    this.companyCountryValue = this.companyCountry.value as Country;
    let valido: boolean =
      this.informationsBrCountry.controls.corporateName?.value &&
      this.informationsBrCountry.controls.corporateName?.value.length > 0 &&
      this.informationsBrCountry.controls.corporateName.getRawValue() != '' &&
      this.informationsBrCountry.controls.phoneForm.valid &&
      this.informationsBrCountry.controls.emailForm.valid &&
      this.businessSector.valid &&
      this.informationsBrCountry.controls.timezone.valid;

    if (this.companyCountryValue.alpha2Code == 'BR') valido = valido;

    return !valido;
  }

  continueToSecondStepAdress() {
    this.showFirstStepAdress = false;
    this.showSecondStepAdress = true;
    this.isFinish = true;
    let companyCountryValue = this.companyCountry.value as Country;
    if (companyCountryValue.alpha2Code == 'BR') {
      this.showAddressBr = true;
      this.showAddress = false;
    } else {
      this.showAddress = true;
      this.showAddressBr = false;
    }
  }

  async nextOfFirstStep() {
    this.firstStep = false;
    let companyCountryValue = this.companyCountry.value as Country;
    let cnpj = this.cnpj.value;
    this.waitingCountry = true;
    try {
      if (companyCountryValue.alpha2Code == 'BR') {
        let response = this.companyService.searchForCompanyByCNPJCrm(cnpj);
        if (this.isNewForwardingAgent) {
          this.searchCompany = true;
          this.informationsBrCountry.controls.emailForm.setValue(this.emailDefault);
        }
        this.stepOne = true;
        this.companyAlreadyFound = response;
        if (this.companyAlreadyFound.length > 0) {
          this.showStepSelectCompany = true;
          this._companyFoundCnpjInCRM = true;
        } else {
          this.showFirstStepAdress = true;
          this._companyFoundCnpjInCRM = false;
          this.showStepSelectCompany = false;
        }
      }
      this.selectCompanyCountry = false;
    } catch (error) {
      console.error(error);
      this.firstStep = true;
      this.selectCompanyCountry = false;
    }
  }

  numericAndLetters(event): boolean {
    let patt = /^[a-zA-Z0-9_.-]*$/;
    let result = patt.test(event.key);
    return result;
  }

  getCompanyCountryValueAlpha2Code(): string {
    let companyCountryValue = this.companyCountry.value as Country;
    return companyCountryValue?.alpha2Code;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
