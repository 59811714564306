import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ResultDialogComponent } from '@ccab/components-lib';
import { FormControl, Validators } from '@angular/forms';
import { InviteService } from '../../services/invite.service';
import { validarCNPJ } from 'src/app/shared/utils/validate-cnpj';
import { getEnviroment } from 'src/app/shared/utils/get-enviroment';
import { InviteRequest } from '../../models/invite-request.interface';
import { CompanyService } from 'src/app/core/services/company.service';
import { AssociateService } from 'src/app/shared/services/associate.service';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { CompanyPreview } from 'src/app/core/interfaces/company-preview.interface';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResultDialogData } from 'src/app/shared/components/result-dialog/result-dialog-data.interface';

@Component({
  selector: 'app-invite-user-dialog',
  templateUrl: './invite-user-dialog.component.html',
  styleUrls: ['./invite-user-dialog.component.scss']
})
export class InviteUserDialogComponent implements OnInit {
  forwardingCompanyId = new FormControl(null as number);
  email = new FormControl('', [Validators.required, Validators.email]);
  role = new FormControl('', [Validators.required]);
  cnpj = new FormControl(null);
  @ViewChild('inputEmail') inputEmail: ElementRef;
  destroy$ = new Subject<any>();
  emailFound: boolean = false;
  searching: boolean = false;
  companies: CompanyPreview[];
  loading: boolean = false;
  errCNPJ: string;
  isValidCNPJ: boolean = true;
  companyNotFound: boolean = false;
  searchingForwarding: boolean;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<InviteUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number,
    private readonly associateService: AssociateService,
    private readonly companyService: CompanyService,
    private readonly inviteService: InviteService
  ) {}

  ngOnInit(): void {}

  Email() {
    this.role = new FormControl('', [Validators.required]);
    this.cnpj = new FormControl(null);
    this.errCNPJ = null;
  }

  async searchForward() {
    if (this.role.value !== 'ForwardingAgent') {
      this.forwardingCompanyId = new FormControl(null as number);
      this.cnpj = new FormControl(null);
      this.isValidCNPJ = true;
    } else {
      try {
        this.companyNotFound = false;
        this.companies = undefined;
        this.searchingForwarding = true;
        this.companies = await this.companyService.searchForwarding(this.email.value);
        this.searchingForwarding = false;
      } catch (e) {
        this.companies = [];
      }
    }
  }
  async existsUser() {
    return await this.associateService.existsUser(this.email.value);
  }
  async submit() {
    let environmentToEmail = getEnviroment();
    let newInvite: InviteRequest = {
      email: this.email.value,
      idCompany: this.data,
      idForwardingCompany: isNaN(this.forwardingCompanyId.value) ? null : this.forwardingCompanyId.value,
      role: this.role.value,
      cnpj: this.cnpj.value,
      isNewForwardingAgent: false,
      environment: environmentToEmail
    };

    if (this.cnpj.value) {
      newInvite = { ...newInvite, isNewForwardingAgent: true };
    }
    try {
      this.loading = true;
      let response = await this.inviteService.sendInvite(newInvite);
      const dialogData: ResultDialogData = {
        success: response,
        text: response ? 'settings.companies.successfully-invited' : 'settings.companies.invite-erro',
        timeout: 3000
      };
      const dialogRefReult = this.dialog.open(ResultDialogComponent, {
        data: dialogData,
        disableClose: true
      });
      dialogRefReult
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          response ? this.dialogRef.close(true) : undefined;
        });
      this.loading = false;
    } catch (e) {
      const dialogData: ResultDialogData = {
        success: false,
        text: 'settings.companies.invite-erro',
        timeout: 3000
      };
      const dialogRefReult = this.dialog.open(ResultDialogComponent, {
        data: dialogData,
        disableClose: true
      });

      this.loading = false;
    }
  }

  async verificationCNPJ(cnpj: string) {
    this.errCNPJ = '';
    this.companyNotFound = false;
    if (validarCNPJ(cnpj)) {
      this.isValidCNPJ = true;
      await this.companyService.getCompanyByCnpjSearch(cnpj).then((x) => {
        if (x?.message === 'Not Found') {
          this.companyNotFound = true;
          this.errCNPJ = 'settings.companies.company-not-found-by-cnpj';
        } else {
          this.companyNotFound = false;
        }

        if (x?.name && x?.cnpj) {
          this.errCNPJ = 'settings.companies.cnpj-found';
        }
      });
    } else {
      this.errCNPJ = 'settings.companies.cnpj-invalid';
      this.isValidCNPJ = false;
    }
  }

  disable() {
    return (
      !this.email.valid ||
      !this.role.valid ||
      !this.forwardingCompanyId.valid ||
      this.loading ||
      this.searching ||
      this.searchingForwarding ||
      !this.cnpj.valid ||
      !this.isValidCNPJ ||
      this.companyNotFound
    );
  }
}
