<mat-toolbar class="mat-elevation-z8">
  <mat-toolbar-row>
    <img class="home-button" src="../../../assets/img/ellos-logo-colorful.png" alt="ellos-logo" />

    <span class="example-spacer"></span>

    <app-translate class="spacer" *ngIf="ShowTranslationDropdown || modules.includes(modulePermission.AlterarIdiomaHome)"></app-translate>
    <div class="company-selector spacer">
      <app-company-selector *ngIf="showCompanySelector" tourAnchor="company"></app-company-selector>
    </div>

    <div *ngIf="showTimeZone">
      <div class="timezone spacer" (click)="navigateSettings()" *ngIf="validateTimeZone()" [hidden]="validateHiddenTimeZone()">
        <div class="button-menu" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="2px">
          <span class="menu-item"> <fa-icon [icon]="faGlobeAfrica"></fa-icon>{{ 'toolbar.timezone' | translate }} </span>

          <span class="menu-item"
            >GMT&nbsp;
            <span *ngIf="company?.timezone.offset > 0">+</span>
            {{ company?.timezone.offset }}&nbsp; {{ company?.timezone.name.replace('_', ' ') }}</span
          >
        </div>
      </div>
    </div>

    <ng-container *ngIf="user">
      <app-profile-image class="profile-image"></app-profile-image>

      <button mat-button [matMenuTriggerFor]="menu" tourAnchor="profile">{{ user?.name }}<mat-icon mat-line>arrow_drop_down</mat-icon></button>

      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/Settings" id="my-profile-button"><mat-icon>person</mat-icon>{{ 'toolbar.my-profile' | translate }}</button>
        <button mat-menu-item (click)="changeTheme()" id="change-theme-button" *ngIf="modules.includes(modulePermission.AlterarTemaHome)">
          <mat-icon>wb_sunny</mat-icon>{{ currentThemeName | translate }}
        </button>
        <button mat-menu-item (click)="logout()" id="logout-button"><mat-icon>exit_to_app</mat-icon>{{ 'toolbar.logout' | translate }}</button>
      </mat-menu>
    </ng-container>
  </mat-toolbar-row>
</mat-toolbar>
<ng-template #jsonTemplate let-step="step">
  {{ step | json }}
</ng-template>
