<section class="main-search" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px" fxLayoutAlign.lt-md="start start" fxLayout.lt-md="column" fxLayoutGap.lt-md="20px">
  <div
    class="main-search__fields"
    fxLayoutGap="20px"
    fxLayout.lt-md="row wrap"
    fxFlex.lt-md="100"
    fxFlex="auto"
    fxFlex.lt-md="grow"
    fxLayoutAlign="start start"
    fxLayoutGap.lt-md="0px"
    fxLayout="row"
  >
    <app-chip-search
      (valueChange)="filterByCertificaNumber($event)"
      fxFlex="20"
      fxFlex.lt-md="100"
      [defaultValue]="certificateNumbers"
      [onlynumber]="false"
      [placeholder]="'easy-trade.certificates.filters.origin-certificate-number-separator' | translate"
      [label]="'easy-trade.certificates.filters.certificate-number-label' | translate"
      [required]="false"
    ></app-chip-search>

    <app-chip-search
      (valueChange)="filterByInvoiceNumber($event)"
      fxFlex="20"
      fxFlex.lt-md="100"
      [defaultValue]="invoiceNumbers"
      [onlynumber]="false"
      [placeholder]="'easy-trade.certificates.filters.invoice-number-separator' | translate"
      [label]="'easy-trade.certificates.filters.invoice-number-label' | translate"
      [required]="false"
    ></app-chip-search>

    <mat-form-field fxFlex="20" appearance="outline" (click)="picker.open()" fxFlex.lt-md="100">
      <mat-label>{{ 'easy-trade.monitoring.filters.filter-by-date' | translate }}</mat-label>

      <mat-date-range-input [formGroup]="rangeDate" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="" readonly />
        <input matEndDate formControlName="final" placeholder="" readonly />
      </mat-date-range-input>

      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker (opened)="resetCalendar()" (closed)="filterDate()"></mat-date-range-picker>
    </mat-form-field>

    <button mat-mini-fab class="refresh" fxFlexAlign="start" color="accent" (click)="resendQuery()">
      <mat-icon>refresh</mat-icon>
    </button>

    <button class="button" mat-button *ngIf="hasFilter()" (click)="clearFilter()">
      <mat-icon matPrefix> cleaning_services</mat-icon>
      {{ 'marketplace.search.clean-filter-button' | translate }}
    </button>
  </div>
</section>
