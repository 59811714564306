import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import ConfirmAccount from 'src/app/core/interfaces/confirmAccount.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators';
import { interval, Subject } from 'rxjs';

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.scss']
})
export class ConfirmAccountComponent implements OnInit, OnDestroy {
  accountValidation = this._fb.group({
    nmb1: ['', [Validators.required, Validators.min(0), Validators.max(9)]],
    nmb2: ['', [Validators.required, Validators.min(0), Validators.max(9)]],
    nmb3: ['', [Validators.required, Validators.min(0), Validators.max(9)]],
    nmb4: ['', [Validators.required, Validators.min(0), Validators.max(9)]],
    nmb5: ['', [Validators.required, Validators.min(0), Validators.max(9)]],
    nmb6: ['', [Validators.required, Validators.min(0), Validators.max(9)]]
  });
  count: number = 60;
  msgCodeError: string;
  couter: any;
  paramSub: any;
  @Input() tel;
  @Input() email;
  @Input() floatLabelControl;
  waitingConfirmation: boolean;
  destroy$ = new Subject<any>();
  @Input() redirectLogin: true;
  @Output() Resendcounter = new EventEmitter<any>();
  @Output() registered = new EventEmitter<boolean>();
  constructor(
    private _fb: FormBuilder,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngOnInit(): void {
    if (!this.email) {
      this.paramSub = this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
        this.email = params['email'];
      });
    }

    this.startOfCount();
  }
  codeResendCounter() {
    if (this.count != 0) {
      this.count += -1;
    } else {
      this.couter?.unsubscribe();
    }
  }
  startOfCount() {
    this.couter = interval(1000);
    this.couter.subscribe(() => this.codeResendCounter());
  }
  ResendCounter() {
    this.count = 60;
    this.startOfCount();
    this.Resendcounter.emit();
  }
  async onSubmit() {
    this.waitingConfirmation = true;
    const n1: string = this.accountValidation.controls['nmb1'].value;
    const n2: string = this.accountValidation.controls['nmb2'].value;
    const n3: string = this.accountValidation.controls['nmb3'].value;
    const n4: string = this.accountValidation.controls['nmb4'].value;
    const n5: string = this.accountValidation.controls['nmb5'].value;
    const n6: string = this.accountValidation.controls['nmb6'].value;

    const code = `${n1}${n2}${n3}${n4}${n5}${n6}`;
    const confirm: ConfirmAccount = {
      code: code,
      email: this.email
    };

    try {
      const successMsg: string = this.translateService.instant('registration.associate-registred');
      await this.authService.confirmAccount(confirm);
      this.snackBar.open(successMsg, 'OK', {
        duration: 2000
      });
      if (this.redirectLogin) {
        this.router.navigateByUrl('/Login');
      } else {
        this.registered.emit(true);
      }
    } catch (exception) {
      this.msgCodeError = 'registration.Erro-code';
    }
    this.waitingConfirmation = false;
  }

  onInputEntry(event, nextInputIndex) {
    const input = event.target;

    document.getElementById(nextInputIndex).focus();
  }
}
