<mat-card class="main-card">
  <mat-card-header class="card-header" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <app-profile-image class="profile-image" [saveEvent]="saveEvent" size="100" [edit]="edit"></app-profile-image>
    <mat-card-title class="title">{{ 'settings.profile.about-me' | translate }}</mat-card-title>
    <mat-progress-bar class="progress" mode="determinate" *ngIf="profilePercent" [value]="profilePercent" color="accent"> </mat-progress-bar>
    <p class="profile-percent" *ngIf="profilePercent">
      {{ 'settings.profile.profile' | translate }} {{ profilePercent }}%
      {{ 'settings.profile.complete' | translate }}
    </p>
  </mat-card-header>

  <mat-card-content *ngIf="!saving">
    <form [formGroup]="userEdit">
      <mat-divider></mat-divider>
      <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
        <p>{{ 'registration.firstname' | translate }}:</p>
        <p *ngIf="!edit">{{ user?.firstName }}</p>

        <mat-form-field class="input full-width" appearance="outline" *ngIf="edit">
          <mat-label>{{ 'registration.firstname' | translate }}</mat-label>
          <input matInput formControlName="firstName" appearance="outline" autocomplete="off" />
        </mat-form-field>
      </div>
      <mat-divider></mat-divider>
      <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
        <p>{{ 'registration.lastname' | translate }}:</p>
        <p *ngIf="!edit">{{ user?.lastName }}</p>

        <mat-form-field class="input full-width" appearance="outline" *ngIf="edit">
          <mat-label>{{ 'registration.lastname' | translate }}</mat-label>
          <input matInput formControlName="lastName" appearance="outline" autocomplete="off" />
        </mat-form-field>
      </div>
      <mat-divider></mat-divider>
      <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
        <p>{{ 'registration.job-title' | translate }}:</p>
        <p *ngIf="!edit">{{ user?.jobTitle || '-' }}</p>
        <mat-form-field class="input full-width" appearance="outline" *ngIf="edit">
          <mat-label>{{ 'registration.job-title' | translate }}</mat-label>
          <input matInput formControlName="jobTitle" appearance="outline" autocomplete="off" />
        </mat-form-field>
      </div>
      <mat-divider></mat-divider>
      <div *ngIf="!edit" fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
        <p>{{ 'settings.profile.e-mail' | translate }}:</p>
        <p>{{ user?.email }}</p>
      </div>

      <div *ngIf="edit" fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
        <p>{{ 'settings.profile.e-mail' | translate }}:</p>
        <mat-form-field appearance="outline" class="input full-width">
          <mat-label>{{ 'settings.profile.e-mail' | translate }}:</mat-label>
          <input matInput value="{{ user?.email }}" disabled="true" />
        </mat-form-field>
      </div>

      <mat-divider></mat-divider>
      <div fxLayout="row" class="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start" fxLayoutAlign="start center">
        <p>{{ 'settings.profile.phone-number' | translate }}:</p>
        <p *ngIf="!edit">{{ user?.phone || '-' }}</p>
        <mat-form-field class="input full-width" appearance="outline" *ngIf="edit">
          <mat-label>{{ 'registration.phone' | translate }}</mat-label>
          <ngx-mat-intl-tel-input class="input-phone" formControlName="phone" [enableSearch]="true" required> </ngx-mat-intl-tel-input>
        </mat-form-field>
      </div>
    </form>
  </mat-card-content>
</mat-card>
