import { Injectable } from '@angular/core';
import { stringify } from 'query-string';
import { EllosApiService } from 'src/app/core/http/ellosApi.service';
import { EllosApi } from 'src/app/core/interfaces/ellosApi.interface';
import EllosRequest from 'src/app/core/interfaces/ellosRequest.interface';
import { NcmEntity } from '../models/ncm-entity.interface';
import { Ncm } from '../models/ncm.interface';
import { QueryNcmResult } from '../models/query-ncm-result';
@Injectable({
  providedIn: 'root'
})
export class NcmService {
  constructor(private readonly ellosApi: EllosApiService) {}

  async getNcmPaginated(filter: { LanguageCode: string; NcmCode: string; PageIndex: number; PageSize: number }): Promise<Ncm[]> {
    const query = stringify(filter);
    const request: EllosRequest = {
      ellosApi: EllosApi.Ncm,
      resource: `Ncm?${query}`
    };

    const response = await this.ellosApi.get<QueryNcmResult>(request);
    return response.data.ncms === null ? [] : response.data.ncms;
  }

  async getNCM(ncmCode: string, langCode: string): Promise<Ncm> {
    const query = stringify({ lang: langCode });
    const request: EllosRequest = {
      ellosApi: EllosApi.Ncm,
      resource: `Ncm/id/${ncmCode}?${query}`
    };

    const response = await this.ellosApi.get<Ncm>(request);
    return response.data;
  }

  async getNCMByCode(ncmCode: string): Promise<NcmEntity[]> {
    const request: EllosRequest = {
      ellosApi: EllosApi.Ncm,
      resource: `Ncm/${ncmCode}`
    };

    const response = await this.ellosApi.get<NcmEntity[]>(request);
    return response.data;
  }
}
