<!-- <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px" class="label oc-in-process"
    *ngIf="status === ocStatus.InProcess">                      
    <p>{{ "easy-trade.origin-certificate.table.status-label.in-process" | translate }}</p>
</div>

<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px" class="label oc-wating-payment"
    *ngIf="status === ocStatus.WaitingPayment">                      
    <p>{{ "easy-trade.origin-certificate.table.status-label.waiting-payment" | translate }}</p>
</div> 

<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px" class="label oc-registered"
    *ngIf="status === ocStatus.InProcess">                      
    <p>{{ "easy-trade.origin-certificate.table.status-label.registered" | translate }}</p>
</div> -->

<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px" class="label oc-registered" *ngIf="status === ocStatus.Registered">
  <p>
    {{ 'easy-trade.origin-certificate.table.status-label.registered' | translate }}
  </p>
</div>

<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px" class="label oc-not-validate" *ngIf="status === ocStatus.NotValidate">
  <p>
    {{ 'easy-trade.origin-certificate.table.status-label.not-validated' | translate }}
  </p>
</div>
