import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import Login from 'src/app/core/interfaces/login.interface';
import { AuthService } from 'src/app/core/services/auth.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CognitoError } from './../../../../core/interfaces/cognitoError.interface';
import { EllosSpinnerComponent } from 'src/app/shared/components/ellos-spinner/ellos-spinner.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  extras: any;
  urlCallback: string;
  capslockOn: boolean;
  loginForm: FormGroup;
  waitingLogin: boolean;
  currentLanguage: string;
  isHomolog = environment.homolog;
  private loadingModalRef: MatDialogRef<EllosSpinnerComponent> = null;

  constructor(
    private fb: FormBuilder,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly notifyService: NotificationService,
    private readonly _dialog: MatDialog
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      recaptcha: ['', this.isHomolog ? null : Validators.required]
    });
    this.extras = this.router.getCurrentNavigation().extras;
  }

  ngOnInit(): void {
    if (this.extras && this.extras.state && (!this.extras.state.url || this.extras.state.url.length > 1)) {
      this.urlCallback = this.extras.state.from;
      this.notifyExpiredSession();
    }
    this.currentLanguage = localStorage.getItem('language');
  }

  async onSubmit() {
    this.waitingLogin = true;
    this.loadingModalRef = this._dialog.open(EllosSpinnerComponent, {
      panelClass: 'custom-modalbox',
      disableClose: true
    });

    const login: Login = {
      password: this.loginForm.controls['password'].value,
      email: this.loginForm.value.email.toString().toLowerCase()
    };

    try {
      const loginResult = await this.authService.login(login);
      if (loginResult) {
        this.router.navigateByUrl(this.urlCallback || '/Home');
      }
    } catch (exception) {
      this.loadingModalRef.close();
      console.error(exception);

      try {
        if ((<string>exception).includes(CognitoError.userNotConfirmed)) {
          this.router.navigate(['/Confirm-account', login.email]);
        }
      } catch (e) {
        this.loadingModalRef.close();
        console.error(e);
      }

      this.notifyError(exception.trim());
    } finally {
      this.loadingModalRef.close();
      this.waitingLogin = false;
    }
  }

  ngAfterViewInit() {
    if (this.extras && this.extras.state && (!this.extras.state.url || this.extras.state.url.length > 1)) {
      this.urlCallback = this.extras.state.from;
      this.notifyExpiredSession();
    }
  }

  notifyError(msg) {
    const message = `error.code.${msg}`;

    this.notifyService.errorSnackBar({
      buttonText: 'X',
      duration: 4000,
      text: message,
      horizontalPosition: 'end',
      verticalPosition: 'top'
    });
  }

  notifyExpiredSession() {
    setTimeout(() => {
      this.notifyService.errorSnackBar({
        buttonText: 'X',
        duration: 5000,
        text: 'error.expired-session',
        horizontalPosition: 'end',
        verticalPosition: 'top'
      });
    }, 500);
  }

  capsLock(e) {
    this.capslockOn = e;
  }

  handleValueRecaptcha(value: any) {
    if (value !== null) {
      this.loginForm.controls['recaptcha'].setValue(value);
      this.loginForm.controls['recaptcha'].updateValueAndValidity();
    }
  }

  isFormValid(): boolean {
    return this.loginForm.valid && (!this.isHomolog || (this.loginForm.get('recaptcha').value !== null && this.loginForm.get('recaptcha').valid));
  }
}
