import { ImporterExporterDetailComponent } from './../../../authenticity/components/importer-exporter-detail/importer-exporter-detail.component';
import { Importer } from './../../../easy-trade/Models/Template/Importer.interface';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { select, Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { allCountries } from './../../../../core/models/all-countries';

import { of, Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, takeUntil } from 'rxjs/operators';

import { Country } from 'src/app/shared/models/country';
import { changeError } from 'src/app/core/actions/error.action';
import { ErrorState } from 'src/app/core/interfaces/error-state';

import { RequestAprovalDialogComponent } from 'src/app/shared/components/request-aproval-dialog/request-aproval-dialog.component';
import { DeactivateDialogComponent } from 'src/app/shared/components/deactivate-dialog/deactivate-dialog.component';
import { CompanyImporterEnum } from 'src/app/core/enums/company-importer.enum';
import { InvoiceFormTypeEnum } from './../../../../core/enums/invoice-form-type.enum';
import { CurrencyEnum, IncotermEnum, TransshipmentMethodEnum } from '../../enums/new-trade.enum';

import { PortService } from '../../services/port.service';
import { NcmService } from 'src/app/shared/services/ncm.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { CountryService } from 'src/app/shared/services/country.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { OriginCertificateService } from '../../services/origin-certificate.service';
import { UnitMeasureService } from 'src/app/modules/marketplace/services/unit-measure.service';

import { Invoice } from '../../Models/invoice.interface';
import { Incoterm } from '../../Models/incoterm.interface';
import { InvoiceForm } from '../../Models/invoice-form.interface';
import Language from 'src/app/core/interfaces/language.interface';

import { Template } from '../../Models/Template/Template.interface';
import { convertToIsoDate } from 'src/app/shared/utils/date-converter';
import { CompanyInvolved } from '../../Models/company-involved.interface';
import { CertificateData } from '../../Models/certificate-data.interface';
import { Transshipment } from 'src/app/shared/models/transshipment.interface';
import { ProductExportation } from '../../Models/product-exportation.interface';
import { CompanyPreview } from 'src/app/core/interfaces/company-preview.interface';
import { CompaniesEnvolved } from '../../Models/Template/CompaniesEnvolved.interface';

import { CertificateTrasShipment } from '../../Models/Template/CertificateTrasShipment.interface';
import { CertificateProductRequest } from '../../Models/Template/CertificateProductRequest.interface';
import { CertificateShipmentInformation } from '../../Models/Template/CertificateShipmentInformation.interface';
import { InvoiceFormComponent } from '../../components/invoice-form/invoice-form.component';
import { CompanyTypeEnum } from '../../enums/invoice-form-type.enum';
import { truncate } from 'fs';

@Component({
  selector: 'app-new-origin-certificate',
  templateUrl: './new-origin-certificate.component.html',
  styleUrls: ['./new-origin-certificate.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ]
})
export class NewOriginCertificateComponent implements OnInit {
  @ViewChild(InvoiceFormComponent) invoiceFormComponent: InvoiceFormComponent;

  templateData: Template = {
    detailsRequiriments: {},
    invoiceInformation: {
      incoterm: {
        cost: 0,
        freightCost: 0,
        incotermType: IncotermEnum.CFR,
        insurance: 0,
        landingFee: 0,
        others: 0
      }
    },
    shipmentInformation: {}
  };

  invoiceFormTypeEnum: typeof InvoiceFormTypeEnum = InvoiceFormTypeEnum;

  incoterm: Incoterm;
  buyer: CompanyInvolved;
  exporter: CompanyInvolved;
  importer: CompanyInvolved;
  consignee: CompanyInvolved;
  currentCompany: CompanyPreview;
  destinationCountry: Country;
  reviewStep: boolean;
  canPass: boolean = false;
  destroy$ = new Subject();
  canEditProducts: boolean = true;
  isInvoiceFormValid: boolean = false;
  showImporterSelection: boolean = false;
  showBuyerSelection: boolean = false;
  showConsigneeSelection: boolean = false;
  isOpenCompaniesInvolvedAdvance: boolean = false;

  currentId: number;
  currentLanguage: string;
  countries: Country[] = [];
  language$: Observable<Language>;
  @Input() invoiceFormCurrentValues: InvoiceForm;

  shipmentFormGroup: FormGroup;
  companiesFormGroup: FormGroup;
  certificateData: CertificateData;
  importerType = CompanyImporterEnum;
  transshipmentFlow: Transshipment[] = [];
  productsGrid: ProductExportation[] = [];
  transhipmentType = TransshipmentMethodEnum;
  sessionCompany$: Observable<CompanyPreview>;
  predefinedDestinationCountries: Country[] = [];
  transhipments = Object.keys(TransshipmentMethodEnum)
    .map((o) => TransshipmentMethodEnum[o as any])
    .map((o) => o as TransshipmentMethodEnum);

  predefinedAlpha3CodeDestinationCountries: string[] = [
    'SAU',
    'DZA',
    'BHR',
    'QAT',
    'COM',
    'DJI',
    'EGY',
    'YEM',
    // 'IRQ',
    'JOR',
    'KWT',
    'LBN',
    'LBY',
    'MRT',
    'MAR',
    'OMN',
    'PSE',
    'SOM',
    'SDN',
    'SYR',
    'ARE',
    'TUN'
  ];
  IncotermtypeNumber: string[] = ['CIF', 'CIP', 'CFR', 'CPT', 'DDP', 'DDU', 'DEQ', 'DES', 'FOB', 'EXW', 'FCA', 'FAS', 'DAP', 'DPU'];
  constructor(
    private readonly __formBuilder: FormBuilder,
    private readonly companyService: CompanyService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly oringinCertificateService: OriginCertificateService,
    private readonly companySessionState: Store<{ company: CompanyPreview }>,
    private readonly route: ActivatedRoute,
    private readonly languageService: LanguageService,
    private readonly countryService: CountryService,
    private readonly portService: PortService,
    private readonly ncmService: NcmService,
    private readonly unitMeasureService: UnitMeasureService,
    private readonly dialog: MatDialog,
    private readonly langStore: Store<{ language: Language }>,
    private store: Store<ErrorState>
  ) {
    this.language$ = langStore.pipe(select('language'));
    let param = route.snapshot.paramMap.get('id');
    if (param != null && param.trim().length > 0) {
      this.currentId = parseInt(route.snapshot.paramMap.get('id'));
    }
    this.sessionCompany$ = this.companySessionState.pipe(select('company'));
  }

  async ngOnInit() {
    this.sessionCompany$.pipe(takeUntil(this.destroy$)).subscribe(async (company) => {
      this.currentCompany = company;
      if (company) {
        await this.getExporter();
        this.companyService
          .validateCompany(company.id)
          .pipe(
            catchError((err) => {
              let msg: Array<string> = [];

              msg = err.error.errors.map((e) => {
                if (e.includes('FantasyNameInvalid')) {
                  return 'validation.invalid-fantasy-name';
                }
                if (e.includes('TraderTypeInvalid')) {
                  return 'validation.invalid-trader-type';
                }
                if (e.includes('TimezoneInvalid')) {
                  return 'validation.invalid-timezone';
                }
                if (e.includes('CnpjInvalid')) {
                  return 'validation.invalid-cnpj';
                }
                if (e.includes('StateRegistryInvalid')) {
                  return 'validation.invalid-state-registry';
                }
                if (e.includes('SegmentInvalid')) {
                  return 'validation.invalid-segment';
                }
                if (e.includes('PrimaryChapterInvalid')) {
                  return 'validation.invalid-primary-chapter';
                }
                if (e.includes('ZipCodeInvalid')) {
                  return 'validation.invalid-zipCode';
                }
                if (e.includes('StreetInvalid')) {
                  return 'validation.invalid-street';
                }
                if (e.includes('CityInvalid')) {
                  return 'validation.invalid-city';
                }
                if (e.includes('DistrictInvalid')) {
                  return 'validation.invalid-district';
                }
                if (e.includes('StateInvalid')) {
                  return 'validation.invalid-state';
                }
                if (e.includes('CountryInvalid')) {
                  return 'validation.invalid-country';
                }
                if (e.includes('ContactEmailInvalid')) {
                  return 'validation.invalid-contact-email';
                }
                if (e.includes('PhoneNumberInvalid')) {
                  return 'validation.invalid-phone-number';
                }
                if (e.includes('NameInvalid')) {
                  return 'validation.invalid-name';
                }
                if (e.includes('NumberInvalid')) {
                  return 'validation.invalid-number';
                }
              });
              return of(
                this.store.dispatch(
                  changeError({
                    errorState: {
                      additionalInfoMore: msg
                    }
                  })
                )
              );
            })
          )
          .subscribe();
      }
    });

    this.language$.pipe(takeUntil(this.destroy$)).subscribe(async (lang: Language) => {
      if (lang) {
        this.currentLanguage = lang.code;
      }
    });

    this.initEmptyForms();
    if (this.currentId != null) {
      await this.initFilledForms();
      this.canPass = false;
    }

    this.predefinedAlpha3CodeDestinationCountries.forEach((element) => {
      this.predefinedDestinationCountries.push(allCountries.find((s) => s.alpha3Code === element));
    });
    this.predefinedDestinationCountries = this.predefinedDestinationCountries.sort((a, b) => (a.name > b.name ? 1 : -1));

    if (this.invoiceFormCurrentValues) {
      this.invoiceFormComponent.setDefaultValues();
    }
  }

  get CompanyTypeEnum() {
    return CompanyTypeEnum;
  }

  async leaveComponent() {
    let dialogRef = this.dialog.open(DeactivateDialogComponent);

    let resp = await dialogRef.afterClosed().toPromise();
    if (resp) this.router.navigate(['/Certificates']);
  }

  async initFilledForms() {
    let oc = await this.oringinCertificateService.getOriginCertificate(this.currentId);
    let langs = await this.languageService.getLanguages();

    let foo = {
      invoiceNumber: oc.invoiceNumber,
      emissionDate: oc.emissionDate,
      originCountry: null,
      certificateLanguage: null,
      incoterm: this.IncotermtypeNumber[oc.incotermType],
      cost: oc.cost,
      freight: oc.freightCost,
      insurance: oc.insurance,
      landinf_fee: oc.landingFee?.toString(),
      others: oc.others?.toString(),
      observations: oc.observations,
      certificateEmail: oc.certificateEmail,
      hasCertificateBrazilianOrigin: oc.hasCertificateBrazilianOrigin
    };

    let selectedLang = langs.find((s) => s.name?.trim().toLocaleLowerCase() == oc.certificateLanguage?.trim().toLocaleLowerCase());
    if (selectedLang != null)
      foo.certificateLanguage = {
        id: selectedLang.id,
        name: selectedLang.name
      };

    if (oc.originCountry != null) {
      let c = this.countries.find((s) => s.alpha3Code?.trim().toLocaleLowerCase() == oc.originCountry?.trim().toLocaleLowerCase());
      if (c != null) foo.originCountry = { ...c };
    }

    this.invoiceFormCurrentValues = foo;
    this.setInvoiceInformation(foo);

    this.initCompaniesEnvolvedForm(oc);

    await this.initShipInfoForm(oc);

    await this.initProductsGrid(oc);
  }

  async initProductsGrid(oc: CertificateData) {
    if (oc.products?.length > 0) {
      let productListToTemplate = [];
      let _productsGrid = [];
      const productAsync = oc.products.map(async (element, i) => {
        let ncm = await this.ncmService.getNCMByCode(element.ncm);
        let units = await this.unitMeasureService.getUnitsMeasure(this.currentLanguage);
        _productsGrid.push({
          id: element.id,
          ncm: {
            id: ncm[0]?.id,
            ncmCode: ncm[0]?.ncm,
            description: ncm[0]?.description
          },
          unitMeasure: units.find((s) => s.id == element.idUnitMeasure),
          quantity: element.quantity,
          unitMeasureWeight: units.find((s) => s.id == element.idUnitMeasureWeight),
          grossWeight: element.grossWeight,
          netWeight: element.netWeight,
          manufactoringDate: element.productionDate,
          expirationDate: element.expirationDate,
          description: element.description,
          marksAndNumbers: element.marks?.toString(),
          sif: element.sif,
          productValue: element.productValue,
          currency: element.currency
        });

        productListToTemplate.push({
          id: element.id,
          ncmCode: ncm[0]?.ncm,
          idUnitMeasure: units.find((s) => s.id == element.idUnitMeasure)?.id,
          quantity: element.quantity,
          idUnitMeasureWeight: units.find((s) => s.id == element.idUnitMeasureWeight)?.id,
          grossWeight: element.grossWeight,
          netWeight: element.netWeight,
          manufactoringDate: element.productionDate,
          expirationDate: element.expirationDate,
          description: element.description,
          marksAndNumbers: element.marks?.toString(),
          sif: element.sif?.toString(),
          productValue: element.productValue,
          currency: element.currency
        });

        if (i + 1 == oc.products.length) this.productList(productListToTemplate);
      });
      await Promise.all(productAsync);
      this.productsGrid = _productsGrid;
      this.productsGrid = [...this.productsGrid];
    }
  }

  initCompaniesEnvolvedForm(oc: CertificateData) {
    this.companiesFormGroup = this.__formBuilder.group({
      exporter: [this.currentCompany?.id, Validators.required],
      importer: [oc.companiesEnvolved?.importer],
      consignee: [oc.companiesEnvolved?.consignee],
      buyer: [oc.companiesEnvolved?.buyer]
    });

    if (oc.companiesEnvolved?.consignee != null || oc.companiesEnvolved?.buyer != null) {
      this.isOpenCompaniesInvolvedAdvance = false;
    }
  }

  async initShipInfoForm(oc: CertificateData) {
    let destCountry;
    if (oc.destinationCountry != null) {
      this.destinationCountry = allCountries.find((s) => s.alpha3Code?.trim().toLocaleLowerCase() == oc.destinationCountry?.trim().toLocaleLowerCase());
      if (this.destinationCountry != null) destCountry = { ...this.destinationCountry };
    }

    let portType: TransshipmentMethodEnum = TransshipmentMethodEnum.plane;
    if (oc.shipmentInformation?.shipmentType != 1) portType = TransshipmentMethodEnum.ship;

    this.shipmentFormGroup = this.__formBuilder.group({
      shipmentType: [portType],
      awbNumber: [oc.shipmentInformation?.blawbNumber],
      shippingDate: [oc.shipmentInformation?.shippingDate, [Validators.required]],
      imo_number: [oc.shipmentInformation?.imoNumber],
      vesselName: [oc.shipmentInformation?.vesselName],
      destinationCountry: [destCountry],
      id: oc.shipmentInformation.id,
      acidNumber: [oc?.acidNumber, Validators.maxLength(19)]
    });

    this.shipmentFormGroup.get('shipmentType').valueChanges.subscribe((shipmentType) => {
      if (shipmentType === TransshipmentMethodEnum.plane || shipmentType === TransshipmentMethodEnum.ship) {
        this.shipmentFormGroup.get('vesselName').setValue(null);
      }
    });

    let flow: Transshipment[] = [];
    let deparPort = await this.portService.getGenericPortById(oc.shipmentInformation?.portOfDeparture, portType);
    flow.push(deparPort);

    if (oc.shipmentInformation?.transshipments != null && oc.shipmentInformation?.transshipments.length > 0) {
      oc.shipmentInformation.transshipments
        .sort((s) => s.ordenation)
        .forEach(async (element) => {
          let port = await this.portService.getGenericPortById(element.portId, portType);
          flow.push(port);
        });
    }

    let destPort = await this.portService.getGenericPortById(oc.shipmentInformation?.portOfDestination, portType);
    flow.push(destPort);

    this.transshipmentFlow = flow;
  }

  async getExporter() {
    try {
      this.exporter = await this.companyService.getExportationCompanyDetail(this.currentCompany.id, this.importerType.ellos);
    } catch (e) {}
  }

  initEmptyForms() {
    this.companiesFormGroup = this.__formBuilder.group({
      exporter: [this.currentCompany?.id, Validators.required],
      importer: [null],
      consignee: [null],
      buyer: [null, []]
    });

    this.shipmentFormGroup = this.__formBuilder.group({
      shipmentType: [TransshipmentMethodEnum.plane],
      awbNumber: [null],
      vesselName: [null],
      shippingDate: [null, [Validators.required]],
      imo_number: [null],
      destinationCountry: [null]
    });

    this.transshipmentFlow = [{}, {}];
  }

  keyPressAlphaNumeric(event) {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  async changeStepper(e) {
    if (e.selectedIndex === 5) {
      this.reviewStep = true;
      await this.getCompaniesInvolveds();
    } else {
      this.reviewStep = false;
    }
  }

  async getCompaniesInvolveds() {
    this.importer = await this.companyService.getExportationCompanyDetail(this.companiesFormGroup.value.importer.id, this.companiesFormGroup.value.importer.companyType);
    this.buyer = this.companiesFormGroup.value.buyer?.id
      ? await this.companyService.getExportationCompanyDetail(this.companiesFormGroup.value.buyer.id, this.companiesFormGroup.value.buyer.companyType)
      : this.importer;
    this.consignee = this.companiesFormGroup.value.consignee?.id
      ? await this.companyService.getExportationCompanyDetail(this.companiesFormGroup.value.consignee.id, this.companiesFormGroup.value.consignee.companyType)
      : this.importer;
  }

  setInvoiceInformation(invoice: InvoiceForm) {
    this.templateData.invoiceInformation.invoiceNumber = invoice?.invoiceNumber;
    this.templateData.invoiceInformation.emissionDate = invoice?.emissionDate ? convertToIsoDate(invoice?.emissionDate) : undefined;
    this.templateData.invoiceInformation.originCountry = invoice?.originCountry;
    this.templateData.invoiceInformation.certificateLanguage = invoice?.certificateLanguage;
    this.templateData.invoiceInformation.certificateEmail = invoice?.certificateEmail;
    this.templateData.invoiceInformation.incoterm.incotermType = invoice?.incoterm;
    this.templateData.invoiceInformation.incoterm.cost = invoice?.cost || 0;
    this.templateData.invoiceInformation.incoterm.freightCost = invoice?.freight || 0;
    this.templateData.invoiceInformation.incoterm.insurance = invoice?.insurance || 0;
    this.templateData.invoiceInformation.incoterm.landingFee = Number(invoice?.landinf_fee) || 0;
    this.templateData.invoiceInformation.incoterm.others = Number(invoice?.others) || 0;
    this.templateData.invoiceInformation.observations = invoice?.observations;
    this.templateData.invoiceInformation.declaration = invoice?.hasCertificateBrazilianOrigin || false;
    this.isInvoiceFormValid = true;

    if (!invoice?.invoiceNumber || !invoice?.emissionDate || !invoice?.originCountry || !invoice?.certificateEmail) {
      this.isInvoiceFormValid = false;
    }
  }

  penddingCompanies() {
    return !this.companiesFormGroup.value.importer;
  }

  penddingTransshipment() {
    let pendding = this.transshipmentFlow.find((c) => c?.id == undefined);
    return !!pendding;
  }

  setTransshipmentFlow(flow: Transshipment[]) {
    this.transshipmentFlow = flow;
  }

  productList(productList) {
    this.templateData.products = productList;
  }

  async submitAndCreateExportation() {
    try {
      await this.submitExportation();
      this.router.navigate(['/EasyTrade/Certificates/OriginCertificate']);
    } catch (error) {
      console.log(error);
    }
  }
  async submitAndRediirectoToList(update: boolean = false) {
    try {
      if (update) await this.updateExportation();
      else await this.submitExportation();
    } catch (error) {
      console.log(error);
    }
  }
  private async updateExportation() {
    this.loadData();
    try {
      this.certificateData.id = this.currentId;
      let idOriginCertificate = await this.oringinCertificateService.updateCertificate(this.certificateData);
      this.notifySucess();
      return idOriginCertificate;
    } catch (error) {
      this.notifyerror();
      throw Error('Fail updating origin certificate');
    }
  }

  private async submitExportation() {
    this.loadData();
    try {
      let idOriginCertificate = await this.oringinCertificateService.createCertificate(this.certificateData);
      await this.notifySucess();

      setTimeout(() => {
        this.dialog.open(RequestAprovalDialogComponent);
      }, 3000);
      return idOriginCertificate;
    } catch (error) {
      this.notifyerror();
      throw Error('Fail creating origin certificate');
    }
  }

  onCountrySelected(event) {
    this.shipmentFormGroup.controls.destinationCountry.setValue(event);
  }

  private notifySucess() {
    let msg: string = this.translateService.instant('easy-trade.origin-certificate.success');
    this.notificationService.notifyDialog({
      success: true,
      timeout: 3000,
      text: msg
    });
  }

  private notifyerror() {
    let msg: string = this.translateService.instant('easy-trade.origin-certificate.error');
    this.notificationService.notifyDialog({
      success: false,
      timeout: 3000,
      text: msg
    });
  }

  loadData() {
    let invoice = this.getInvoiceRequest();
    let incoterm = this.templateData.invoiceInformation.incoterm;
    let shipInfo = this.shipmentFormGroup.value;
    this.certificateData = {
      incotermType: incoterm.incotermType,
      currency: CurrencyEnum.DOLLAR,
      cost: this.getcostForAllProducts(),
      freightCost: incoterm.freightCost,
      insurance: incoterm.insurance,
      landingFee: incoterm.landingFee,
      others: incoterm.others,
      invoiceNumber: invoice.idInvoice.trim(),
      emissionDate: invoice.emissionDate,
      originCountry: invoice.originCountryCode,
      destinationCountry: shipInfo.destinationCountry?.alpha3Code,
      certificateLanguage: this.templateData.invoiceInformation.certificateLanguage?.name,
      certificateEmail: this.templateData.invoiceInformation.certificateEmail,
      observations: invoice.comments,
      hasCertificateBrazilianOrigin: this.currentCompany.cnpj != null && this.currentCompany.cnpj.trim().length > 1,
      companiesEnvolved: this.getCompaniesEnvolved(),
      shipmentInformation: this.getShipmentInformation(shipInfo),
      products: this.getProducts()
    };
  }

  getcostForAllProducts(): number {
    var allProduct = this.templateData.products;
    let valueOfProducts = 0;

    if (allProduct.length > 0) {
      allProduct.forEach((element) => {
        valueOfProducts += element.productValue;
      });
    }
    return valueOfProducts;
  }

  getProducts() {
    var certProdList: CertificateProductRequest[] = [];
    var prods = this.templateData.products;

    if (prods.length > 0) {
      prods.forEach((element) => {
        const productRequest: CertificateProductRequest = {
          ncm: element.ncmCode,
          idUnitMeasure: element.idUnitMeasure,
          idUnitMeasureWeight: element.idUnitMeasureWeight,
          quantity: element.quantity,
          grossWeight: element.grossWeight,
          netWeight: element.netWeight,
          productionDate: this.dateFormater(element.manufactoringDate),
          expirationDate: this.dateFormater(element.expirationDate),
          description: element.description,
          sif: element.sif,
          marks: element.marksAndNumbers,
          productValue: element.productValue,
          currency: element.currency
        };

        certProdList.push(productRequest);
      });
    }

    return certProdList;
  }

  dateFormater(value) {
    if (value != null && typeof value == 'string') {
      if (this.currentLanguage == 'pt' || value?.split('/').length <= 2) {
        return value?.split('/').reverse().join('/');
      } else if (this.currentLanguage == 'en' && value?.split('/').length > 2) {
        let splited = value?.split('/');
        let aux = splited[1];
        splited[1] = splited[0];
        splited[0] = aux;
        return splited.reverse().join('/');
      }
    }

    return value;
  }

  getShipmentInformation(shipInfo: any) {
    let stopPoints = this.transshipmentFlow.map((t) => t.id);

    let csi: CertificateShipmentInformation = {
      shipmentType: shipInfo.shipmentType == TransshipmentMethodEnum.plane ? 1 : 2,
      shippingDate: shipInfo.shippingDate,
      blawbNumber: shipInfo.awbNumber,
      imoNumber: shipInfo.imo_number,
      vesselName: shipInfo.vesselName,
      id: shipInfo.id ?? null
    };

    if (stopPoints.length > 1) {
      csi.portOfDeparture = stopPoints.shift();
      csi.portOfDestination = stopPoints.pop();
    }

    if (stopPoints.length > 0) {
      let trans: CertificateTrasShipment[] = [];

      stopPoints.forEach((element, i) => {
        trans.push({
          portId: element,
          ordenation: i
        });
      });

      csi.transshipments = trans;
    }
    return csi;
  }

  getCompaniesEnvolved() {
    let ce: CompaniesEnvolved = {
      exporter: { id: this.currentCompany.id }
    };

    if (this.companiesFormGroup.value?.importer != null) ce.importer = this.companiesFormGroup.value?.importer;

    if (this.companiesFormGroup.value?.buyer != null) ce.buyer = this.companiesFormGroup.value?.buyer;

    if (this.companiesFormGroup.value?.consignee != null) ce.consignee = this.companiesFormGroup.value?.consignee;

    return ce;
  }

  previewPdf() {}

  companySelectedEvent(event) {
    if (event?.typeCompany === CompanyTypeEnum.IMPORTER) {
      this.showImporterSelection = false;
      delete event.typeCompany;
      delete event.country;
      this.companiesFormGroup.value.importer = event;
    } else if (event?.typeCompany === CompanyTypeEnum.BUYER) {
      this.showBuyerSelection = false;
      delete event.typeCompany;
      delete event.country;
      this.companiesFormGroup.value.buyer = event;
    } else if (event?.typeCompany === CompanyTypeEnum.CONSIGNEE) {
      this.showConsigneeSelection = false;
      delete event.typeCompany;
      delete event.country;
      this.companiesFormGroup.value.consignee = event;
    }
  }

  findCountryByIdImporter() {
    return allCountries.find((idCountry) => idCountry.id === this.companiesFormGroup.value?.importer?.idCountry);
  }

  findCountryByIdConsignee() {
    return allCountries.find((idCountry) => idCountry.id === this.companiesFormGroup.value?.consignee?.idCountry);
  }

  findCountryByIdBuyer() {
    return allCountries.find((idCountry) => idCountry.id === this.companiesFormGroup.value?.buyer?.idCountry);
  }

  OpenshowImporterContainer() {
    if (this.showBuyerSelection || this.showConsigneeSelection) {
      return false;
    } else {
      return true;
    }
  }

  OpenshowImporterSelection() {
    this.showImporterSelection = true;
    this.showBuyerSelection = false;
    this.showConsigneeSelection = false;
    this.isOpenCompaniesInvolvedAdvance = false;
  }

  checkOpemCompaniesInvolvedAdvance() {
    if (this.showImporterSelection) {
      return false;
    }
    return !this.isOpenCompaniesInvolvedAdvance;
  }

  OpenshowConsigneeContainer() {
    if (this.isOpenCompaniesInvolvedAdvance) {
      if (this.showBuyerSelection || this.showImporterSelection) {
        return false;
      } else {
        return true;
      }
    }
  }

  OpenshowBuyerContainer() {
    if (this.isOpenCompaniesInvolvedAdvance) {
      if (this.showConsigneeSelection || this.showImporterSelection) {
        return false;
      } else {
        return true;
      }
    }
  }

  checkIsOpenCompaniesInvolvedAdvance() {
    if (this.isOpenCompaniesInvolvedAdvance) {
      if (this.showImporterSelection || this.showConsigneeSelection || this.showBuyerSelection) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  OpenshowConsigneeSelection() {
    this.showConsigneeSelection = true;
    this.showImporterSelection = false;
    this.showBuyerSelection = false;
    this.isOpenCompaniesInvolvedAdvance = true;
  }

  OpenshowBuyerSelection() {
    this.showConsigneeSelection = false;
    this.showImporterSelection = false;
    this.showBuyerSelection = true;
    this.isOpenCompaniesInvolvedAdvance = true;
  }

  closeTableCompanyEvent(event) {
    if (event === CompanyTypeEnum.IMPORTER) {
      this.showImporterSelection = false;
    } else if (event === CompanyTypeEnum.BUYER) {
      this.showBuyerSelection = false;
    } else if (event === CompanyTypeEnum.CONSIGNEE) {
      this.showConsigneeSelection = false;
    }
  }

  checkCompaniesFormGroupImporter() {
    if (this.companiesFormGroup.value.importer) {
      return false;
    } else {
      return true;
    }
  }

  checkCompaniesFormGroupConsignee() {
    if (this.companiesFormGroup.value?.consignee) {
      return false;
    } else {
      return true;
    }
  }
  checkCompaniesFormGroupBuyer() {
    if (this.companiesFormGroup.value?.buyer) {
      return false;
    } else {
      return true;
    }
  }

  getInvoiceRequest() {
    let invoice: Invoice = {
      certifiedLanguage: String(this.templateData.invoiceInformation.certificateLanguage?.id),
      comments: this.templateData.invoiceInformation?.observations,
      email: this.templateData.invoiceInformation.certificateEmail,
      emissionDate: this.templateData.invoiceInformation.emissionDate,
      idInvoice: this.templateData.invoiceInformation.invoiceNumber,
      originCountryCode: this.templateData.invoiceInformation.originCountry.alpha3Code,
      totalValue: this.templateData.detailsRequiriments.value,
      totalWeight: this.templateData.detailsRequiriments.totalWeight
    };
    return invoice;
  }

  openCompaniesInvolvedAdvance() {
    this.isOpenCompaniesInvolvedAdvance = true;
  }

  cloaseCompaniesInvolvedAadvance() {
    this.isOpenCompaniesInvolvedAdvance = false;
    this.showImporterSelection = false;
    this.showConsigneeSelection = false;
    this.showBuyerSelection = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  checkOpenBox() {
    if (this.showImporterSelection || this.showConsigneeSelection || this.showBuyerSelection) {
      return true;
    } else {
      return false;
    }
  }

  validateCompletedFirstStep() {
    return !(
      (this.templateData?.invoiceInformation?.originCountry?.alpha3Code == 'BRA' && !this.invoiceFormComponent?.invoiceForm?.value?.hasCertificateBrazilianOrigin) ||
      !this.invoiceFormComponent?.invoiceForm?.valid
    );
  }

  checkNumberFieldLength() {
    if (this.shipmentFormGroup.controls.acidNumber.value.length > 19) {
      this.shipmentFormGroup.controls.acidNumber.setValue(this.shipmentFormGroup.controls.acidNumber.value.slice(0, 19));
    }
  }
}
