import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-deactivate-dialog',
  templateUrl: './deactivate-dialog.component.html',
  styleUrls: ['./deactivate-dialog.component.scss']
})
export class DeactivateDialogComponent implements OnInit {
  constructor(private readonly dialogRef: MatDialogRef<DeactivateDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}
  ngOnInit(): void {}

  onNoButtonClick(): void {
    this.dialogRef.close(false);
  }
}
