export enum CompanyProfileEnum {
  Exporter = 'EXP',
  Importer = 'IMP',
  ForwardAgent = 'FWA',
  Customs = 'CTS',
  Embassy = 'EMB',
  Certifier = 'CTF',
  BrazilianAuthority = 'BRA',
  SharedProcesses = 'SHP'
}
