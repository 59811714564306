<br />
<br />
<br />
<div fxLayout="row" fxLayoutAlign="center center">
  <p class="mat-h1">
    {{ 'confirm-account.title-email' | translate }}
  </p>
</div>
<div fxLayout="row" fxLayoutAlign="center center">
  <p class="mat-h2">
    {{ email }}
  </p>
</div>
<br />
<form *ngIf="!waitingConfirmation" [formGroup]="accountValidation" (ngSubmit)="onSubmit()">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div fxLayout="column" fxFlex="80">
      <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-around center" fxLayoutGap="10px">
        <!-- #1 number -->
        <div class="column" flxLayout="column">
          <mat-form-field class="input-number" appearance="outline">
            <input formControlName="nmb1" id="1" autocomplete="off" (ngModelChange)="onInputEntry($event, 2)" maxlength="1" OnlyNumber="true" matInput />
          </mat-form-field>
        </div>

        <!-- #2 number -->
        <div class="column" flxLayout="column">
          <mat-form-field class="input-number" appearance="outline">
            <input formControlName="nmb2" id="2" autocomplete="off" (ngModelChange)="onInputEntry($event, 3)" maxlength="1" OnlyNumber="true" matInput />
          </mat-form-field>
        </div>

        <div class="column" flxLayout="column">
          <mat-form-field class="input-number" appearance="outline">
            <input formControlName="nmb3" id="3" autocomplete="off" (ngModelChange)="onInputEntry($event, 4)" maxlength="1" OnlyNumber="true" matInput />
          </mat-form-field>
        </div>

        <!-- #4 number -->
        <div class="column" flxLayout="column">
          <mat-form-field class="input-number" appearance="outline">
            <input formControlName="nmb4" id="4" autocomplete="off" (ngModelChange)="onInputEntry($event, 5)" maxlength="1" OnlyNumber="true" matInput />
          </mat-form-field>
        </div>

        <!-- #5 number -->
        <div class="column" flxLayout="column">
          <mat-form-field class="input-number" appearance="outline">
            <input formControlName="nmb5" id="5" autocomplete="off" (ngModelChange)="onInputEntry($event, 6)" maxlength="1" OnlyNumber="true" matInput />
          </mat-form-field>
        </div>

        <!-- #6 number -->
        <div class="column" flxLayout="column">
          <mat-form-field class="input-number" appearance="outline">
            <input formControlName="nmb6" id="6" autocomplete="off" (ngModelChange)="onInputEntry($event, 7)" maxlength="1" OnlyNumber="true" matInput />
          </mat-form-field>
        </div>
      </div>

      <button class="button" [disabled]="!accountValidation.valid" id="7" mat-raised-button color="accent">
        {{ 'auth.validation.send-button' | translate }}
      </button>
    </div>
  </div>
</form>

<app-ellos-spinner *ngIf="waitingConfirmation" [text]="'confirm-account.confirm-loader' | translate"> </app-ellos-spinner>
