import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import Language from 'src/app/core/interfaces/language.interface';
import { LanguageService } from 'src/app/core/services/language.service';

@Pipe({
  name: 'ellosDate',
  pure: false
})
export class EllosDatePipe implements PipeTransform {
  language$: Observable<Language>;

  constructor(private readonly languageService: LanguageService) {}

  transform(value: string | Date, showTime: boolean = false): string {
    if (!value) return '';
    return this.formatDate(value, showTime);
  }

  formatDate(date: string | Date, showTime: boolean): string {
    let momentDate = moment(date);
    if (!momentDate.isValid()) {
      return typeof date === 'string' ? date : date.toString();
    }
    const formatString = showTime ? this.getFormatStringWithTime() : this.getFormatStringWithoutTime();
    return momentDate.format(formatString);
  }

  getFormatStringWithTime(): string {
    const currentLanguage = this.languageService.getCurrentLanguage();
    return currentLanguage === 'en' ? 'MM/DD/YYYY HH:mm:ss' : 'DD/MM/YYYY HH:mm:ss';
  }

  getFormatStringWithoutTime(): string {
    const currentLanguage = this.languageService.getCurrentLanguage();
    return currentLanguage === 'en' ? 'MM/DD/YYYY' : 'DD/MM/YYYY';
  }
}
