import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ChangeTheme } from 'src/app/core/actions/theme.action';
import Theme from 'src/app/core/interfaces/theme.interface';
import { AuthService } from 'src/app/core/services/auth.service';
import { ThemeService } from 'src/app/core/services/theme.service';
import { ResultDialogData } from 'src/app/shared/components/result-dialog/result-dialog-data.interface';
import { ResultDialogComponent } from '@ccab/components-lib';
import { AssociateService } from 'src/app/shared/services/associate.service';
import { CompanyListDialogComponent } from '../company-list-dialog/company-list-dialog.component';

@Component({
  selector: 'app-delete-account-dialog',
  templateUrl: './delete-account-dialog.component.html',
  styleUrls: ['./delete-account-dialog.component.scss']
})
export class DeleteAccountDialogComponent implements OnInit {
  saving: boolean = false;

  constructor(
    private readonly dialogRef: MatDialogRef<DeleteAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly dialog: MatDialog,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly themeService: ThemeService,
    private readonly themeStore: Store<{ theme: Theme }>,
    private associateService: AssociateService
  ) {}

  ngOnInit(): void {}

  onNoClick() {
    this.dialogRef.close();
  }
  async DeleteCompanyDialog() {
    this.saving = true;
    try {
      const deletedUser = await this.associateService.deleteUser(this.data.email);
      if (deletedUser && deletedUser?.data?.length > 0) {
        const dialogRef = this.dialog.open(CompanyListDialogComponent, {
          data: deletedUser.data,
          disableClose: true
        });
      } else if (deletedUser && deletedUser?.data?.length == 0) {
        const dialogData: ResultDialogData = {
          success: true,
          text: 'settings.dialog.account-deleted',
          timeout: 3000
        };
        const resultDialogRef = this.dialog.open(ResultDialogComponent, {
          data: dialogData,
          disableClose: true
        });

        resultDialogRef.afterClosed().subscribe((result) => {
          this.dialog.closeAll();
          this.logout();
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.saving = false;
    }
  }
  logout(): void {
    this.authService.logout();
    this.resetTheme();
    this.router.navigateByUrl('/Login');
  }

  private resetTheme(): void {
    let defaultTheme = this.themeService.resetTheme();
    this.themeStore.dispatch(ChangeTheme(defaultTheme));
  }
}
