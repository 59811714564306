<h1 *ngIf="!loading">
  {{ companyDetail?.name }}
</h1>
<mat-card class="main-card-company">
  <mat-tab-group color="accent" class="tab" [formGroup]="company">
    <mat-tab [label]="'settings.companies.company-information' | translate">
      <div fxLayout="column" class="row main-content" fxLayoutAlign="start start" fxLayoutGap="10px" *ngIf="!loading">
        <div
          fxLayout="row"
          fxLayout.lt-md="column"
          class="row images-session"
          fxLayoutAlign.lt-md="start center"
          fxLayoutGap.lt-md="20px"
          fxLayoutAlign="start center"
          fxLayoutGap="60px"
        >
          <app-company-logo class="company-logo" [saveEvent]="saveEvent.asObservable()" [size]="175" [edit]="true" [url]="companyLogo" [companyId]="companyId"> </app-company-logo>

          <app-company-cover
            class="company-cover"
            [saveEvent]="saveEvent.asObservable()"
            [url]="companyCover"
            [edit]="true"
            fxFlex="grow"
            [companyId]="companyId"
          ></app-company-cover>
        </div>
        <div fxLayout="row" fxLayout.lt-md="row wrap" class="row" fxLayoutAlign="start start" fxLayoutGap="10px">
          <mat-form-field appearance="outline" fxFlex="30" fxFlex.lt-md="100" [matTooltip]="'settings.companies.request-change' | translate" [matTooltipPosition]="'above'">
            <mat-label>{{ 'settings.companies.corporate-name' | translate }}</mat-label>
            <input matInput autocomplete="off" [value]="companyDetail.name" disabled readonly />
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="grow" fxFlex.lt-md="100">
            <mat-label>{{ 'settings.companies.fantasy-name' | translate }}</mat-label>
            <input matInput autocomplete="off" formControlName="fantasyName" />
          </mat-form-field>
          <div fxLayout="column" fxLayoutAlign="start start">
            <p class="toggle-title">{{ 'settings.companies.type-trade' | translate }}:</p>
            <div fxLayout="row" fxLayout.lt-md="column" class="row" fxLayoutAlign="start start" fxLayoutGap="20px" [style.margin-left]="'5px'">
              <mat-slide-toggle [formControl]="exporter" #exp>{{ 'settings.companies.exporter' | translate }} </mat-slide-toggle>
              <mat-slide-toggle [formControl]="importer" #imp>{{ 'settings.companies.importer' | translate }} </mat-slide-toggle>
              <mat-slide-toggle [formControl]="forwarding" #fwa>{{ 'settings.companies.forwarding-agent' | translate }} </mat-slide-toggle>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayout.lt-md="row wrap" class="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-select-country
            appearance="outline"
            [matTooltip]="'settings.companies.request-change' | translate"
            [matTooltipPosition]="'above'"
            (onCountrySelected)="onCountrySelected($event)"
            formControlName="countryNumericCode"
            [label]="'settings.companies.country' | translate"
            ngDefaultControl
          >
          </mat-select-country>

          <div class="timezone-content" fxFlex="18" fxFlex.lt-md="100">
            <app-timezone [required]="true" (timezone)="company.controls.timezone.setValue($event)" [companyTimezone]="company.controls.timezone.value"></app-timezone>
          </div>

          <mat-form-field appearance="outline" fxFlex="20" fxFlex.lt-md="100" *ngIf="company.controls.countryNumericCode?.value?.name.toUpperCase() == 'BRAZIL'">
            <mat-label>CNPJ</mat-label>
            <input matInput autocomplete="off" mask="00.000.000/0000-00" formControlName="cnpj" />
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="20" fxFlex.lt-md="100" *ngIf="company.controls.countryNumericCode?.value?.name.toUpperCase() !== 'BRAZIL'">
            <mat-label>{{ 'registration.ministry-of-commerce' | translate }}</mat-label>
            <input matInput autocomplete="off" formControlName="cnpj" />
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex.lt-md="100" *ngIf="company.controls.countryNumericCode?.value?.name.toUpperCase() == 'BRAZIL'">
            <mat-label>{{ 'settings.companies.state-registration' | translate }}</mat-label>
            <input matInput autocomplete="off" formControlName="stateRegistry" />
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="22" fxFlex.lt-md="100">
            <mat-label>{{ 'settings.companies.phone' | translate }}</mat-label>
            <ngx-mat-intl-tel-input [enableSearch]="false" formControlName="phoneNumber"></ngx-mat-intl-tel-input>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="22" fxFlex.lt-md="100">
            <mat-label>{{ 'settings.companies.new-company-email' | translate }}</mat-label>
            <input matInput autocomplete="off" formControlName="contactEmail" />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-md="row wrap" class="row" fxLayoutAlign="start start" fxLayoutGap="10px">
          <mat-form-field class="" appearance="outline" fxFlex="40" fxFlex.lt-sm="100">
            <mat-label>{{ 'settings.companies.segment' | translate }}</mat-label>
            <mat-select [formControl]="sectorId" (ngModelChange)="selectedSector()">
              <mat-option value=""></mat-option>
              <mat-option *ngFor="let segment of segments" [value]="segment.id">{{ segment.name }} </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="" appearance="outline" fxFlex="60" fxFlex.lt-sm="100">
            <mat-label>{{ 'settings.companies.primary-chapter' | translate }}</mat-label>
            <mat-select [formControl]="primaryChapterId">
              <mat-option value=""></mat-option>
              <mat-option *ngFor="let pc of primaryChapter" [value]="pc.id">{{ pc.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="row" class="row" fxLayoutAlign="start start" fxLayoutGap="10px">
          <mat-form-field appearance="outline" fxFill>
            <mat-label>{{ 'settings.companies.product-and-service' | translate }}</mat-label>
            <mat-chip-list #chipList>
              <mat-chip *ngFor="let product of products; let i = index" [selectable]="selectable" [removable]="removable" (removed)="remove(i)" selected color="accent">
                {{ product }}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input
                [readOnly]="updatingTags"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)"
              />
            </mat-chip-list>
          </mat-form-field>
        </div>
      </div>
    </mat-tab>
    <mat-tab [label]="'settings.companies.address' | translate">
      <div fxLayout="column" class="row main-content" fxLayoutAlign="start start" fxLayoutGap="10px" *ngIf="!loading">
        <div fxLayout="row" fxLayout.lt-md="row wrap" class="row" fxLayoutAlign="start start" fxLayoutGap="10px">
          <mat-form-field class="input-zipcode" appearance="outline" fxFlex.lt-sm="100">
            <mat-label>{{ 'settings.companies.zip-code' | translate }}</mat-label>
            <input matInput autocomplete="off" formControlName="zipCode" (keypress)="numericOnly($event)" />
          </mat-form-field>

          <mat-form-field class="input-address" appearance="outline" fxFlex.lt-sm="100">
            <mat-label>{{ 'settings.companies.address' | translate }}</mat-label>
            <input matInput autocomplete="off" formControlName="street" />
          </mat-form-field>

          <mat-form-field class="input-number" appearance="outline" fxFlex.lt-sm="100">
            <mat-label>{{ 'settings.companies.number' | translate }}</mat-label>
            <input matInput autocomplete="off" formControlName="number" />
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-md="row wrap" class="row" fxLayoutAlign="start start" fxLayoutGap="10px">
          <mat-form-field class="input-city" appearance="outline" fxFlex.lt-sm="100">
            <mat-label>{{ 'settings.companies.city' | translate }}</mat-label>
            <input matInput autocomplete="off" formControlName="city" />
          </mat-form-field>

          <mat-form-field class="input-city" appearance="outline" fxFlex.lt-sm="100">
            <mat-label>{{ 'settings.companies.district' | translate }}</mat-label>
            <input matInput autocomplete="off" formControlName="district" />
          </mat-form-field>

          <mat-form-field class="input-state" appearance="outline" fxFlex.lt-sm="100">
            <mat-label>{{ 'settings.companies.state' | translate }}</mat-label>
            <input matInput autocomplete="off" formControlName="state" maxlength="2" maxlength="2" />
          </mat-form-field>

          <mat-form-field class="input-additional" appearance="outline" fxFlex.lt-sm="100">
            <mat-label>{{ 'settings.companies.additional-information' | translate }}</mat-label>
            <input matInput autocomplete="off" formControlName="complement" />
          </mat-form-field>
          <mat-form-field class="input full-width" appearance="outline">
            <mat-label>{{ 'registration.po-box' | translate }}</mat-label>
            <input matInput appearance="outline" autocomplete="off" formControlName="poBox" (keypress)="numericAndLetters($event)" />
          </mat-form-field>
        </div>
      </div>
    </mat-tab>

    <mat-tab [label]="'settings.companies.contact' | translate">
      <div fxLayout="column" class="row main-content" fxLayoutAlign="start start" fxLayoutGap="25px" *ngIf="!loading">
        <div fxLayout="column" class="row" fxLayoutAlign="start start" fxLayoutGap="10px">
          <div>
            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" *ngFor="let contact of contacts; let i = index">
              <mat-form-field class="input-name" appearance="outline" fxFlex.lt-md="100">
                <mat-label>Name</mat-label>
                <input
                  matInput
                  autocomplete="off"
                  [(ngModel)]="valuesInputContact[i].name"
                  [ngModelOptions]="{ standalone: true }"
                  [value]="contact.name"
                  [disabled]="contact.disabledContactForEdit"
                  (change)="logContact(i)"
                />
              </mat-form-field>

              <mat-form-field class="input-role" appearance="outline" fxFlex.lt-md="100">
                <mat-label>Role</mat-label>
                <input
                  matInput
                  autocomplete="off"
                  [(ngModel)]="valuesInputContact[i].role"
                  [ngModelOptions]="{ standalone: true }"
                  [value]="contact.role"
                  [disabled]="contact.disabledContactForEdit"
                  (change)="logContact(i)"
                />
              </mat-form-field>

              <mat-form-field class="input-email" appearance="outline" fxFlex.lt-md="100">
                <mat-label>Email</mat-label>
                <input
                  matInput
                  autocomplete="off"
                  [(ngModel)]="valuesInputContact[i].email"
                  [ngModelOptions]="{ standalone: true }"
                  [value]="contact.email"
                  [disabled]="contact.disabledContactForEdit"
                  (change)="logContact(i)"
                />
              </mat-form-field>

              <mat-form-field class="input-phone" appearance="outline" fxFlex.lt-md="100">
                <mat-label>{{ 'settings.companies.phone' | translate }}</mat-label>
                <input
                  matInput
                  autocomplete="off"
                  [(ngModel)]="valuesInputContact[i].phone"
                  [ngModelOptions]="{ standalone: true }"
                  [value]="contact.phone"
                  [disabled]="contact.disabledContactForEdit"
                  (change)="logContact(i)"
                />
              </mat-form-field>

              <button
                *ngIf="contact.disabledContactForEdit"
                mat-mini-fab
                color="warn"
                type="button"
                class="add-button"
                [matTooltip]="'settings.remove-contact' | translate"
                (click)="removeContact(contact.id, i)"
              >
                <mat-icon> remove </mat-icon>
              </button>
              <button
                mat-mini-fab
                type="button"
                class="add-button"
                [matTooltip]="'settings.edit-contact' | translate"
                *ngIf="contact.disabledContactForEdit"
                (click)="editContact(contact.id, i)"
              >
                <mat-icon> edit </mat-icon>
              </button>
              <button
                mat-mini-fab
                type="button"
                color="warn"
                [matTooltip]="'settings.cancel-edit-contact' | translate"
                class="add-button"
                *ngIf="!contact.disabledContactForEdit"
                (click)="cancel(i)"
              >
                <mat-icon> cancel </mat-icon>
              </button>
              <button
                mat-mini-fab
                type="button"
                class="add-button"
                *ngIf="!contact.disabledContactForEdit"
                [matTooltip]="'settings.save-edit-contact' | translate"
                (click)="saveContactEdited(contact.id, i)"
              >
                <mat-icon> save </mat-icon>
              </button>
            </div>

            <form [formGroup]="contactForm" #formContact="ngForm">
              <div>
                <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" *ngIf="!resetNumber">
                  <mat-form-field appearance="outline" class="input-name">
                    <mat-label>{{ 'settings.companies.name' | translate }}</mat-label>
                    <input matInput formControlName="name" appPreventEmoji />
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="input-role">
                    <mat-label>{{ 'settings.companies.role' | translate }}</mat-label>
                    <input matInput formControlName="role" appPreventEmoji />
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="input-email">
                    <mat-label>{{ 'settings.profile.e-mail' | translate }}</mat-label>
                    <input matInput formControlName="email" appPreventEmoji />
                  </mat-form-field>
                  <mat-form-field class="input-phone" appearance="outline" fxFlex.lt-md="100">
                    <mat-label>{{ 'settings.companies.phone' | translate }}</mat-label>
                    <ngx-mat-intl-tel-input #phoneNumberContactForm formControlName="phonenumber" [preferredCountries]="['br']" [enableSearch]="false"> </ngx-mat-intl-tel-input>
                  </mat-form-field>
                  <button
                    mat-mini-fab
                    color="accent"
                    type="submit"
                    class="add-button"
                    [disabled]="contactForm.invalid"
                    [matTooltip]="'settings.add-contact' | translate"
                    (click)="addContact(formContact)"
                  >
                    <mat-icon>add</mat-icon>
                  </button>
                  <button mat-mini-fab color="accent" type="submit" [matTooltip]="'settings.reset-contact' | translate" class="add-button" (click)="resetAddContact()">
                    <mat-icon>undo</mat-icon>
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div *ngFor="let media of medias; let i = index">
            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
              <mat-form-field class="select-icon" appearance="outline">
                <mat-label>{{ 'settings.companies.social-media' | translate }}</mat-label>
                <mat-select [(value)]="media.socialMedia" #select [disabled]="true">
                  <mat-select-trigger #trigger>
                    <fa-icon [icon]="getIcon(media?.socialMedia.toLowerCase())"> </fa-icon>
                  </mat-select-trigger>

                  <mat-option value="Linkedin"> <fa-icon [icon]="getIcon('linkedin')"> </fa-icon>&nbsp; </mat-option>

                  <mat-option value="Twitter"> <fa-icon [icon]="getIcon('twitter')"> </fa-icon>&nbsp; </mat-option>

                  <mat-option value="Facebook"> <fa-icon [icon]="getIcon('facebook')"> </fa-icon>&nbsp; </mat-option>

                  <mat-option value="Instagram"> <fa-icon [icon]="getIcon('twitter')"> </fa-icon>&nbsp; </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" class="input-social">
                <mat-label>Link</mat-label>
                <input matInput [(value)]="media.mediaUrl" [disabled]="true" />
              </mat-form-field>

              <button mat-mini-fab color="warn" type="button" class="add-button" (click)="removeSocialMedia(media.id, i)">
                <mat-icon> remove </mat-icon>
              </button>
            </div>
          </div>

          <form [formGroup]="socialMedia" autocomplete="off">
            <div>
              <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" *ngIf="!isAddMedia && medias.length < 4">
                <mat-form-field class="select-icon" appearance="outline">
                  <mat-label id="social-label">{{ 'settings.companies.social-media' | translate }}</mat-label>
                  <mat-select formControlName="icon" #select>
                    <mat-select-trigger id="select-icon-social" #trigger>
                      <fa-icon [icon]="getIcon(select.value?.toLowerCase())"> </fa-icon>
                    </mat-select-trigger>

                    <mat-option value="Linkedin" *ngIf="notAdded('Linkedin')"> <fa-icon [icon]="faLinkedin"> </fa-icon>&nbsp; </mat-option>

                    <mat-option value="Twitter" *ngIf="notAdded('Twitter')"> <fa-icon [icon]="faTwitter"> </fa-icon>&nbsp; </mat-option>

                    <mat-option value="Facebook" *ngIf="notAdded('Facebook')"> <fa-icon [icon]="faFacebook"> </fa-icon>&nbsp; </mat-option>

                    <mat-option value="Instagram" *ngIf="notAdded('Instagram')"> <fa-icon [icon]="faInstagram"> </fa-icon>&nbsp; </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="input-social">
                  <mat-label>Link</mat-label>
                  <input matInput formControlName="link" appPreventEmoji />
                </mat-form-field>

                <button mat-mini-fab color="accent" type="submit" class="add-button" (click)="addSocialMedia()">
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </div>
          </form>

          <mat-form-field class="input-email" appearance="outline" fxFlex.lt-md="100">
            <mat-label>Website</mat-label>
            <input matInput autocomplete="off" formControlName="website" appPreventEmoji />
          </mat-form-field>
        </div>
      </div>
    </mat-tab>
    <mat-tab [label]="'settings.companies.events' | translate" *ngIf="companyDetail?.roleName.toUpperCase() === 'ADMIN'">
      <section class="event">
        <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end start">
          <button (click)="subscribeDialog()" mat-stroked-button class="submit-button" type="submit">
            {{ 'settings.companies.event.subscribe' | translate }}
          </button>
        </div>
        <app-events-grid [idCompany]="companyDetail.id" class="w100" #eventGrid></app-events-grid>
      </section>
    </mat-tab>

    <mat-tab [label]="'settings.companies.integration' | translate" *ngIf="companyDetail?.roleName.toUpperCase() === 'ADMIN'">
      <div fxLayout="column" class="row main-content" fxLayoutAlign="start start" fxLayoutGap="25px">
        <div class="col-12">
          <mat-form-field class="input-address" appearance="outline" fxFlex.lt-sm="300" [style.width.%]="100">
            <mat-label>{{ 'settings.companies.tradelens' | translate }}</mat-label>
            <input matInput autocomplete="off" formControlName="tradelensId" />
          </mat-form-field>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <mat-divider></mat-divider>
  <mat-card-actions align="end">
    <button mat-button routerLink="/Settings/Companies">
      {{ ('settings.companies.button-cancel' | translate).toUpperCase() }}
    </button>
    <button mat-button [disabled]="disableSaveCompanyInformations()" (click)="saveCompanyInformations()">
      {{ ('settings.companies.button-save' | translate).toUpperCase() }}
    </button>
  </mat-card-actions>
</mat-card>
