<form [formGroup]="productFormGroup" class="form-products" autocomplete="off">
  <ng-template matStepLabel>{{ 'easy-trade.new-trade.stepper.timeline.product-information-ncm' | translate }}</ng-template>
  <div fxLayout="row" class="registration__title">
    <div fxFlex="100">
      <p class="mat-h2" color="primary">
        {{ 'easy-trade.new-trade.stepper.product-information.title' | translate }}
      </p>
    </div>
  </div>
  <div id="content-form-product" fxLayoutGap="20px" fxLayout="row wrap" fxLayoutAlign="start">
    <app-certificate-ncm-autocomplete
      fxFlex="auto"
      #ncmProduct
      [nameNcmDefault]="nameNcmDefault"
      [cleanNcm]="cleanNcm"
      (selectedNcm$)="setProductNcm($event)"
      (emitNameUpdate$)="isUpdateNameNcmToFalse($event)"
      [urlNcmService]="urlNcmService"
    >
    </app-certificate-ncm-autocomplete>

    <!-- mat-form-field -->
    <app-select-weight
      formControlName="unitMeasure"
      required="true"
      unitMeasureType="VOL"
      [defaultUnitMeasure]="defaultUnitMeasure"
      [defaultUnitMeasureId]="defaultUnitMeasureId"
      [isDue]="isDueFormReadOnly"
      [urlUnitMeasureService]="urlUnitMeasureService"
    ></app-select-weight>

    <!-- mat-form-field -->
    <mat-form-field color="accent" appearance="outline">
      <mat-label>{{ 'easy-trade.new-trade.stepper.product-information.form.amount' | translate }}</mat-label>
      <input #searchInputQuantity matInput [readonly]="isDueFormReadOnly" formControlName="quantity" required OnlyNumber="true" min="0" />
    </mat-form-field>

    <!-- mat-form-field -->
    <app-select-weight
      [disabled]="productsRequest.length > 0"
      [required]="true"
      unitMeasureType="WEI"
      formControlName="unitMeasureWeight"
      label="easy-trade.new-trade.stepper.product-information.form.unit-weight"
      [urlUnitMeasureService]="urlUnitMeasureService"
    ></app-select-weight>

    <mat-form-field color="accent" appearance="outline">
      <mat-label>{{ 'easy-trade.new-trade.stepper.product-information.form.gross-weight' | translate }}</mat-label>
      <input
        matInput
        #inputGrossWeight
        formControlName="grossWeight"
        (ngModelChange)="onChangeGrossWeighttEvent($event)"
        required
        currencyMask
        [options]="{
          prefix:
            productsRequest.length > 0
              ? productsRequest[0]?.unitMeasureWeight?.code + ' '
              : productFormGroup?.value?.unitMeasureWeight
              ? productFormGroup?.value?.unitMeasureWeight?.code + ' '
              : 'KG ',
          thousands: '.',
          decimal: ',',
          precision: 4
        }"
        OnlyNumber="true"
        min="0"
      />
    </mat-form-field>

    <!-- mat-form-field -->

    <mat-form-field id="container-netweight" color="accent" appearance="outline">
      <mat-label>{{ 'easy-trade.new-trade.stepper.product-information.form.net-weight' | translate }}</mat-label>
      <input
        matInput
        #inputNetWeight
        formControlName="netWeight"
        [readonly]="isDueFormReadOnly"
        (ngModelChange)="onChangeNetWeightEvent($event)"
        required
        currencyMask
        [options]="{
          prefix:
            productsRequest.length > 0
              ? productsRequest[0]?.unitMeasureWeight?.code + ' '
              : productFormGroup?.value?.unitMeasureWeight
              ? productFormGroup?.value?.unitMeasureWeight?.code + ' '
              : 'KG ',
          thousands: '.',
          decimal: ',',
          precision: 4
        }"
        OnlyNumber="true"
        min="0"
      />
      <mat-hint style="color: #eb5757" id="error-netweight" *ngIf="productFormGroup.errors?.checkGrossValidator">
        {{ 'easy-trade.new-trade.stepper.product-information.form.net-weight-error' | translate }}
      </mat-hint>
    </mat-form-field>
  </div>
  <div fxLayoutGap="20px" fxLayout="row wrap" fxLayoutAlign="start">
    <!-- PRODUTION DATE -->
    <mat-form-field appearance="outline">
      <mat-label>{{ 'easy-trade.new-trade.stepper.product-information.form.production' | translate }}</mat-label>
      <mat-hint style="color: #eb5757" *ngIf="isErrorDateProduction" [matTooltip]="this.ErrorTypeDateProduction">
        {{ 'easy-trade.new-trade.stepper.product-information.form.error-expiration-date' | translate }}* {{ mask }}
      </mat-hint>
      <input
        matInput
        [formControl]="inputStringDateProduction"
        [(ngModel)]="dateFormatStringProduction"
        [required]="isProductionAndExpirationDateRequired()"
        minlength="8"
        [placeholder]="mask"
        mask="00/00/0000"
        (keyup)="validateDateProduction()"
        [dropSpecialCharacters]="false"
        type="string"
      />
      <input
        matInput
        [matDatepicker]="productionDate"
        [placeholder]="mask"
        hidden
        [min]="minDateProduction"
        [max]="maxDateProduction"
        (dateInput)="setDateProduction($event)"
        [value]="productFormGroup.controls['manufactoringDate'].value"
        formControlName="manufactoringDate"
      />
      <mat-datepicker-toggle matSuffix [for]="productionDate"></mat-datepicker-toggle>
      <mat-datepicker #productionDate></mat-datepicker>
    </mat-form-field>

    <!-- expirationDate DATE -->

    <mat-form-field
      appearance="outline"
      [ngClass]="{
        'mat-form-field-invalid': invalidExpirationDate()
      }"
    >
      <mat-label>{{ 'easy-trade.new-trade.stepper.product-information.form.shelf-life' | translate }}</mat-label>
      <mat-hint style="color: #eb5757" *ngIf="productFormGroup.errors?.checkExpirationDate">
        {{ 'easy-trade.new-trade.stepper.product-information.form.shelf-life-error' | translate }}
      </mat-hint>
      <mat-hint style="color: #eb5757" *ngIf="isErrorExpirationDate" [matTooltip]="this.ErrorTypeExpirationDate">
        {{ 'easy-trade.new-trade.stepper.product-information.form.error-expiration-date' | translate }}* {{ mask }}
      </mat-hint>

      <input
        matInput
        [formControl]="inputStringDateExpirationDate"
        minlength="8"
        [required]="isProductionAndExpirationDateRequired()"
        [(ngModel)]="dateFormatStringExpirationDate"
        [placeholder]="mask"
        mask="00/00/0000"
        (keyup)="validateDateExpirationDate()"
        [dropSpecialCharacters]="false"
        type="string"
      />
      <input
        matInput
        [matDatepicker]="expirationDate"
        [placeholder]="mask"
        hidden
        [min]="minDateExpirationDate"
        [max]="maxDateExpirationDate"
        (dateInput)="setDateExpirationDate($event)"
        [min]="getMinDateToShelfDate()"
        [value]="productFormGroup.controls['expirationDate'].value"
        formControlName="expirationDate"
      />
      <mat-datepicker-toggle matSuffix [for]="expirationDate"></mat-datepicker-toggle>
      <mat-datepicker #expirationDate></mat-datepicker>
    </mat-form-field>

    <mat-form-field *ngIf="_isOriginCertificate" appearance="outline">
      <mat-label>{{ 'easy-trade.new-trade.stepper.product-information.form.currency' | translate }}</mat-label>
      <mat-select [formControl]="productFormGroup.controls.currency" (valueChange)="setCurrencyConfig($event)">
        <mat-option></mat-option>
        <mat-option [value]="currencyKey" *ngFor="let currencyKey of currencyEnumKeys">{{ currencyEnum[currencyKey] }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="auto" color="accent" appearance="outline" *ngIf="useProductValue">
      <mat-label>{{ 'easy-trade.new-trade.stepper.product-information.table.value' | translate }}</mat-label>
      <input matInput formControlName="productValue" currencyMask required [options]="currentCurrencyConfig" placeholder="0,00" />
    </mat-form-field>

    <mat-form-field fxFlex="auto" color="accent" appearance="outline">
      <mat-label>{{ 'easy-trade.new-trade.stepper.product-information.form.description' | translate }}</mat-label>
      <input matInput formControlName="description" required [readonly]="isDueFormReadOnly" maxlength="180" />
    </mat-form-field>

    <!-- mat-form-field -->

    <mat-form-field color="accent" appearance="outline">
      <mat-label>{{ 'easy-trade.new-trade.stepper.product-information.form.marks-and-numbers' | translate }}</mat-label>
      <input matInput formControlName="marksAndNumbers" autocomplete="off" maxlength="15" />
    </mat-form-field>

    <!-- mat-form-field -->

    <mat-form-field color="accent" appearance="outline">
      <mat-label>{{ 'easy-trade.new-trade.stepper.product-information.form.sif' | translate }}</mat-label>
      <input matInput formControlName="sif" OnlyNumber="true" min="0" />
    </mat-form-field>
  </div>
  <div *ngIf="!isEditProduct" fxLayout="row" class="button-group" fxLayoutGap="25px" fxLayoutAlign="center">
    <button
      [ngClass]="{ 'disabled-addProduct': checkDisabledButtonAddProdutc() }"
      mat-raised-button
      class="button-control add-product-button"
      align="center"
      type="button"
      (click)="addProduct()"
    >
      {{ 'easy-trade.new-trade.stepper.product-information.form.add-button' | translate }}
    </button>
  </div>
  <div *ngIf="isEditProduct" fxLayout="row" class="button-group" fxLayoutGap="25px" fxLayoutAlign="center">
    <button align="end" mat-raised-button type="button" mat-stroked-button class="add-product-button" (click)="addProduct(true)">
      {{ 'easy-trade.new-trade.stepper.product-information.form.cancel-product-edit' | translate }}
    </button>
    <button
      [ngClass]="{ 'disabled-addProduct': checkDisabledButtonAddProdutc() }"
      align="end"
      mat-raised-button
      type="button"
      (click)="addProduct()"
      mat-stroked-button
      class="add-product-button"
    >
      {{ 'easy-trade.new-trade.stepper.product-information.form.save-product' | translate }}
    </button>
  </div>
</form>
