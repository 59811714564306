import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import { QueryCompanies } from '../../models/query-companies';
import { QueryCompaniesAction } from '../../actions/query-companies.action';
@Component({
  selector: 'app-search-companies',
  templateUrl: './search-companies.component.html',
  styleUrls: ['./search-companies.component.scss']
})
export class SearchCompaniesComponent implements OnInit {
  selectedFilter = 'all';

  @ViewChild('searchInput') searchInput: ElementRef;
  profile: string;
  destroy$ = new Subject<any>();
  pageIndex: number = 0;
  pageSize: number = 10;
  constructor(private readonly queryCompaniesState: Store<QueryCompanies>) {}

  ngOnInit(): void {
    this.sendNewCompanyQuery();
  }

  ngAfterViewInit() {
    this.inputFilter();
  }

  profileFilter(profile) {
    this.profile = profile.value;

    this.sendNewCompanyQuery();
  }
  inputFilter() {
    fromEvent(this.searchInput?.nativeElement, 'keyup')
      .pipe(
        debounceTime(800),
        switchMap(async () => this.sendNewCompanyQuery()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
  async sendNewCompanyQuery() {
    let query: QueryCompanies = {
      companyName: this.searchInput?.nativeElement.value || null,
      profileCode: this.profile || null,
      pageIndex: this.pageIndex,
      pageSize: this.pageSize
    };
    this.queryCompaniesState.dispatch(QueryCompaniesAction(query));
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.queryCompaniesState.dispatch(QueryCompaniesAction(undefined));
  }
}
